import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import NumberInput from './NumberInput';
import VideoDepositLog from '../models/VideoDepositLog';

type MachineId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export interface VideoMasterReportFormMachine {
  id: MachineId;
  cashIn: number;
  chipChange: number;
  rollover: boolean;
}

export interface VideoMasterReportFormProps {
  value: VideoDepositLog;
  onChange?: (value: VideoDepositLog) => any;
  totalCashIn: number;
}

const VideoMasterReportForm: React.FC<VideoMasterReportFormProps> = props => {
  const { value, onChange, totalCashIn, ...rest } = props;
  const machine1 = value.machine1 || 0;
  const machine2 = value.machine2 || 0;
  const machine3 = value.machine3 || 0;
  const machine4 = value.machine4 || 0;
  const machine5 = value.machine5 || 0;
  const machine6 = value.machine6 || 0;
  const machine7 = value.machine7 || 0;
  const machine8 = value.machine8 || 0;
  const machine9 = value.machine9 || 0;
  const machine10 = value.machine10 || 0;

  const machines: VideoMasterReportFormMachine[] = [
    {
      id: 1,
      cashIn: machine1,
      rollover: !!value.rollOver1,
      chipChange: value.chipChange1 || 0,
    },
    {
      id: 2,
      cashIn: machine2,
      rollover: !!value.rollOver2,
      chipChange: value.chipChange2 || 0,
    },
    {
      id: 3,
      cashIn: machine3,
      rollover: !!value.rollOver3,
      chipChange: value.chipChange3 || 0,
    },
    {
      id: 4,
      cashIn: machine4,
      rollover: !!value.rollOver4,
      chipChange: value.chipChange4 || 0,
    },
    {
      id: 5,
      cashIn: machine5,
      rollover: !!value.rollOver5,
      chipChange: value.chipChange5 || 0,
    },
    {
      id: 6,
      cashIn: machine6,
      rollover: !!value.rollOver6,
      chipChange: value.chipChange6 || 0,
    },
    {
      id: 7,
      cashIn: machine7,
      rollover: !!value.rollOver7,
      chipChange: value.chipChange7 || 0,
    },
    {
      id: 8,
      cashIn: machine8,
      rollover: !!value.rollOver8,
      chipChange: value.chipChange8 || 0,
    },
    {
      id: 9,
      cashIn: machine9,
      rollover: !!value.rollOver9,
      chipChange: value.chipChange9 || 0,
    },
    {
      id: 10,
      cashIn: machine10,
      rollover: !!value.rollOver10,
      chipChange: value.chipChange10 || 0,
    },
  ];

  function handleChange(changes: Partial<typeof value>) {
    if (onChange) {
      onChange({
        ...value,
        ...changes,
      });
    }
  }

  function handleMachineCashInChange(machineId: MachineId, cashIn: number) {
    handleChange({
      [`machine${machineId}`]: cashIn,
    });
  }

  function handleMachineChipChangeChange(
    machineId: MachineId,
    rollover: number,
  ) {
    handleChange({
      [`chipChange${machineId}`]: rollover,
    });
  }

  function handleMachineRolloverToggle(machineId: MachineId) {
    const key = `rollOver${machineId}`;
    handleChange({
      [key]: (value as Record<string, any>)[key] ? 0 : 1,
    });
  }

  return (
    <Root {...rest}>
      <h3>
        Video Master Report
        <br />
        (enter total in)
      </h3>
      <MachineLabelRow>
        <label>Chip Change</label>
        <label>Rollover</label>
      </MachineLabelRow>
      {machines.map((machine, i) => (
        <MachineRow key={machine.id} data-testid={`machine${machine.id}`}>
          <label>Machine {i + 1}</label>
          <NumberInput
            value={machine.cashIn}
            onValueChange={cashIn =>
              handleMachineCashInChange(machine.id, cashIn)
            }
            data-testid={`machine${machine.id}CashInInput`}
          />
          <NumberInput
            value={machine.chipChange}
            disabled={!machine.rollover}
            onValueChange={chipChange => {
              handleMachineChipChangeChange(machine.id, chipChange);
            }}
            data-testid={`machine${machine.id}ChipChangeInput`}
          />
          <input
            type="checkbox"
            checked={machine.rollover}
            onChange={() => handleMachineRolloverToggle(machine.id)}
            data-testid={`machine${machine.id}RolloverCheckbox`}
          />
        </MachineRow>
      ))}
      <TotalRow>
        <label>Total Cash In:</label>
        <NumberInput value={totalCashIn} data-testid="totalCashInInput" />
      </TotalRow>
    </Root>
  );
};

export default styled(VideoMasterReportForm)`
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Root = styled(Column)`
  align-items: center;
`;

const MachineLabelRow = styled(Row)`
  justify-content: flex-end;
  padding-right: 30px;
  margin-bottom: 10px;
  margin-left: 190px;
  label + label {
    margin-left: 5px;
  }
`;

const MachineRow = styled(Row)`
  align-items: center;
  padding-right: 50px;

  input[type='number'] {
    margin-left: 5px;
    text-align: right;
    padding: 0;
  }

  > input,
  > ${NumberInput} {
    margin-left: 3px;
    height: 25px;
    width: 100px;
  }

  input[type='checkbox'] {
    margin-left: 15px;
    width: 12px !important;
  }

  > label {
    margin-right: 10px;
  }
`;

const TotalRow = styled(Row)`
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  width: 423px;
  ${NumberInput} {
    margin-left: 13px;
    height: 25px;
    text-align: right;
    padding: 0;
    > input {
      height: 25px;
      margin-left: 5px;
      text-align: right;
      padding: 0;
    }
  }
  > label {
    margin-left: 7px;
  }
`;
