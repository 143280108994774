import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import Form from './Form';
import FormBox from './FormBox';
import Column from './Column';
import Row from './Row';
import NumberInput from './NumberInput';
import DateInput from './DateInput';
import Table from './Table';
import Select from './Select';
import BlockButton from './BlockButton';
import { BrandInventoryRow, ActivePromo } from '../api/BrandInventoryEndpoints';
import noop from '../utils/noop';

interface BrandInventoryFormProps {
  eopDate: string;
  storeId: number;
  grandTotal: number;
  value: BrandInventoryRow[];
  activePromos: ActivePromo[];
  closingTotal: number;
  closingBluTotal: number;
  onProductChange?: (
    brandId: number,
    packCount: number,
    cartonCount: number,
  ) => any;
  onOtherProductChange?: (
    brandId: number,
    brandCount: number,
    brandCartonCount: number,
  ) => any;
  onSubmit?: (value: BrandInventoryRow[]) => any;
}

const BrandInventoryForm: React.FC<BrandInventoryFormProps> = props => {
  const {
    eopDate,
    storeId,
    value,
    grandTotal,
    closingTotal,
    closingBluTotal,
    activePromos,
    onProductChange = noop,
    onOtherProductChange = noop,
    onSubmit = noop,
    ...rest
  } = props;
  const closingDate = getPrevMonday(eopDate);

  const eopDateObject = useMemo(() => new Date(eopDate), [eopDate]);
  const products = [
    { brandId: 11, name: 'Camel Other', data: getProductByBrandId(11) },
    { brandId: 25, name: 'Camel Turkish', data: getProductByBrandId(25) },
    { brandId: 14, name: 'Camel NF', data: getProductByBrandId(14) },
    { brandId: 82, name: 'Winston', data: getProductByBrandId(82) },
    { brandId: 22, name: 'Doral', data: getProductByBrandId(22) },
    { brandId: 51, name: 'Marlboro', data: getProductByBrandId(51) },
    { brandId: 80, name: 'Virginia', data: getProductByBrandId(80) },
    { brandId: 5, name: 'Camel Core/Capsole', data: getProductByBrandId(5) },
    { brandId: 68, name: 'Pall Mall', data: getProductByBrandId(68) },
    { brandId: 63, name: 'Newport', data: getProductByBrandId(63) },
    {
      brandId: 170,
      name: 'Newport Non-Menthol',
      data: getProductByBrandId(170),
    },
    { brandId: 3, name: 'American Spirit', data: getProductByBrandId(3) },
    { brandId: 37, name: 'L&M', data: getProductByBrandId(37) },
    { brandId: 52, name: 'Marlboro 72', data: getProductByBrandId(52) },
    { brandId: 153, name: "Camel 99's", data: getProductByBrandId(153) },
    {
      brandId: 156,
      name: 'Marb Special Blend',
      data: getProductByBrandId(156),
    },
  ];
  const otherProduct = getProductByBrandId(105);
  const damagedProduct = getProductByBrandId(106);
  const snusProduct = getProductByBrandId(120);
  const bluPacksProduct = getProductByBrandId(203);

  const promos = useMemo<Array<BrandInventoryRow | undefined>>(
    () => value.filter(product => product.isPromo),
    [value],
  );

  while (promos.length < 6) {
    promos.push(undefined);
  }

  function handleSubmit() {
    onSubmit(value);
  }

  function getProductByBrandId(brandId: number) {
    return value.find(product => product.brandId === brandId);
  }

  return (
    <FormBox
      {...rest}
      title="End of Period Inventory"
      onCloseClick={handleSubmit}
    >
      <Form onSubmit={handleSubmit} data-testid="form">
        <HeaderRow>
          <label>Store #:</label>
          <NumberInput disabled value={storeId} />
          <label>EOPDate</label>
          <DateInput value={eopDateObject} />
        </HeaderRow>
        <ProductTable
          headers={['Product', 'Pack Count', 'Carton Count', 'Total']}
        >
          {products.map(product => (
            <ProductTableRow
              key={product.name}
              brandId={product.brandId}
              productName={product.name}
              packCount={(product.data && product.data.packCount) || 0}
              cartonCount={(product.data && product.data.cartonCount) || 0}
              onChange={onProductChange}
            />
          ))}
        </ProductTable>

        <PromoTable headers={['Promos']}>
          {promos.map((promo, i) => (
            <PromoTableRow
              key={i}
              brandId={(promo && promo.brandId) || null}
              packCount={(promo && promo.packCount) || 0}
              cartonCount={(promo && promo.cartonCount) || 0}
              brandName={promo ? promo.brandName : ''}
              activePromos={activePromos}
              onChange={onProductChange}
            />
          ))}
          <ProductTableRow
            productName="Other"
            brandId={105}
            packCount={otherProduct ? otherProduct.packCount : 0}
            cartonCount={otherProduct ? otherProduct.cartonCount : 0}
            onChange={onOtherProductChange}
          />
          <ProductTableRow
            brandId={106}
            productName="Damaged/ No Stamp"
            packCount={damagedProduct ? damagedProduct.packCount : 0}
            cartonCount={damagedProduct ? damagedProduct.cartonCount : 0}
            onChange={onOtherProductChange}
          />
          <tr>
            <td>
              <ProductName>Snus/Dissolvables:</ProductName>
            </td>
            <td>
              <NumberInput
                value={snusProduct ? snusProduct.packCount : 0}
                onChange={event => onProductChange(120, +event.target.value, 0)}
              />
            </td>
            <td />
            <td>
              <NumberInput value={snusProduct ? snusProduct.packCount : 0} />
            </td>
          </tr>
          <tr>
            <td>
              <ProductName>Total Blu Packs:</ProductName>
            </td>
            <td>
              <NumberInput
                value={bluPacksProduct ? bluPacksProduct.packCount : 0}
                onChange={event => onProductChange(203, +event.target.value, 0)}
              />
            </td>
            <td>(not included in grand total)</td>
          </tr>
        </PromoTable>
        <FooterRow>
          <DoneButton>Done</DoneButton>
          <CenterRow>
            <CenterRow>
              <label>
                <b>Grand Total</b>
              </label>
              <NumberInput readOnly value={grandTotal} />
            </CenterRow>
            <ClosingColumn>
              <CenterRow>
                <label>Closing Date:</label>
                <DateInput disabled value={closingDate} />
              </CenterRow>

              <CenterRow>
                <label>Closing Total:</label>
                <NumberInput disabled value={closingTotal} />
              </CenterRow>

              <CenterRow>
                <label>Blu Closing Total:</label>
                <NumberInput disabled value={closingBluTotal} />
              </CenterRow>
            </ClosingColumn>
          </CenterRow>
        </FooterRow>
      </Form>
    </FormBox>
  );
};

function getPrevMonday(date: Date | string): Date {
  const monday = new Date(date);
  monday.setDate(monday.getDate() - monday.getDay() + 1);
  return monday;
}

export default styled(BrandInventoryForm)`
  min-width: 1000px;

  ${NumberInput} {
    max-width: 150px;
    height: 25px;
  }
  ${Select} {
    width: 200px;
    height: 25px;
  }
`;

const HeaderRow = styled(Row)`
  align-items: center;
  label {
    margin-right: 70px;
  }
  label:last-of-type {
    margin-left: 70px;
  }
`;

const ProductTable = styled(Table)`
  margin-top: 20px;
`;

const PromoTable = styled(Table)``;

function ProductTableRow(props: {
  brandId: number;
  productName: string;
  packCount: number;
  cartonCount: number;
  onChange?: (brandId: number, packCount: number, cartonCount: number) => any;
}) {
  const {
    brandId,
    productName,
    packCount,
    cartonCount,
    onChange = noop,
    ...rest
  } = props;

  return (
    <tr {...rest}>
      <td>
        <ProductName>{productName}:</ProductName>
      </td>
      <td>
        <CenterRow>
          <NumberInput
            value={packCount}
            onChange={event =>
              onChange(brandId, +event.target.value, cartonCount)
            }
          />
          +
        </CenterRow>
      </td>
      <td>
        <CenterRow>
          <NumberInput
            value={cartonCount}
            onChange={event =>
              onChange(brandId, packCount, +event.target.value)
            }
          />
          =
        </CenterRow>
      </td>
      <td>
        <NumberInput disabled value={packCount + cartonCount} />
      </td>
    </tr>
  );
}

function PromoTableRow(props: {
  packCount: number;
  cartonCount: number;
  brandId: number | null;
  brandName: string;
  activePromos: ActivePromo[];
  onChange?: (promoId: number, packCount: number, cartonCount: number) => any;
}) {
  const {
    packCount,
    brandId,
    cartonCount,
    activePromos,
    brandName,
    onChange = noop,
    ...rest
  } = props;

  return (
    <tr {...rest}>
      <td>
        <Select
          onChange={event =>
            onChange(+event.target.value, packCount, cartonCount)
          }
          value={brandId || ''}
        >
          <option value="">{brandName}</option>
          {activePromos.map(p => (
            <option key={p.brandId} value={p.brandId}>
              {p.promoName}
            </option>
          ))}
        </Select>
      </td>
      <td>
        <CenterRow>
          <NumberInput
            value={packCount}
            onChange={event =>
              onChange(brandId || 0, +event.target.value, cartonCount)
            }
          />
          +
        </CenterRow>
      </td>
      <td>
        <CenterRow>
          <NumberInput
            value={cartonCount}
            onChange={event =>
              onChange(brandId || 0, packCount, +event.target.value)
            }
          />
          =
        </CenterRow>
      </td>
      <td>
        <NumberInput disabled value={packCount + cartonCount} readOnly />
      </td>
    </tr>
  );
}

const CenterRow = styled(Row)`
  align-items: center;
`;

const ProductName = styled('div')`
  white-space: nowrap;
  text-align: right;
`;

const FooterRow = styled(Row)`
  justify-content: space-between;

  label {
    margin-right: 10px;
    width: 120px;
    text-align: right;
  }
`;

const DoneButton = styled(BlockButton)`
  height: 40px;
  margin-top: 30px;
`;

const ClosingColumn = styled(Column)`
  margin-left: 20px;
`;
