import React, { FC, MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import BlockButton from './BlockButton';

interface SideBySideButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

const SideBySideButton: FC<SideBySideButtonProps> = props => {
  const { children, onClick, ...rest } = props;
  return (
    <div {...rest}>
      <BlockButton role="button" onClick={onClick} />
      {children}
    </div>
  );
};

export default styled(SideBySideButton)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  padding-right: 10px;
  & > ${BlockButton} {
    min-height: auto;
    min-width: auto;
    height: 20px;
    width: 40px;
    flex: 0 auto;
    margin-right: 10px;
  }
`;
