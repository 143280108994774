import React from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import MainSwitchboard from '../components/MainSwitchboard';
import withAuth from '../components/withAuth';
import Api from '../api/Api';

const MainSwitchboardPage: PageComponent = props => {
  const { user } = props;
  const routeTo = (route?: string) => () => route && navigate(route);

  function handleExitAppClick() {
    Api.logout();
    if (user) {
      navigate(`/login?store_id=${user.storeId}`);
    } else {
      navigate('/login');
    }
  }

  async function handleShiftSignoffClick() {
    const shiftMaster = await Api.getShiftToSignoff();

    if (shiftMaster) {
      navigate(`/audit-shift/${shiftMaster.shiftDate}/${shiftMaster.shift}`);
    } else {
      alert(
        `There are no shifts that haven't been signed off; if you want to audit a shift use the Audit Shift feature!`,
      );
    }
  }

  return (
    <Page {...props}>
      <MainSwitchboard
        onAdminClick={routeTo('/admin')}
        onManagerClick={routeTo('/manager')}
        onCreateShiftClick={routeTo('/create-shift')}
        onBackToShiftEntryClick={handleShiftSignoffClick}
        onBrandInventoryClick={routeTo('/brand-inventory')}
        onOver21TestClick={routeTo('/over-21-test')}
        onGuestPlayClick={routeTo('/guest-play')}
        onExitAppClick={handleExitAppClick}
        onCloseClick={handleExitAppClick}
        user={user}
        onWorkbookExportClicked={routeTo('/workbook-export')}
      />
    </Page>
  );
};

export default styled(withAuth(MainSwitchboardPage))`
  align-items: center;
  justify-content: center;
`;
