import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import debounce from 'lodash.debounce';
import { navigate } from '@reach/router';
import Page, { PageComponent } from '../components/Page';
import VideoDepositLogInputForm from '../components/VideoDepositLogInputForm';
import withAuth from '../components/withAuth';
import VideoDepositLog from '../models/VideoDepositLog';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import Store from '../models/Store';
import SpinnerOverlay from '../components/SpinnerOverlay';
import isValidDate from '../utils/isValidDate';

interface Props {
  videoDepositLogId: string;
}

const AuditVideoDepositLog: PageComponent<Props> = props => {
  const { user } = props;
  const videoDepositLogId = parseInt(props.videoDepositLogId, 10);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [
    priorVideoDepositLog,
    setPriorVideoDepositLog,
  ] = useState<VideoDepositLog | null>(null);
  const [reviewableVideoDepositLogs, setReviewableVideoDepositLogs] = useState<
    VideoDepositLog[] | null
  >(null);
  const [store, setStore] = useState<Store | null>(null);
  const [value, setValue] = useState<VideoDepositLog | null>(null);

  useAsyncEffect(
    async cancelled => {
      if (!user) {
        return;
      }
      setLoading(true);
      const { storeId } = user;
      const videoDepositLog = await Api.getVideoDepositLog({
        videoDepositLogId,
        storeId,
      });

      if (!videoDepositLog) {
        window.alert('Video deposit log not found.');
        window.history.back();
        return;
      }

      const [userStore, reviewableLogs] = await Promise.all([
        Api.getStoreById(storeId),
        Api.getReviewableVideoDepositLogs(storeId),
      ]);

      const priorVdl = await Api.getPriorVideoDepositLog({
        storeId: user.storeId,
        videoDepositLogId: videoDepositLog.id,
        date: videoDepositLog.date || new Date().toISOString(),
      });

      if (!cancelled()) {
        setPriorVideoDepositLog(priorVdl);
        setValue(videoDepositLog);
        setReviewableVideoDepositLogs(reviewableLogs);
        setStore(userStore);
        setLoading(false);
      }
    },
    [user, videoDepositLogId],
  );

  async function save(videoDepositLog: VideoDepositLog) {
    if (!changed) {
      return;
    }
    try {
      if (!videoDepositLog.date || !isValidDate(videoDepositLog.date)) {
        throw new Error('Invalid date.');
      }
      await Api.patchVideoDepositLog(videoDepositLogId, videoDepositLog);
      setChanged(false);
    } catch (error) {
      alert(error.message);
    }
  }

  async function handleCloseClick(
    videoDepositLog: VideoDepositLog,
    shouldSave: boolean,
  ) {
    if (shouldSave) {
      await save(videoDepositLog);
    }
    navigate('/manager');
  }

  const reloadPriorVdl = useMemo(
    () =>
      debounce(
        async (fields: {
          date: string;
          videoDepositLogId: number;
          storeId: number;
        }) => {
          setLoading(true);
          try {
            const newPriorVdl = await Api.getPriorVideoDepositLog(fields);
            setPriorVideoDepositLog(newPriorVdl);
          } finally {
            setLoading(false);
          }
        },
        2000,
      ),
    [],
  );

  function handleChange(newValue: VideoDepositLog) {
    if (value && value.date && newValue.date && newValue.date !== value.date) {
      // Reload prior VDL if the user changes the VDL date
      reloadPriorVdl({
        date: newValue.date,
        videoDepositLogId: newValue.id,
        storeId: newValue.storeId,
      });
    }
    setValue(newValue);
    setChanged(true);
  }

  if (loading || !value || !user || !store || !reviewableVideoDepositLogs) {
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <VideoDepositLogInputForm
        onSave={save}
        onCloseClick={handleCloseClick}
        storeId={user.storeId}
        employee={user}
        priorVideoDepositLog={priorVideoDepositLog}
        reviewableVideoDepositLogs={reviewableVideoDepositLogs}
        store={store}
        value={value}
        onChange={handleChange}
      />
    </Page>
  );
};

export default styled(withAuth(AuditVideoDepositLog))``;
