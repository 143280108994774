import React from 'react';
import styled from 'styled-components/macro';
import Row from './Row';
import FormBox from './FormBox';
import SideBySideButton from './SideBySideButton';
import Employees from '../models/User';
import isSuperAdmin from '../utils/isSuperAdmin';

export interface MainSwitchboardFormProps {
  onAdminClick?: () => any;
  onManagerClick?: () => any;
  onCreateShiftClick?: () => any;
  onBackToShiftEntryClick?: () => any;
  onBrandInventoryClick?: () => any;
  onOver21TestClick?: () => any;
  onGuestPlayClick?: () => any;
  onExitAppClick?: () => any;
  onCloseClick?: () => any;
  user?: Employees;
  onWorkbookExportClicked?(): any;
}

const MainSwitchboardForm: React.FC<MainSwitchboardFormProps> = props => {
  const {
    onAdminClick,
    onManagerClick,
    onCreateShiftClick,
    onBackToShiftEntryClick,
    onBrandInventoryClick,
    onOver21TestClick,
    onGuestPlayClick,
    onExitAppClick,
    onCloseClick,
    user,
    onWorkbookExportClicked,
    ...rest
  } = props;

  return (
    <FormBox
      {...rest}
      title="Main Switchboard: Form"
      onCloseClick={onCloseClick}
    >
      <Row>
        <SideBySideButton onClick={onAdminClick} data-testid="admin-link">
          Administration
        </SideBySideButton>
        <SideBySideButton onClick={onManagerClick} data-testid="manager-link">
          Manager/Regional
        </SideBySideButton>
      </Row>
      <Row>
        <SideBySideButton
          onClick={onCreateShiftClick}
          data-testid="create-shift-link"
        >
          Create Shift
        </SideBySideButton>
        <SideBySideButton
          onClick={onBackToShiftEntryClick}
          data-testid="back-to-shift-entry-link"
        >
          Back to Shift Entry
        </SideBySideButton>
      </Row>
      <Row>
        {user && isSuperAdmin(user) && (
          <SideBySideButton onClick={onWorkbookExportClicked}>
            Workbook export
          </SideBySideButton>
        )}
        <SideBySideButton onClick={onExitAppClick} data-testid="exit-app-link">
          Exit Application
        </SideBySideButton>
      </Row>
    </FormBox>
  );
};

export default styled(MainSwitchboardForm)`
  width: 500px;

  ${SideBySideButton} {
    width: 50%;
  }

  > ${Row} + ${Row} {
    margin-top: 20px;
  }

  > ${Row}:nth-child(5) {
    justify-content: flex-end;
  }

  > ${Row}:last-child {
    justify-content: space-between;
  }
`;
