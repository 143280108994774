import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import BlankButton from './BlankButton';
import { navigate } from '@reach/router';

const FormBox: React.FC<{
  title?: string;
  backPath?: string;
  tabIndex?: number;
  hideDuringPrinting?: boolean;
  onCloseClick?: () => any;
}> = props => {
  const { title = '', onCloseClick, backPath, ...rest } = props;

  function handleCloseClick() {
    if (onCloseClick) {
      onCloseClick();
      return;
    }
    if (backPath) {
      navigate(backPath);
    } else {
      window.history.back();
    }
  }

  return (
    <Root {...rest}>
      <Header>
        <Title>{title}</Title>
        <CloseButton tabIndex={-1} onClick={handleCloseClick}>
          {'\u2715'}
        </CloseButton>
      </Header>
      {props.children}
    </Root>
  );
};

export default styled(FormBox)``;

const Root = styled(Column)<{ hideDuringPrinting?: boolean }>`
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  padding: 40px 10px 10px 10px;
  background-color: #eeeeee;
  width: 640px;
  max-width: 100%;
  overflow: auto;

  @media print {
    display: ${props => (props.hideDuringPrinting ? 'none' : 'flex')};
    border: none;
    width: 100%;
    padding: 0;
  }
`;

const Header = styled(Row)`
  position: absolute;
  background-color: #a0bcd9;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;

  @media print {
    display: none;
  }
`;

const Title = styled('div')``;

const CloseButton = styled(BlankButton)`
  background-color: #c0c0c0;
  color: black;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #777;
  border-right: 2px solid #777;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 0;
  padding-top: 2px;

  &:active {
    border-top: 2px solid #777;
    border-left: 2px solid #777;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background-color: #b0b0b0;
  }
`;
