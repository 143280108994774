import { AxiosInstance } from 'axios';
import W2G from '../models/W2G';

export async function getW2GReportData(
  axios: AxiosInstance,
  startDate: Date | string,
  endDate: Date | string,
): Promise<W2G[]> {
  const response = await axios.get(
    `/w2g/report/${new Date(startDate).toISOString()}/${new Date(
      endDate,
    ).toISOString()}`,
  );
  return response.data.w2gReportData;
}

export async function getW2gRowsForShift(
  axios: AxiosInstance,
  shiftDate: Date | string,
  shiftNumber: number,
  employeeId: string,
): Promise<W2G[]> {
  const response = await axios.get(
    `/w2g/${new Date(shiftDate).toISOString()}/${shiftNumber}/${employeeId}`,
  );
  return response.data.w2gRows;
}

export async function createW2g(axios: AxiosInstance, w2g: W2G): Promise<W2G> {
  w2g = { ...w2g };
  delete w2g.id;
  const response = await axios.post('/w2g', w2g);
  return response.data.w2g;
}

export async function updateW2g(axios: AxiosInstance, w2g: W2G): Promise<void> {
  await axios.put('/w2g', w2g);
}

export async function deleteW2g(
  axios: AxiosInstance,
  w2gId: number,
): Promise<void> {
  await axios.delete(`/w2g/${w2gId}`);
}
