import React from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import Input from './Input';
import InputRow from './InputRow';
import FormBox from './FormBox';
import BlockButton from './BlockButton';
import EmployeeSearchForm from './EmployeeSearchForm';
import noop from '../utils/noop';

export interface EmployeeFormEmployee {
  employeeId: string;
  firstName: string;
  lastName: string;
  securityCode: number;
  password: string;
}

export interface Props {
  employee?: EmployeeFormEmployee;
  onChange?: (newEmployee: EmployeeFormEmployee) => any;
  onSave?: () => any;
  onSearchClicked?: (employeeId: string, employeeName: string) => Promise<void>;
}

export const defaultEmployee: EmployeeFormEmployee = {
  employeeId: '0',
  firstName: '',
  lastName: '',
  securityCode: 0,
  password: '',
};

const EmployeeForm: React.FC<Props> = props => {
  const {
    employee = defaultEmployee,
    onChange = noop,
    onSave = noop,
    onSearchClicked = noop,
    ...rest
  } = props;
  const { employeeId, firstName, lastName, securityCode, password } = employee;

  function createChangeHandler<K extends keyof EmployeeFormEmployee>(key: K) {
    return (value: EmployeeFormEmployee[K]) => {
      onChange({
        ...employee,
        [key]: value,
      });
    };
  }

  return (
    <FormBox
      {...rest}
      title="Employees"
      onCloseClick={() => navigate('/admin')}
    >
      <InputRow>
        <label>Employee ID</label>
        <Input
          value={employeeId}
          onValueChange={createChangeHandler('employeeId')}
          data-testid="employeeIdInput"
        />
      </InputRow>
      <InputRow>
        <label>First Name</label>
        <Input
          value={firstName}
          onValueChange={createChangeHandler('firstName')}
          data-testid="firstNameInput"
        />
      </InputRow>
      <InputRow>
        <label>Last Name</label>
        <Input
          value={lastName}
          onValueChange={createChangeHandler('lastName')}
          data-testid="lastNameInput"
        />
      </InputRow>
      <InputRow>
        <label>Security Code</label>
        <SecurityCodeInput
          value={securityCode}
          onValueChange={value => createChangeHandler('securityCode')(+value)}
          data-testid="securityCodeInput"
        />
      </InputRow>
      <InputRow>
        <label>Password</label>
        <Input
          value={password}
          onValueChange={createChangeHandler('password')}
          data-testid="passwordInput"
        />
      </InputRow>
      <BlockButton onClick={onSave} data-testid="saveButton">
        Save
      </BlockButton>
      <EmployeeSearchForm onSearchClick={onSearchClicked} />
    </FormBox>
  );
};

export default styled(EmployeeForm)`
  width: 400px;
  max-width: 100%;

  label {
    width: 140px;
  }
  input {
    padding: 0;
    height: 25px;
  }

  ${InputRow} {
    justify-content: flex-start;
  }

  ${BlockButton} {
    height: 30px;
    min-height: 30px;
    width: 60px;
    min-width: 60px;
    padding-top: 2px;
    align-self: center;
  }

  ${EmployeeSearchForm} {
    margin-top: 30px;
  }
`;

const SecurityCodeInput = styled(Input)`
  width: 80px;
  text-align: right;
`;
