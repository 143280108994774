import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import OverShortByEmployeeForm from '../components/OverShortByEmployeeForm';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import ShiftMaster from '../models/ShiftMaster';
import isValidDate from '../utils/isValidDate';
import SpinnerOverlay from '../components/SpinnerOverlay';

const OverShortByEmployeeLog: PageComponent = props => {
  const [data, setData] = useState<ShiftMaster[] | null>(null);

  useAsyncEffect(async () => {
    const beginningDate = window.prompt('Beginning date:');

    if (!beginningDate) {
      window.history.back();
      return;
    }
    if (!isValidDate(beginningDate)) {
      window.alert('Invalid beginning date.');
      return;
    }

    const endingDate = window.prompt('Ending date:');

    if (!endingDate) {
      window.history.back();
      return;
    }
    if (!isValidDate(endingDate)) {
      window.alert('Invalid ending date.');
      return;
    }

    setData(await Api.getEmployeeOverShortData(beginningDate, endingDate));
  }, []);

  if (!data) {
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <OverShortByEmployeeForm data={data} />
    </Page>
  );
};

export default styled(withAuth(OverShortByEmployeeLog))``;
