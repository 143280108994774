import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import InputRow from './InputRow';
import Input from './Input';
import NumberInput from './NumberInput';
import VideoDepositLog from '../models/VideoDepositLog';
import noop from '../utils/noop';

export interface RegionalUseFormProps {
  value: VideoDepositLog;
  onChange?: (value: VideoDepositLog) => any;
}

const RegionalUseForm: React.FC<RegionalUseFormProps> = props => {
  const { value, onChange = noop, ...rest } = props;

  return (
    <Column {...rest}>
      <h3>Regional Use</h3>
      <InputRow>
        <label>Jammed Bills Out::</label>
        <NumberInput
          value={value.atmJammedBillOut || 0}
          onValueChange={atmJammedBillOut =>
            onChange({ ...value, atmJammedBillOut })
          }
          data-testid="jammedBillsOutInput"
        />
      </InputRow>
      <InputRow>
        <label>Jammed Bills Notes::</label>
        <Input
          value={value.atmJammedBillNotes || ''}
          onValueChange={atmJammedBillNotes =>
            onChange({ ...value, atmJammedBillNotes })
          }
          data-testid="jammedBillsNotesInput"
        />
      </InputRow>
      <InputRow>
        <label>Money to Increase Safe/ATM:</label>
        <NumberInput
          value={value.moneytoIncreaseSafeAmount || ''}
          onValueChange={moneytoIncreaseSafeAmount =>
            onChange({ ...value, moneytoIncreaseSafeAmount })
          }
          data-testid="moneyToIncreaseSafeInput"
        />
      </InputRow>
      <InputRow>
        <label>ATM Notes:</label>
        <Input
          value={value.atmIncreaseNotes || ''}
          onValueChange={atmIncreaseNotes =>
            onChange({ ...value, atmIncreaseNotes })
          }
          data-testid="atmNotesInput"
        />
      </InputRow>
      <InputRow>
        <label>VLM Pull and Put in Register</label>
        <NumberInput
          value={value.vlmPullPriorDay || 0}
          onValueChange={vlmPullPriorDay =>
            onChange({ ...value, vlmPullPriorDay })
          }
          data-testid="vlmRegisterPullInput"
        />
      </InputRow>
    </Column>
  );
};

export default styled(RegionalUseForm)`
  ${NumberInput}, ${Input} {
    height: 25px;
    text-align: right;
    padding: 0;
  }

  h3 {
    margin-bottom: 10px;
  }

  > :last-child {
    margin-top: 40px;
  }

  margin-right: 5px;
  margin-left: 5px;
`;
