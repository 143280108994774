import React, { useState } from 'react';
import styled from 'styled-components';
import flatMap from 'lodash/flatMap';
import Row from './Row';
import Input from './Input';
import Checkbox from './Checkbox';
import Select from './Select';
import replaceWhere from '../utils/replaceWhere';
import BlockButton from './BlockButton';
import FormBox from './FormBox';
import ScrollableColumn from '../components/ScrollableColumn';
import noop from '../utils/noop';
import ScratchTickets from '../models/ScratchTickets';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import ScratchTicketDetails, {
  ScratchTicketDetailsDraft,
  scratchTicketDetailsFromDraft,
} from '../models/ScratchTicketDetails';

export interface ScratchTicket2FormProps {
  shiftDate: string;
  shiftNumber: number;
  readOnly: boolean;
  onSubmit?: (value: ScratchTicketDetails[]) => any;
  onCancel?: () => any;
}

const ScratchTicket2Form: React.FC<ScratchTicket2FormProps> = props => {
  const [value, setValue] = useState<ScratchTicketDetailsDraft[] | null>(null);
  const [scratchTickets, setScratchTickets] = useState<ScratchTickets[]>([]);
  const {
    shiftDate,
    shiftNumber,
    readOnly,
    onSubmit = noop,
    onCancel,
    ...rest
  } = props;

  useAsyncEffect(async () => {
    setValue(await Api.getShiftScratchTickets(shiftDate, shiftNumber));
    setScratchTickets(await Api.getScratchTickets());
  }, [shiftDate, shiftNumber]);

  if (!value) {
    return null;
  }

  function handleEnterClick() {
    if (value) {
      onSubmit(
        value.map(scratchTicketDetailsFromDraft).map(row => ({
          ...row,
          amountSold:
            (row.endingInventory || 0) - (row.beginningInventory || 0),
        })),
      );
    }
  }

  /**
   * When the out box is checked, a new row is added below it with the same slot and
   * an incremented replacement number.
   */
  function handleOutToggle(scratchTicket: ScratchTicketDetailsDraft) {
    if (!value || readOnly) {
      return;
    }
    setValue(
      flatMap(value, (ticket, i) => {
        if (ticket !== scratchTicket) {
          return [ticket];
        }
        const outFlag = ticket.outFlag ? 0 : -1;

        if (outFlag !== 0) {
          const replacementExists = value.some(
            (other, j) => other.slot === ticket.slot && j > i,
          );
          const confirmed =
            replacementExists ||
            window.confirm('Are you sure you want to replace this game?');
          if (confirmed) {
            if (replacementExists) {
              return [{ ...ticket, outFlag }];
            }
            return [
              { ...ticket, outFlag },
              {
                ...ticket,
                gameNumber: 0,
                beginningInventory: 0,
                endingInventory: 0,
                replacementFlag: +ticket.replacementFlag + 1,
              },
            ];
          }
          return [ticket];
        } else {
          return [{ ...ticket, outFlag }];
        }
      }),
    );
  }

  function handleBeginningInventoryChange(
    scratchTicket: ScratchTicketDetailsDraft,
    beginningInventory: string,
  ) {
    if (!value) {
      return;
    }
    setValue(
      replaceWhere(
        value,
        ticket => ticket === scratchTicket,
        ticket => ({
          ...ticket,
          beginningInventory,
          amountSold: +(ticket.endingInventory || 0) - +beginningInventory,
        }),
      ),
    );
  }

  function handleEndingInventoryChange(
    scratchTicket: ScratchTicketDetailsDraft,
    endingInventory: string,
  ) {
    if (!value) {
      return;
    }
    setValue(
      replaceWhere(
        value,
        ticket => ticket === scratchTicket,
        ticket => ({
          ...ticket,
          endingInventory,
          amountSold: +endingInventory - +(ticket.beginningInventory || 0),
        }),
      ),
    );
  }

  function handleGameNumberChange(
    scratchTicket: ScratchTicketDetailsDraft,
    gameNumber: string,
  ) {
    if (!value) {
      return;
    }
    setValue(
      replaceWhere(
        value,
        ticket => ticket === scratchTicket,
        ticket => ({
          ...ticket,
          gameNumber,
        }),
      ),
    );
  }

  return (
    <FormBox {...rest} title="Scratch Ticket 2" onCloseClick={handleEnterClick}>
      <ScrollableColumn>
        <LabelRow>
          <SlotNumberLabel>Slot #</SlotNumberLabel>
          <ShiftDateLabel>Shift Date</ShiftDateLabel>
          <ShiftNumberLabel>Shift</ShiftNumberLabel>
          <GameNumberSLabel>Game #</GameNumberSLabel>
          <StartingSequenceLabel>Starting Seq #</StartingSequenceLabel>
          <EndingSequenceLabel>Ending Seq #</EndingSequenceLabel>
          <SoldLabel>Sold</SoldLabel>
          <OutLabel>Out</OutLabel>
        </LabelRow>
        {value.map((ticket, i) => (
          <FormRow key={i}>
            <SlotNumberInput readOnly value={ticket.slot} />
            <ShiftDateInput
              readOnly
              type="date"
              value={shiftDate.slice(0, 10)}
            />
            <ShiftNumberInput readOnly value={shiftNumber} />
            <GameNumberSelect
              readOnly={readOnly}
              value={ticket.gameNumber || ''}
              onValueChange={gameNumber =>
                handleGameNumberChange(ticket, gameNumber)
              }
              data-testid={`ticketGameNumberInput${i}`}
            >
              <option disabled value="">
                empty
              </option>
              <GameSelectOptions scratchTickets={scratchTickets} />
            </GameNumberSelect>
            <StartingSequenceInput
              readOnly={readOnly}
              value={ticket.beginningInventory || 0}
              onValueChange={beginningInventory =>
                handleBeginningInventoryChange(ticket, beginningInventory)
              }
              tabIndex={1 + i * 3}
              data-testid={`ticketStartingSequenceInput${i}`}
            />
            <EndingSequenceInput
              readOnly={readOnly}
              value={ticket.endingInventory || 0}
              onValueChange={endingInventory =>
                handleEndingInventoryChange(ticket, endingInventory)
              }
              tabIndex={2 + i * 3}
              data-testid={`ticketEndingSequenceInput${i}`}
            />
            <SoldInput readOnly value={ticket.amountSold || 0} />
            <OutCheckbox
              readOnly={readOnly}
              checked={!!ticket.outFlag}
              disabled={!!ticket.outFlag}
              tabIndex={3 + i * 3}
              onClick={() => handleOutToggle(ticket)}
              data-testid={`ticketOutCheckbox${i}`}
            />
          </FormRow>
        ))}
      </ScrollableColumn>
      <ButtonRow>
        <BlockButton onClick={handleEnterClick} data-testid="enterButton">
          Enter
        </BlockButton>
      </ButtonRow>
    </FormBox>
  );
};

const GameSelectOptions = React.memo(
  (props: { scratchTickets: ScratchTickets[] }) => (
    <>
      {props.scratchTickets.map(scratchTicket => (
        <option key={scratchTicket.gameNumber} value={scratchTicket.gameNumber}>
          {scratchTicket.gameName} {scratchTicket.gameNumber}
        </option>
      ))}
    </>
  ),
);

export default styled(ScratchTicket2Form)`
  min-width: 780px;
  margin: auto;
`;

const FormRow = styled(Row)`
  align-items: center;
  border: 1px solid black;
  border-right: 1px solid transparent;
  height: 30px;
  > * + * {
    margin-left: 20px;
  }

  > input,
  > select {
    height: 25px;
  }
`;

const LabelRow = styled(Row)``;

const SlotNumberInput = styled(Input)`
  max-width: 50px;
  padding: 0;
  padding-right: 4px;
  text-align: right;
`;
const ShiftDateInput = styled(Input)`
  max-width: 110px;
  padding: 0;
  padding-right: 4px;
  text-align: right;
`;
const ShiftNumberInput = styled(Input)`
  max-width: 50px;
  padding: 0;
  padding-right: 4px;
  text-align: right;
`;
const GameNumberSelect = styled(Select)`
  width: 120px;
  margin-right: 40px;
`;
const StartingSequenceInput = styled(Input)`
  max-width: 50px;
  text-align: center;
  padding: 0;
`;
const EndingSequenceInput = styled(Input)`
  max-width: 50px;
  text-align: center;
  padding: 0;
`;
const SoldInput = styled(Input)`
  max-width: 50px;
  text-align: center;
  padding: 0;
`;
const OutCheckbox = styled(Checkbox)``;

const SlotNumberLabel = styled('div')`
  margin: 0 50px 0 10px;
`;
const ShiftDateLabel = styled('div')`
  margin-right: 50px;
`;
const ShiftNumberLabel = styled('div')`
  margin-right: 40px;
`;
const GameNumberSLabel = styled('div')`
  margin-right: 80px;
`;
const StartingSequenceLabel = styled('div')`
  margin-right: 10px;
`;
const EndingSequenceLabel = styled('div')`
  margin-right: 10px;
`;
const SoldLabel = styled('div')`
  margin-right: 15px;
`;
const OutLabel = styled('div')``;

const ButtonRow = styled(Row)`
  justify-content: center;
  margin-top: 10px;
`;
