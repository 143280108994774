import React, { useState, MouseEvent, FormEvent } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Input from './Input';
import Form from './Form';
import InputRow from './InputRow';
import BlockLink from './BlockLink';
import BlockButton from './BlockButton';
import FormBox from './FormBox';
import OkDialog from './OkDialog';
import Employees from '../models/Employees';
import isValidDate from '../utils/isValidDate';

export type ManagersShiftAuditSubmitResult = { date: Date; shift: number };
export type ManagersShiftAuditFormOnSubmit = (
  result: ManagersShiftAuditSubmitResult,
) => any;
export interface ManagersShiftAuditFormProps {
  user?: Employees;
  onAuditSubmit?: ManagersShiftAuditFormOnSubmit;

  onCloseClick?: () => any;
  onOverShortLogClick?: () => any;
  onCigaretteOverShortClick?: () => any;
  onVideoDepositLogClick?: () => any;
  onVideoDepositManagerClick?: () => any;
  onPayoutReportclick?: () => any;
  onW2gReportClick?: () => any;
  onScratchTicketInventoryClick?: () => any;
  onOverShortByEmployeeClick?: () => any;
  onDeleteShiftClicked?: () => any;
}

let prevShiftDate: string | undefined;
let prevShiftNumber: string | undefined;

const ManagersShiftAuditForm: React.FC<ManagersShiftAuditFormProps> = props => {
  const {
    user,
    onAuditSubmit,
    onCloseClick,
    onOverShortLogClick,
    onCigaretteOverShortClick,
    onVideoDepositLogClick,
    onVideoDepositManagerClick,
    onPayoutReportclick,
    onW2gReportClick,
    onScratchTicketInventoryClick,
    onOverShortByEmployeeClick,
    onDeleteShiftClicked,
    ...rest
  } = props;

  const [auditDate, setAuditDate] = useState(
    () => prevShiftDate || new Date().toISOString().slice(0, 10),
  );
  const [shift, setShift] = useState(prevShiftNumber || '');
  const [validationError, setValidationError] = useState<string>('');
  const [showValidationError, setShowValidationError] = useState<boolean>(
    false,
  );

  function handleCloseClick(event: MouseEvent | null) {
    if (event) {
      event.preventDefault(); // stop audit form from submitting
    }
    if (onCloseClick) {
      onCloseClick();
    }
  }

  function handleValidationErrorClose() {
    setShowValidationError(false);
    setValidationError('');
  }

  function handleAuditFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const auditDateObject = new Date(auditDate + 'T00:00:00.000Z');
    if (!shift) {
      setValidationError('Invalid shift number');
      setShowValidationError(true);
      return;
    }
    if (!isValidDate(auditDateObject)) {
      setValidationError('Invalid shift date');
      setShowValidationError(true);
      return;
    }

    prevShiftDate = auditDate;
    prevShiftNumber = shift;

    if (onAuditSubmit) {
      onAuditSubmit({
        date: auditDateObject,
        shift: parseInt(shift, 10),
      });
    }
  }

  return (
    <FormBox
      {...rest}
      title="AuditShift : Form"
      onCloseClick={() => handleCloseClick(null)}
    >
      <AuditForm onSubmit={handleAuditFormSubmit} data-testid="auditForm">
        <h1>Manager's Shift Audit</h1>
        <InputRow>
          <label>Enter Date:</label>
          <Input
            type="date"
            value={auditDate}
            onValueChange={setAuditDate}
            data-testid="auditDateInput"
          />
        </InputRow>
        <InputRow>
          <label>Enter Shift:</label>
          <Input
            type="number"
            value={shift}
            onValueChange={setShift}
            data-testid="auditShiftInput"
          />
        </InputRow>
        <InputRow>
          <BlockButton data-testid="auditShiftButton" type="submit">
            Audit Shift
          </BlockButton>
          <BlockButton onClick={handleCloseClick} data-testid="closeButton">
            Close
          </BlockButton>
        </InputRow>
      </AuditForm>
      <ButtonColumn>
        <ColumnHeader>
          Manager
          <br />
          (use only)
        </ColumnHeader>
        <BlockLink
          onClick={onOverShortLogClick}
          data-testid="overShortLogbutton"
        >
          Over/Short Log
        </BlockLink>
        <BlockLink
          onClick={onVideoDepositLogClick}
          data-testid="videoDepositLogButton"
        >
          Video Deposit Log
        </BlockLink>
        <BlockLink
          onClick={onPayoutReportclick}
          data-testid="payoutReportButton"
        >
          Payout Report
        </BlockLink>
        <BlockLink onClick={onW2gReportClick} data-testid="w2gReportButton">
          W2-g Report
        </BlockLink>
        <BlockLink
          onClick={onScratchTicketInventoryClick}
          data-testid="scratchTicketInventoryButton"
        >
          Scratch Ticket Inventory
        </BlockLink>
      </ButtonColumn>
      <ButtonColumn>
        <ColumnHeader>
          Regional Manager <br />
          (use only)
        </ColumnHeader>
        <BlockLink
          onClick={onVideoDepositManagerClick}
          data-testid="videoDepositManagerButton"
        >
          Cassette + Manual Drop Deposit
        </BlockLink>
        {user && user.employeeId === '9999' && (
          <BlockLink
            data-testid="databaseTables"
            onClick={onDeleteShiftClicked}
          >
            Delete Shift
          </BlockLink>
        )}
        <BlockLink
          onClick={onOverShortByEmployeeClick}
          data-testid="overShortByEmployeeButton"
        >
          Over/Short by Employee
        </BlockLink>
      </ButtonColumn>
      {showValidationError && (
        <OkDialog
          message={validationError}
          onOkClicked={handleValidationErrorClose}
          onClose={handleValidationErrorClose}
        />
      )}
    </FormBox>
  );
};

export default styled(ManagersShiftAuditForm)`
  flex-direction: row;
  width: 1024px;
  max-width: 100%;
`;

const ColumnHeader = styled(`div`)`
  text-align: center;
  margin-bottom: 10px;
`;

const AuditForm = styled(Form)`
  padding-right: 50px;

  ${Input} {
    width: 200px;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  label {
    margin-right: 20px;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 10px;
  }
`;

const ButtonColumn = styled(Column)`
  flex: 1;

  ${BlockLink} + ${BlockLink} {
    margin-top: 10px;
  }

  & + & {
    margin-left: 10px;
  }
`;
