import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ReactList from 'react-list';
import Column from './Column';
import InputRow from './InputRow';
import FormBox from './FormBox';
import Row from './Row';
import DateInput from './DateInput';
import Checkbox from './Checkbox';
import NumberInput from './NumberInput';
import Input from './Input';
import { FaAsterisk } from './icons';
import BlockButton from './BlockButton';
import ScrollableColumn from './ScrollableColumn';
import CheckMaster from '../models/CheckMaster';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import noop from '../utils/noop';
import getErrorMessage from '../utils/getErrorMessage';
import isBlank from '../utils/isBlank';
import replaceWhere from '../utils/replaceWhere';
import formatISODate from '../utils/formatISODate';

const cutoffInterval = 1000 * 60 * 60 * 24 * 60; // 60 days

interface Props {
  storeId: number;
  shiftDate: Date | string;
  shiftNumber: number;
  readOnly: boolean;
  onSubmit?: (totals: { nonPrizeTotal: number; winnerTotal: number }) => any;
  onCancel?: () => any;
}

interface EntryProps {
  entry: CheckMaster;
  readOnly: boolean;
  newEntry?: boolean;
  onEntryChanged?: (entry: CheckMaster) => any;
  onAddClick?: () => any;
}

const CheckRegister: React.FC<Props> = props => {
  const initialNewEntry: CheckMaster = {
    amount: 0,
    checkNumber: 0,
    dateActivated: null,
    dateWritten: '',
    name: '',
    payee: '',
    payout: 0,
    prize: 0,
    reason: '',
    shiftActivated: 0,
    shiftWritten: 0,
    storeId: 0,
    timestamp: new Date().toISOString(),
  };
  const [entries, setEntries] = useState<CheckMaster[]>([]);
  const [newEntry, setNewEntry] = useState<CheckMaster>(initialNewEntry);
  const {
    storeId,
    shiftDate,
    shiftNumber,
    readOnly,
    onSubmit,
    onCancel,
    ...rest
  } = props;

  const pastCutoff = new Date(shiftDate).getTime() - cutoffInterval;
  const futureCutoff = new Date(shiftDate).getTime() + cutoffInterval;

  useAsyncEffect(async () => {
    setEntries(await Api.getChecks());
  }, []);

  async function handleAddEntry() {
    if (readOnly) {
      return;
    }
    try {
      if (isBlank(newEntry.payee)) {
        throw new Error('Payee field must not be blank.');
      }
      if (isBlank(newEntry.name)) {
        throw new Error('Team member field must not be blank.');
      }
      if (!hasValidDate(newEntry)) {
        const pastCutoffDate = new Date(pastCutoff);
        const futureCutoffDate = new Date(futureCutoff);
        window.alert(
          `Date must be between ${formatISODate(
            pastCutoffDate.toISOString(),
          )} and ${formatISODate(futureCutoffDate.toISOString())}.`,
        );
        return;
      }
      await Api.createCheckMaster(newEntry);
      setEntries([...entries, newEntry]);
      setNewEntry(initialNewEntry);
    } catch (error) {
      window.alert(getErrorMessage(error));
    }
  }

  async function handleChangeEntry(
    oldEntry: CheckMaster,
    updatedEntry: CheckMaster,
  ) {
    setEntries(
      replaceWhere(entries, other => other === oldEntry, () => updatedEntry),
    );
    if (!readOnly && hasValidDate(updatedEntry)) {
      await Api.updateCheckMaster(oldEntry.checkNumber, updatedEntry);
    }
  }

  async function handleSaveClick() {
    if (onSubmit) {
      onSubmit(
        await Api.getCheckTotals(
          new Date(shiftDate).toISOString(),
          shiftNumber,
        ),
      );
    }
  }

  function hasValidDate(entry: CheckMaster) {
    const dateWrittenTime =
      entry.dateWritten && new Date(entry.dateWritten).getTime();
    return (
      dateWrittenTime &&
      dateWrittenTime >= pastCutoff &&
      dateWrittenTime <= futureCutoff
    );
  }

  return (
    <FormBox {...rest} title="Check Register" onCloseClick={handleSaveClick}>
      <CheckRegisterBody>
        <HeaderRow>
          <PlaceholderColumn>{/* layout placeholder */}</PlaceholderColumn>
          <StoreIdColumn>
            <label>Store ID</label>
          </StoreIdColumn>
          <CheckNumberColumn>
            <label>Check Number</label>
          </CheckNumberColumn>
          <AmountColumn>
            <label>Amount</label>
          </AmountColumn>
          <PayeeColumn>
            <label>Payee</label>
          </PayeeColumn>
          <ShiftColumn>
            <label>Shift</label>
          </ShiftColumn>
          <DateColumn>
            <label>Date</label>
          </DateColumn>
          <TeamMemberColumn>
            <label>Team Member</label>
          </TeamMemberColumn>
          <PayoutColumn>
            <label>Payout</label>
          </PayoutColumn>
          <DescriptionColumn>
            <label>Description</label>
          </DescriptionColumn>
        </HeaderRow>
        <RegisterEntry
          newEntry
          readOnly={readOnly}
          entry={newEntry}
          onEntryChanged={setNewEntry}
          onAddClick={handleAddEntry}
        />
        <ListContainer>
          <ReactList
            type="uniform"
            length={entries.length}
            itemRenderer={(index, key) => (
              <RegisterEntry
                key={key}
                readOnly={readOnly}
                entry={entries[index]}
                onEntryChanged={updatedEntry =>
                  handleChangeEntry(entries[index], updatedEntry)
                }
              />
            )}
          />
        </ListContainer>

        <ButtonRow>
          <BlockButton onClick={handleSaveClick}>Save</BlockButton>
          <BlockButton onClick={onCancel}>Exit</BlockButton>
        </ButtonRow>
      </CheckRegisterBody>
    </FormBox>
  );
};

const RegisterEntry: React.FC<EntryProps> = props => {
  const {
    entry,
    newEntry,
    readOnly,
    onAddClick,
    onEntryChanged = noop,
  } = props;
  const isDisabled = newEntry === true && entry.checkNumber === 0;

  function onStoreIdChanged(storeIdNum: number) {
    onEntryChanged({
      ...entry,
      storeId: storeIdNum,
    });
  }

  function onCheckNumberChanged(checkNum: number) {
    onEntryChanged({
      ...entry,
      checkNumber: checkNum,
    });
  }

  function onAmountChange(checkAmount: number) {
    onEntryChanged({ ...entry, amount: checkAmount });
  }

  function onPayeeChanged(checkPayee: string) {
    onEntryChanged({ ...entry, payee: checkPayee });
  }

  function onShiftChanged(shiftWritten: number) {
    onEntryChanged({ ...entry, shiftWritten });
  }

  function onEntryDateChanged(dateWritten: Date) {
    onEntryChanged({ ...entry, dateWritten: dateWritten.toISOString() });
  }

  function onTeamMemberChanged(name: string) {
    onEntryChanged({ ...entry, name });
  }

  function onPayoutChanged(payout: boolean) {
    onEntryChanged({ ...entry, payout: payout ? 1 : 0 });
  }

  function onDescriptionChanged(reason: string) {
    onEntryChanged({ ...entry, reason });
  }

  return (
    <EntryRow>
      <PlaceholderColumn>
        <EmptyCell>
          {newEntry && (
            <span>
              <FaAsterisk size="0.5em" />
            </span>
          )}
        </EmptyCell>
      </PlaceholderColumn>
      <StoreIdColumn>
        <Cell>
          <NumberInput
            readOnly={readOnly}
            value={entry.storeId}
            onValueChange={onStoreIdChanged}
            disabled={isDisabled}
          />
        </Cell>
      </StoreIdColumn>
      <CheckNumberColumn>
        <Cell>
          <NumberInput
            readOnly={readOnly}
            value={entry.checkNumber}
            onValueChange={onCheckNumberChanged}
          />
        </Cell>
      </CheckNumberColumn>
      <AmountColumn>
        <Cell>
          <NumberInput
            readOnly={readOnly}
            value={entry.amount || 0}
            onValueChange={onAmountChange}
            disabled={isDisabled}
          />
        </Cell>
      </AmountColumn>
      <PayeeColumn>
        <Cell>
          <Input
            readOnly={readOnly}
            value={entry.payee || ''}
            onValueChange={onPayeeChanged}
            disabled={isDisabled}
          />
        </Cell>
      </PayeeColumn>
      <ShiftColumn>
        <Cell>
          <NumberInput
            readOnly={readOnly}
            value={entry.shiftWritten || 0}
            onValueChange={onShiftChanged}
            disabled={isDisabled}
          />
        </Cell>
      </ShiftColumn>
      <DateColumn>
        <Cell>
          <DateInput
            readOnly={readOnly}
            value={isDisabled ? '' : entry.dateWritten || ''}
            onValueChange={onEntryDateChanged}
            disabled={isDisabled}
          />
        </Cell>
      </DateColumn>
      <TeamMemberColumn>
        <Cell>
          <Input
            readOnly={readOnly}
            value={entry.name || ''}
            onValueChange={onTeamMemberChanged}
            disabled={isDisabled}
          />
        </Cell>
      </TeamMemberColumn>
      <PayoutColumn>
        <Cell>
          <Checkbox
            readOnly={readOnly}
            checked={entry.payout ? true : false}
            onValueChange={onPayoutChanged}
            disabled={isDisabled}
          />
        </Cell>
      </PayoutColumn>
      <DescriptionColumn>
        <Cell>
          <Input
            readOnly={readOnly}
            value={entry.reason || ''}
            onValueChange={onDescriptionChanged}
            disabled={isDisabled}
          />
        </Cell>
      </DescriptionColumn>
      {newEntry && !readOnly && (
        <AddColumn>
          <BlockButton disabled={isDisabled} onClick={onAddClick}>
            Add
          </BlockButton>
        </AddColumn>
      )}
    </EntryRow>
  );
};

export default styled(CheckRegister)`
  width: auto;
  align-self: center;
`;

const ListContainer = styled(Column)`
  height: 300px;
  overflow: auto;
`;

const Cell = styled(Column)`
  height: 50px;
`;

const EmptyCell = styled(Cell)`
  border-right: solid 1px black;
  width: 20px;
  font-weight: bold;
  > span {
    align-self: flex-end;
  }
`;

const HeaderRow = styled(Row)`
  align-items: center;
  margin-bottom: 2px;
  > label {
    text-align: center;
  }
`;

const RowEntryInputHeight = css`
  height: 25px;
`;

const EntryRow = styled(InputRow)`
  border: solid 1px black;
  border-right: solid 1px transparent;
  margin-bottom: 0;
  justify-content: flex-start;
  & input {
    ${RowEntryInputHeight}
  }
`;

const ButtonRow = styled(Row)`
  justify-content: center;
  align-self: center;
  width: 100%;
  margin-right: 100px;
  margin: 20px 100px auto auto;

  > :first-child {
    margin-right: 150px;
  }
`;

const CheckRegisterBody = styled(ScrollableColumn)`
  max-height: 95%;
  overflow-y: auto;
  padding-right: 15px;
`;

const AlignmentColumn = styled(Column)`
  margin: auto 5px auto 5px;
  & input {
    margin-top: 3px;
    padding: 0;
  }
`;

const PlaceholderColumn = styled(AlignmentColumn)`
  width: 20px;
`;

const StoreIdColumn = styled(AlignmentColumn)`
  width: 55px;
  ${NumberInput} {
    width: 55px;
    text-align: right;
  }
`;

const CheckNumberColumn = styled(AlignmentColumn)`
  width: 80px;
  label {
    align-self: center;
    text-align: center;
  }

  ${NumberInput} {
    width: 80px;
    text-align: right;
  }
`;

const AmountColumn = styled(AlignmentColumn)`
  width: 100px;
  label {
    align-self: flex-end;
  }

  ${NumberInput} {
    width: 100px;
    margin-top: 3px;
    ${RowEntryInputHeight}
    input {
      margin-top: 0;
    }
    text-align: right;
  }
`;

const PayeeColumn = styled(AlignmentColumn)`
  width: 225px;
  label {
    align-self: center;
  }
`;

const ShiftColumn = styled(AlignmentColumn)`
  width: 52px;
  label {
    align-self: center;
  }
`;

const DateColumn = styled(AlignmentColumn)`
  width: 150px;
  label {
    align-self: center;
  }
`;

const TeamMemberColumn = styled(AlignmentColumn)`
  width: 190px;
  label {
    align-self: center;
  }
`;

const AddColumn = styled(AlignmentColumn)`
  width: 50px;

  ${BlockButton} {
    width: 100%;
    min-width: 0;
  }
`;

const PayoutColumn = styled(AlignmentColumn)`
  margin-left: 40px;
  margin-right: 30px;
`;

const DescriptionColumn = styled(AlignmentColumn)`
  width: 225px;
  label {
    padding-left: 45px;
  }
`;
