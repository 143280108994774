import React, { useState } from 'react';
import styled from 'styled-components/macro';
import InputRow from './InputRow';
import Row from './Row';
import MoneyInput from './MoneyInput';
import BlockButton from './BlockButton';
import FormBox from './FormBox';
import ShiftMaster, {
  ShiftMasterDraft,
  shiftMasterFromDraft,
} from '../models/ShiftMaster';
import noop from '../utils/noop';
import Input from './Input';

interface Extra {
  enjoySales: number;
}

export interface CashRegisterReportFormProps {
  value: ShiftMasterDraft & Extra;
  readOnly: boolean;
  onSubmit?: (value: ShiftMaster & Extra) => any;
  onCancel?: () => any;
}

const CashRegisterReportForm: React.FC<CashRegisterReportFormProps> = props => {
  const [value, setValue] = useState<
    ShiftMasterDraft & { enjoySales: number | string }
  >(() => ({
    ...props.value,
    cigaretteDiscount: (
      ((props.value.cigaretteDiscount && +props.value.cigaretteDiscount) || 0) -
      ((props.value.cigaretteCoupons && +props.value.cigaretteCoupons) || 0)
    ).toFixed(2),
  }));
  const { onSubmit = noop, onCancel = noop, readOnly, ...rest } = props;

  function handleEnterClick() {
    const shiftMaster = shiftMasterFromDraft(value);
    onSubmit({
      ...shiftMaster,
      enjoySales: +value.enjoySales,
      cigaretteDiscount:
        (shiftMaster.cigaretteDiscount || 0) +
        (shiftMaster.cigaretteCoupons || 0),
    });
  }

  return (
    <FormBox {...rest} title="CRREPORT : Form" onCloseClick={handleEnterClick}>
      <h1>Cash Register Report</h1>

      <Subtitle>Z1 sub total (DOLLARS)</Subtitle>

      <InputRow>
        <label>Sales Tax</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.salesTax === null ? '0' : value.salesTax}
          onValueChange={salesTax => setValue({ ...value, salesTax })}
        />
      </InputRow>
      <InputRow>
        <label>Food</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.food === null ? '0' : value.food}
          onValueChange={food => setValue({ ...value, food })}
        />
      </InputRow>
      <InputRow>
        <label>Beverage</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.nonAlcohol === null ? '0' : value.nonAlcohol}
          onValueChange={nonAlcohol => setValue({ ...value, nonAlcohol })}
        />
      </InputRow>
      <InputRow>
        <label>Alcohol</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.alcoholBeverages === null ? '0' : value.alcoholBeverages}
          onValueChange={alcoholBeverages =>
            setValue({ ...value, alcoholBeverages })
          }
        />
      </InputRow>
      <InputRow>
        <label>Food Discount</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.foodDiscount === null ? '0' : value.foodDiscount}
          onValueChange={foodDiscount => setValue({ ...value, foodDiscount })}
        />
      </InputRow>
      <InputRow>
        <label>Miscellaneous</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.enjoySales}
          onValueChange={enjoySales => setValue({ ...value, enjoySales })}
        />
      </InputRow>
      <ButtonRow>
        <BlockButton onClick={handleEnterClick} data-testid="enterButton">
          Enter
        </BlockButton>
        <BlockButton onClick={onCancel} data-testid="cancelButton">
          Cancel
        </BlockButton>
      </ButtonRow>
    </FormBox>
  );
};

export default styled(CashRegisterReportForm)`
  width: 800px;
  margin: auto;
  h1 {
    text-align: center;
  }

  ${InputRow} {
    width: 350px;
  }

  ${MoneyInput} {
    width: 150px;
  }
`;

const Subtitle = styled('div')`
  width: 350px;
  padding: 10px 0;
  text-align: right;
`;

const ButtonRow = styled(Row)`
  border-top: 4px solid black;
  padding-top: 8px;
  margin-top: 8px;
  justify-content: space-evenly;
  width: 700px;
  ${BlockButton} {
    min-width: unset;
    width: 90px;
    text-transform: uppercase;
  }
`;
