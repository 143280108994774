import { AxiosInstance } from 'axios';
import BaseShiftData from '../models/BaseShiftData';
import ShiftMaster from '../models/ShiftMaster';

export async function addOrUpdateShiftMaster(
  axios: AxiosInstance,
  shiftMaster: Partial<ShiftMaster>,
): Promise<ShiftMaster | null> {
  const { data } = await axios.post('/shift-master', shiftMaster);
  return data;
}

export async function getMostRecentShiftInfo(
  axios: AxiosInstance,
): Promise<BaseShiftData> {
  const { data } = await axios.get('/shift-master');
  return data;
}

export async function createShiftMaster(
  axios: AxiosInstance,
  shiftMaster: Partial<ShiftMaster>,
): Promise<ShiftMaster | null> {
  const { data } = await axios.put('/create-shift-master', shiftMaster);
  return data;
}

export async function getPreviousDaysShiftMaster(
  axios: AxiosInstance,
  shift: number,
): Promise<BaseShiftData> {
  const { data } = await axios.get(`/shift-master/previous-day/${shift}`);
  return data;
}
