import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import CigOverShortForm, {
  CigOverShortEntry,
} from '../components/CigOverShortForm';
import withAuth from '../components/withAuth';
import Api from '../api/Api';
import isValidDate from '../utils/isValidDate';
import SpinnerOverlay from '../components/SpinnerOverlay';

const CigOverShort: PageComponent = props => {
  const { user, ...rest } = props;
  const [cigOverShortData, setCigOverShortData] = useState<
    CigOverShortEntry[] | null
  >(null);

  async function load() {
    const startDateString = prompt('Enter beginning date:');

    if (!startDateString || !isValidDate(startDateString)) {
      alert('Invalid date');
    }

    const endDateString = prompt('Enter ending date:');

    if (!endDateString || !isValidDate(endDateString)) {
      alert('Invalid date');
    }

    const startDate = new Date(startDateString as string)
      .toISOString()
      .replace(/T.*/, 'T00:00:00.000Z');
    const endDate = new Date(endDateString as string)
      .toISOString()
      .replace(/T.*/, 'T00:00:00.000Z');

    const data = await Api.getCigOverShortData(startDate, endDate);
    setCigOverShortData(data);
  }

  useEffect(() => {
    load();
  }, []);

  if (!cigOverShortData) {
    // Loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...rest}>
      <CigOverShortForm
        storeId={user ? user.storeId : 0}
        weeks={cigOverShortData}
      />
    </Page>
  );
};

export default styled(withAuth(CigOverShort))``;
