import React from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import ScratchTicketBackStockForm from '../components/ScratchTicketBackStockForm';
import withAuth from '../components/withAuth';

interface Props {
  inventoryDate: string;
}

const ScratchTicketBackstockPage: PageComponent<Props> = props => {
  const { inventoryDate, ...rest } = props;

  return (
    <Page {...rest}>
      <ScratchTicketBackStockForm inventoryDate={inventoryDate} />
    </Page>
  );
};

export default styled(withAuth(ScratchTicketBackstockPage))``;
