import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import DateText from './DateText';
import last from '../utils/last';
import first from '../utils/first';
import FormBox from '../components/FormBox';
import formatISODate from '../utils/formatISODate';

export interface CigOverShortEntry {
  id: number;
  beginningInventory: number;
  cigaretteOrder: number;
  mcdonaldsTransfers: number;
  innerCompanyTransfers: number;
  pack: number;
  carton: number;
  overRings: number;
  expectedInventory: number;
  actualInventory: number;
  date: string;
}

export interface CigOverShortFormProps {
  storeId: number;
  weeks: CigOverShortEntry[];
}

const CigOverShortForm: React.FC<CigOverShortFormProps> = props => {
  const { storeId, weeks, ...rest } = props;
  const sortedWeeks = weeks.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });
  const firstWeek = first(sortedWeeks);
  const lastWeek = last(sortedWeeks);

  const beginningDate = firstWeek && firstWeek.date;
  const endingDate = lastWeek && lastWeek.date;
  const packsTotal = weeks.reduce((total, week) => total + week.pack, 0);
  const cartonsTotal = weeks.reduce((total, week) => total + week.carton, 0);

  const cigaretteOrderTotal = weeks.reduce(
    (total, week) => total + week.cigaretteOrder,
    0,
  );
  const innerCompanyTransferTotal = weeks.reduce(
    (total, week) => total + week.innerCompanyTransfers,
    0,
  );
  const overRingsTotal = weeks.reduce(
    (total, week) => total + week.overRings,
    0,
  );
  const overShortTotal = weeks.reduce(
    (total, week) => total + getOverShort(week),
    0,
  );

  return (
    <Root {...rest} title="Cig sales1">
      <Header>
        <Title>Cigarette Over/Short</Title>
        <StoreIdRow>
          <StoreIdLabel>Store id:</StoreIdLabel>
          <Box>{storeId}</Box>
        </StoreIdRow>
        <WeekRow>
          <WeekLabel>Week</WeekLabel>
          <div>
            {beginningDate && formatISODate(beginningDate)}
            &nbsp;-&nbsp;
            {endingDate && formatISODate(endingDate)}
          </div>
        </WeekRow>
      </Header>
      <Table>
        {weeks.map(week => {
          const expectedInventory = getExpectedInventory(week);
          return (
            <TableRow key={week.id}>
              <Column>
                <div>{formatISODate(week.date)}</div>
                <ColumnTitle>Beg. Inventory</ColumnTitle>
                <Box>{week.beginningInventory.toFixed(1)}</Box>
              </Column>
              <Column>
                <ColumnTitle>Cigarette Order</ColumnTitle>
                <Box>{week.cigaretteOrder.toFixed(1)}</Box>
              </Column>
              <Column>
                <ColumnTitle>Inner Company Transfers</ColumnTitle>
                <Box>{week.innerCompanyTransfers}</Box>
              </Column>
              <Column>
                <ColumnTitle>Packs</ColumnTitle>
                <Box>{week.pack.toFixed(1)}</Box>
              </Column>
              <Column>
                <ColumnTitle>Cartons</ColumnTitle>
                <Box>{week.carton.toFixed(1)}</Box>
              </Column>
              <Column>
                <ColumnTitle>
                  <span>
                    Cig Over
                    <br />
                    Ring
                  </span>
                </ColumnTitle>
                <Box>{week.overRings.toFixed(1)}</Box>
              </Column>
              <Column>
                <ColumnTitle>Expected Inventory</ColumnTitle>
                <Box>{expectedInventory.toFixed(1)}</Box>
              </Column>
              <Column>
                <ColumnTitle>Actual Inventory</ColumnTitle>
                <Box>{week.actualInventory.toFixed(1)}</Box>
              </Column>
              <Column>
                <ColumnTitle>Cig Over/Short</ColumnTitle>
                <Box>{week.actualInventory - expectedInventory}</Box>
              </Column>
            </TableRow>
          );
        })}
      </Table>
      <Footer>
        <TotalRowTitle>Grand Total</TotalRowTitle>
        <Column>
          <TotalRowTitle>Cigarette Order</TotalRowTitle>
          <Box>{cigaretteOrderTotal.toFixed(1)}</Box>
        </Column>
        <Column>
          <TotalRowTitle>Inner Company Transfers</TotalRowTitle>
          <Box>{innerCompanyTransferTotal.toFixed(1)}</Box>
        </Column>

        <Column>
          <TotalRowTitle>Cigarette Packs</TotalRowTitle>
          <Box>{packsTotal.toFixed(1)}</Box>
        </Column>

        <Column>
          <TotalRowTitle>Cigarette Cartons</TotalRowTitle>
          <Box>{cartonsTotal.toFixed(1)}</Box>
        </Column>

        <Column>
          <TotalRowTitle>Over-rings</TotalRowTitle>
          <Box>{overRingsTotal.toFixed(1)}</Box>
        </Column>

        <Row>
          <OverShortTotalRow>
            <OverShortTotalLabel>
              Total Over/Short for the week
            </OverShortTotalLabel>
            <Column>
              <OverShortText>Over/Shorts</OverShortText>
              <Box>{overShortTotal.toFixed(1)}</Box>
            </Column>
          </OverShortTotalRow>
        </Row>
      </Footer>
    </Root>
  );
};

function getOverShort(week: CigOverShortEntry): number {
  return week.actualInventory - getExpectedInventory(week);
}

function getExpectedInventory(week: CigOverShortEntry): number {
  return (
    week.beginningInventory +
    week.cigaretteOrder +
    week.innerCompanyTransfers +
    week.overRings -
    (week.pack + week.carton * 10)
  );
}

const Root = styled(FormBox)`
  overflow-x: hidden;
  @media print {
    width: auto;
    height: auto;
    transform-origin: 0 0;
    transform: scale(0.8, 0.8);
    overflow-x: visible;
  }
`;

const Box = styled(Row)`
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  padding: 0 5px 0 5px;
  background-color: white;
  color: black;
  font-style: normal;
  width: 100%;
  margin: auto;
  margin-bottom: 0;
  max-width: 110px;
  min-width: 55px;

  @media print {
    max-width: 80px;
  }
`;

const Title = styled('div')``;

const StoreIdLabel = styled('div')`
  margin-right: 10px;
`;

const StoreIdRow = styled(Row)`
  align-items: center;
  width: 180px;
`;

const WeekRow = styled(Column)`
  align-items: center;
  color: black;
  ${DateText} {
    font-style: normal;
    display: inline-block;
  }
`;

const WeekLabel = styled('div')`
  margin-bottom: 5px;
  + div {
    font-size: 11px;
  }
`;

const Header = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 75%;
  padding: 0 10px 0 10px;

  ${Title},
  ${StoreIdLabel},
  ${WeekLabel} {
    font-weight: bold;
  }

`;

const Table = styled(Column)``;

const TableRow = styled(Row)`
  border-bottom: 2px solid black;
  padding: 10px 0 6px;

  > ${Column} {
    justify-content: flex-end;
    margin: 0 10px 0 10px;
  }

  ${DateText} {
    font-size: 11px;
    margin-left: -4px;
    margin-top: -2px;
  }

  > ${Column} ${Box} {
    margin-top: 10px;
  }

  > ${Column} + ${Column} {
    margin-left: 10px;
  }
`;

const ColumnTitle = styled('div')`
  text-align: center;
  text-decoration: underline;
`;

const Footer = styled(Row)`
  margin-top: 20px;
  justify-content: space-evenly;
  align-items: flex-end;

  ${Box} {
    margin-top: 10px;
  }
`;

const TotalRowTitle = styled('div')`
  text-decoration: underline;
  font-weight: bold;
`;

const OverShortTotalRow = styled(Row)`
  align-items: flex-end;
`;

const OverShortTotalLabel = styled('div')`
  margin-right: 10px;
  min-width: 100px;
  font-weight: bold;
`;

const OverShortText = styled('div')`
  font-weight: bold;
`;

export default styled(CigOverShortForm)`
  background: #fff;
  width: 1000px;
  margin: auto;
  font-size: 14px;


  ${Header},
  ${Table},
  ${Footer} {
    font-style: italic;
  }

  @media print {
    width: 100%;
    max-width: 100%;
    overflow: visible;
  }
`;
