import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import Table from './Table';
import FormBox from './FormBox';
import ErrorLog from '../models/ErrorLog';
import formatDate from '../utils/formatDate';
import Row from './Row';
import formatISODate from '../utils/formatISODate';

export interface ErrorLogFormProps {
  autoPrint?: boolean;
  data: ErrorLog[];
}

const ErrorLogForm: React.FC<ErrorLogFormProps> = props => {
  const { data, autoPrint, ...next } = props;

  useEffect(() => {
    if (autoPrint) {
      window.scrollTo({ top: 0 });
      window.print();
    }
  }, [autoPrint]);

  return (
    <FormBox {...next} title="Error Log">
      <TableWrapper>
        <Table
          headers={[
            'Store_ID',
            'Shift_Date',
            'Shift',
            'Employee_ID',
            'Account',
            'Beginning_Shift_Amount',
            'Previous_Shift_Amount',
            'Error_Description',
            'Run_Date',
          ]}
        >
          {data.map((errorLog, i) => (
            <tr key={i}>
              <td>{errorLog.storeId}</td>
              <td>{formatISODate(errorLog.shiftDate)}</td>
              <td>{errorLog.shift}</td>
              <td>{errorLog.employeeId}</td>
              <td>{errorLog.accountId}</td>
              <td>{errorLog.beginningShiftAmount}</td>
              <td>{errorLog.previousShiftAmount}</td>
              <td>{errorLog.errorDescription}</td>
              <td>{errorLog.runDate && formatDate(errorLog.runDate)}</td>
            </tr>
          ))}
        </Table>
      </TableWrapper>
    </FormBox>
  );
};

export default styled(ErrorLogForm)`
  margin: auto;
  width: 1400px;
  max-width: 100%;

  @media print {
    margin: none;
    width: auto;
  }
`;

const TableWrapper = styled(Row)`
  width: 100%;
  overflow: auto;

  ${Table} {
    min-width: 2000px;
  }

  @media print {
    ${Table} {
      width: 100%;
      min-width: 0;
      max-width: 100%;
      font-size: 80%;
    }
  }
`;
