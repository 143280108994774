import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Form from './Form';
import Row from './Row';
import Input from './Input';
import BlockButton from './BlockButton';
import MoneyInput from './MoneyInput';
import FormBox from './FormBox';
import noop from '../utils/noop';

export interface Value {
  carton10Id: number | null;
  carton10Name: string | null;
  carton10Price: number | string | null;
  carton11Id: number | null;
  carton11Name: string | null;
  carton11Price: number | string | null;
  carton12Id: number | null;
  carton12Name: string | null;
  carton12Price: number | string | null;
  carton13Id: number | null;
  carton13Name: string | null;
  carton13Price: number | string | null;
  carton14Id: number | null;
  carton14Name: string | null;
  carton14Price: number | string | null;
  carton15Id: number | null;
  carton15Name: string | null;
  carton15Price: number | string | null;
  carton16Id: number | null;
  carton16Name: string | null;
  carton16Price: number | string | null;
  carton17Id: number | null;
  carton17Name: string | null;
  carton17Price: number | string | null;
  carton18Id: number | null;
  carton18Name: string | null;
  carton18Price: number | string | null;
  carton19Id: number | null;
  carton19Name: string | null;
  carton19Price: number | string | null;
  carton1Id: number | null;
  carton1Name: string | null;
  carton1Price: number | string | null;
  carton20Id: number | null;
  carton20Name: string | null;
  carton20Price: number | string | null;
  carton2Id: number | null;
  carton2Name: string | null;
  carton2Price: number | string | null;
  carton3Id: number | null;
  carton3Name: string | null;
  carton3Price: number | string | null;
  carton4Id: number | null;
  carton4Name: string | null;
  carton4Price: number | string | null;
  carton5Id: number | null;
  carton5Name: string | null;
  carton5Price: number | string | null;
  carton6Id: number | null;
  carton6Name: string | null;
  carton6Price: number | string | null;
  carton7Id: number | null;
  carton7Name: string | null;
  carton7Price: number | string | null;
  carton8Id: number | null;
  carton8Name: string | null;
  carton8Price: number | string | null;
  carton9Id: number | null;
  carton9Name: string | null;
  carton9Price: number | string | null;
  pAck10Name: string | null;
  pAck13Name: string | null;
  pAck14Name: string | null;
  pAck6Id: number | null;
  pAck7Id: number | null;
  pAck9Name: string | null;
  pack10Id: number | null;
  pack10Price: number | string | null;
  pack11Id: number | null;
  pack11Name: string | null;
  pack11Price: number | string | null;
  pack12Id: number | null;
  pack12Name: string | null;
  pack12Price: number | string | null;
  pack13Id: number | null;
  pack13Price: number | string | null;
  pack14Id: number | null;
  pack14Price: number | string | null;
  pack15Id: number | null;
  pack15Name: string | null;
  pack15Price: number | string | null;
  pack16Id: number | null;
  pack16Name: string | null;
  pack16Price: number | string | null;
  pack17Id: number | null;
  pack17Name: string | null;
  pack17Price: number | string | null;
  pack18Id: number | null;
  pack18Name: string | null;
  pack18Price: number | string | null;
  pack19Id: number | null;
  pack19Name: string | null;
  pack19Price: number | string | null;
  pack1Id: number | null;
  pack1Price: number | string | null;
  pack20Id: number | null;
  pack20Name: string | null;
  pack20Price: number | string | null;
  pack2Id: number | null;
  pack2Name: string | null;
  pack2Price: number | string | null;
  pack3Id: number | null;
  pack3Name: string | null;
  pack3Price: number | string | null;
  pack4Id: number | null;
  pack4Name: string | null;
  pack4Price: number | string | null;
  pack5Id: number | null;
  pack5Name: string | null;
  pack5Price: number | string | null;
  pack6Name: string | null;
  pack6Price: number | string | null;
  pack7Name: string | null;
  pack7Price: number | string | null;
  pack8Id: number | null;
  pack8Name: string | null;
  pack8Price: number | string | null;
  pack9Id: number | null;
  pack9Price: number | string | null;
  patck1Name: string | null;
  storeId: number;
}

export interface Props {
  value: Value;
  onChange?: (value: Value) => any;
  onSubmit?: (value: Value) => any;
}

const CigPriceSetupForm: React.FC<Props> = props => {
  const { value, onChange = noop, onSubmit = noop, ...rest } = props;

  function handleSubmit() {
    onSubmit(value);
  }

  return (
    <FormBox {...rest} title="CigPriceSetup" onCloseClick={handleSubmit}>
      <StoreIdLabel>
        StoreID
        <Input value={value.storeId} />
      </StoreIdLabel>
      <Form onSubmit={handleSubmit} data-testid="form">
        <ProductColumn>
          <ColumnHeader>Packs</ColumnHeader>
          <Row>
            <Input value={value.patck1Name || ''} />
            <Input
              isMoney
              value={value.pack1Price || 0}
              onValueChange={pack1Price => onChange({ ...value, pack1Price })}
              data-testid="packPriceInput1"
            />
          </Row>
          <Row>
            <Input value={value.pack2Name || ''} />
            <Input
              isMoney
              value={value.pack2Price || 0}
              onValueChange={pack2Price => onChange({ ...value, pack2Price })}
              data-testid="packPriceInput2"
            />
          </Row>
          <Row>
            <Input value={value.pack3Name || ''} />
            <Input
              isMoney
              value={value.pack3Price || 0}
              onValueChange={pack3Price => onChange({ ...value, pack3Price })}
              data-testid="packPriceInput3"
            />
          </Row>
          <Row>
            <Input value={value.pack4Name || ''} />
            <Input
              isMoney
              value={value.pack4Price || 0}
              onValueChange={pack4Price => onChange({ ...value, pack4Price })}
              data-testid="packPriceInput4"
            />
          </Row>
          <Row>
            <Input value={value.pack5Name || ''} />
            <Input
              isMoney
              value={value.pack5Price || 0}
              onValueChange={pack5Price => onChange({ ...value, pack5Price })}
              data-testid="packPriceInput5"
            />
          </Row>
          <Row>
            <Input value={value.pack6Name || ''} />
            <Input
              isMoney
              value={value.pack6Price || 0}
              onValueChange={pack6Price => onChange({ ...value, pack6Price })}
              data-testid="packPriceInput6"
            />
          </Row>
          <Row>
            <Input value={value.pack7Name || ''} />
            <Input
              isMoney
              value={value.pack7Price || 0}
              onValueChange={pack7Price => onChange({ ...value, pack7Price })}
              data-testid="packPriceInput7"
            />
          </Row>
          <Row>
            <Input value={value.pack8Name || ''} />
            <Input
              isMoney
              value={value.pack8Price || 0}
              onValueChange={pack8Price => onChange({ ...value, pack8Price })}
              data-testid="packPriceInput8"
            />
          </Row>
          <Row>
            <Input value={value.pAck9Name || ''} />
            <Input
              isMoney
              value={value.pack9Price || 0}
              onValueChange={pack9Price => onChange({ ...value, pack9Price })}
              data-testid="packPriceInput9"
            />
          </Row>
          <Row>
            <Input value={value.pAck10Name || ''} />
            <Input
              isMoney
              value={value.pack10Price || 0}
              onValueChange={pack10Price => onChange({ ...value, pack10Price })}
              data-testid="packPriceInput10"
            />
          </Row>
          <Row>
            <Input value={value.pack11Name || ''} />
            <Input
              isMoney
              value={value.pack11Price || 0}
              onValueChange={pack11Price => onChange({ ...value, pack11Price })}
              data-testid="packPriceInput11"
            />
          </Row>
          <Row>
            <Input value={value.pack12Name || ''} />
            <Input
              isMoney
              value={value.pack12Price || 0}
              onValueChange={pack12Price => onChange({ ...value, pack12Price })}
              data-testid="packPriceInput12"
            />
          </Row>
          <Row>
            <Input value={value.pAck13Name || ''} />
            <Input
              isMoney
              value={value.pack13Price || 0}
              onValueChange={pack13Price => onChange({ ...value, pack13Price })}
              data-testid="packPriceInput13"
            />
          </Row>
          <Row>
            <Input value={value.pAck14Name || ''} />
            <Input
              isMoney
              value={value.pack14Price || 0}
              onValueChange={pack14Price => onChange({ ...value, pack14Price })}
              data-testid="packPriceInput14"
            />
          </Row>
          <Row>
            <Input value={value.pack15Name || ''} />
            <Input
              isMoney
              value={value.pack15Price || 0}
              onValueChange={pack15Price => onChange({ ...value, pack15Price })}
              data-testid="packPriceInput15"
            />
          </Row>
          <Row>
            <Input value={value.pack16Name || ''} />
            <Input
              isMoney
              value={value.pack16Price || 0}
              onValueChange={pack16Price => onChange({ ...value, pack16Price })}
              data-testid="packPriceInput16"
            />
          </Row>
          <Row>
            <Input value={value.pack17Name || ''} />
            <Input
              isMoney
              value={value.pack17Price || 0}
              onValueChange={pack17Price => onChange({ ...value, pack17Price })}
              data-testid="packPriceInput17"
            />
          </Row>
          <Row>
            <Input value={value.pack18Name || ''} />
            <Input
              isMoney
              value={value.pack18Price || 0}
              onValueChange={pack18Price => onChange({ ...value, pack18Price })}
              data-testid="packPriceInput18"
            />
          </Row>
          <Row>
            <Input value={value.pack19Name || ''} />
            <Input
              isMoney
              value={value.pack19Price || 0}
              onValueChange={pack19Price => onChange({ ...value, pack19Price })}
              data-testid="packPriceInput19"
            />
          </Row>
          <Row>
            <Input value={value.pack20Name || ''} />
            <Input
              isMoney
              value={value.pack20Price || 0}
              onValueChange={pack20Price => onChange({ ...value, pack20Price })}
              data-testid="packPriceInput20"
            />
          </Row>
        </ProductColumn>
        <ProductColumn>
          <ColumnHeader>Cartons</ColumnHeader>
          <Row>
            <Input value={value.carton1Name || ''} />
            <Input
              isMoney
              value={value.carton1Price || 0}
              onValueChange={carton1Price =>
                onChange({ ...value, carton1Price })
              }
              data-testid="cartonPriceInput1"
            />
          </Row>
          <Row>
            <Input value={value.carton2Name || ''} />
            <Input
              isMoney
              value={value.carton2Price || 0}
              onValueChange={carton2Price =>
                onChange({ ...value, carton2Price })
              }
              data-testid="cartonPriceInput2"
            />
          </Row>
          <Row>
            <Input value={value.carton3Name || ''} />
            <Input
              isMoney
              value={value.carton3Price || 0}
              onValueChange={carton3Price =>
                onChange({ ...value, carton3Price })
              }
              data-testid="cartonPriceInput3"
            />
          </Row>
          <Row>
            <Input value={value.carton4Name || ''} />
            <Input
              isMoney
              value={value.carton4Price || 0}
              onValueChange={carton4Price =>
                onChange({ ...value, carton4Price })
              }
              data-testid="cartonPriceInput4"
            />
          </Row>
          <Row>
            <Input value={value.carton5Name || ''} />
            <Input
              isMoney
              value={value.carton5Price || 0}
              onValueChange={carton5Price =>
                onChange({ ...value, carton5Price })
              }
              data-testid="cartonPriceInput5"
            />
          </Row>
          <Row>
            <Input value={value.carton6Name || ''} />
            <Input
              isMoney
              value={value.carton6Price || 0}
              onValueChange={carton6Price =>
                onChange({ ...value, carton6Price })
              }
              data-testid="cartonPriceInput6"
            />
          </Row>
          <Row>
            <Input value={value.carton7Name || ''} />
            <Input
              isMoney
              value={value.carton7Price || 0}
              onValueChange={carton7Price =>
                onChange({ ...value, carton7Price })
              }
              data-testid="cartonPriceInput7"
            />
          </Row>
          <Row>
            <Input value={value.carton8Name || ''} />
            <Input
              isMoney
              value={value.carton8Price || 0}
              onValueChange={carton8Price =>
                onChange({ ...value, carton8Price })
              }
              data-testid="cartonPriceInput8"
            />
          </Row>
          <Row>
            <Input value={value.carton9Name || ''} />
            <Input
              isMoney
              value={value.carton9Price || 0}
              onValueChange={carton9Price =>
                onChange({ ...value, carton9Price })
              }
              data-testid="cartonPriceInput9"
            />
          </Row>
          <Row>
            <Input value={value.carton10Name || ''} />
            <Input
              isMoney
              value={value.carton10Price || 0}
              onValueChange={carton10Price =>
                onChange({ ...value, carton10Price })
              }
              data-testid="cartonPriceInput10"
            />
          </Row>
          <Row>
            <Input value={value.carton11Name || ''} />
            <Input
              isMoney
              value={value.carton11Price || 0}
              onValueChange={carton11Price =>
                onChange({ ...value, carton11Price })
              }
              data-testid="cartonPriceInput11"
            />
          </Row>
          <Row>
            <Input value={value.carton12Name || ''} />
            <Input
              isMoney
              value={value.carton12Price || 0}
              onValueChange={carton12Price =>
                onChange({ ...value, carton12Price })
              }
              data-testid="cartonPriceInput12"
            />
          </Row>
          <Row>
            <Input value={value.carton13Name || ''} />
            <Input
              isMoney
              value={value.carton13Price || 0}
              onValueChange={carton13Price =>
                onChange({ ...value, carton13Price })
              }
              data-testid="cartonPriceInput13"
            />
          </Row>
          <Row>
            <Input value={value.carton14Name || ''} />
            <Input
              isMoney
              value={value.carton14Price || 0}
              onValueChange={carton14Price =>
                onChange({ ...value, carton14Price })
              }
              data-testid="cartonPriceInput14"
            />
          </Row>
          <Row>
            <Input value={value.carton15Name || ''} />
            <Input
              isMoney
              value={value.carton15Price || 0}
              onValueChange={carton15Price =>
                onChange({ ...value, carton15Price })
              }
              data-testid="cartonPriceInput15"
            />
          </Row>
          <Row>
            <Input value={value.carton16Name || ''} />
            <Input
              isMoney
              value={value.carton16Price || 0}
              onValueChange={carton16Price =>
                onChange({ ...value, carton16Price })
              }
              data-testid="cartonPriceInput16"
            />
          </Row>
          <Row>
            <Input value={value.carton17Name || ''} />
            <Input
              isMoney
              value={value.carton17Price || 0}
              onValueChange={carton17Price =>
                onChange({ ...value, carton17Price })
              }
              data-testid="cartonPriceInput17"
            />
          </Row>
          <Row>
            <Input value={value.carton18Name || ''} />
            <Input
              isMoney
              value={value.carton18Price || 0}
              onValueChange={carton18Price =>
                onChange({ ...value, carton18Price })
              }
              data-testid="cartonPriceInput18"
            />
          </Row>
          <Row>
            <Input value={value.carton19Name || ''} />
            <Input
              isMoney
              value={value.carton19Price || 0}
              onValueChange={carton19Price =>
                onChange({ ...value, carton19Price })
              }
              data-testid="cartonPriceInput19"
            />
          </Row>
          <Row>
            <Input value={value.carton20Name || ''} />
            <Input
              isMoney
              value={value.carton20Price || 0}
              onValueChange={carton20Price =>
                onChange({ ...value, carton20Price })
              }
              data-testid="cartonPriceInput20"
            />
          </Row>
        </ProductColumn>
      </Form>
      <Footer>
        <BlockButton onClick={handleSubmit}>Done</BlockButton>
      </Footer>
    </FormBox>
  );
};

export default styled(CigPriceSetupForm)`
  width: 750px;
  margin: auto;
  > ${Form} {
    flex-direction: row;
  }

  ${Input}, input {
    height: 25px;
    width: 100px;
  }
`;

const ProductColumn = styled(Column)`
  flex: 1;

  & + & {
    margin-left: 20px;
  }

  ${Input} {
    flex: 1;
    text-align: left;
    padding-right: 5px;
  }

  ${MoneyInput} {
    width: 100px;
    margin-left: 10px;
    > span {
      padding-left: 20px;
      width: 33px;
      color: #000;
    }
    > input {
      max-width: 65px;
      padding-right: 4px;
      text-align: left;
    }
  }

  > ${Row} + ${Row} {
    margin-top: 10px;
  }
`;

const ColumnHeader = styled('div')`
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`;

const StoreIdLabel = styled('label')`
  margin-bottom: 20px;
  margin-left: 50px;
  ${Input} {
    margin-left: 10px;
    width: 110px;
  }
`;

const Footer = styled(Row)`
  margin-top: 20px;
  justify-content: flex-end;
`;
