import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components/macro';
import Row from './Row';

const InputRow: React.FC<ComponentPropsWithoutRef<'div'>> = props => {
  return <Row {...props} />;
};

export default styled(InputRow)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;
