import React, { useState } from 'react';
import { FaPrint } from 'react-icons/fa';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import MoneyInput from './MoneyInput';
import NumberInput from './NumberInput';
import Input from './Input';
import InputRow from './InputRow';
import BlockButton from './BlockButton';
import TextArea from './TextArea';
import Checkbox from './Checkbox';
import { navigate } from '@reach/router';
import FormBox from './FormBox';
import { ShiftMasterDraft, shiftMasterFromDraft } from '../models/ShiftMaster';
import useAsyncEffect from '../utils/useAsyncEffect';
import useSearchParams from '../utils/useSearchParams';
import Api from '../api/Api';
import Modal from './Modal';
import CashRegisterReportForm from './CashRegisterReportForm';
import CigSalesForm from './CigSalesForm';
import LotteryReportsForm from './LotteryReportsForm';
import GeneralShiftEntriesForm from './GeneralShiftEntriesForm';
import ScratchTicket2Form from './ScratchTicket2Form';
import DailyCheckRegisterForm from './DailyCheckRegisterForm';
import CheckRegisterForm from './CheckRegisterForm';
import W2GForm from './W2gForm';
import Employees from '../models/User';
import formatISODate from '../utils/formatISODate';
import MoneyText from './MoneyText';
import usePrintLayout from '../utils/usePrintLayout';
import canEditShift from '../utils/canEditShift';
import useSinglePagePrint from '../utils/useSinglePagePrint';
import useIdleTimeout from '../utils/useIdleTimeout';
import isSuperAdmin from '../utils/isSuperAdmin';

enum ModalType {
  GeneralShiftEntriesModal,
  CigSalesModal,
  CashRegisterReportModal,
  LotteryReportModal,
  ScratchTicketModal,
  DailyCheckRegisterModal,
  CheckRegisterModal,
  W2GModal,
}

export interface ShiftFormProps {
  shiftDate: string;
  shiftNumber: number;
  autoShowAuditShift?: boolean;
  onDelete?(): unknown;
}

const ShiftForm: React.FC<ShiftFormProps> = (props: ShiftFormProps) => {
  useIdleTimeout(() => close(false));
  useSinglePagePrint();
  usePrintLayout('portrait');
  const { onDelete } = props;
  const [openModals, setOpenModals] = useState<ModalType[]>([]);
  const [user, setUser] = useState<Employees | null>(null);
  const { shiftDate, shiftNumber, ...rest } = props;
  const [value, setValue] = useState<ShiftMasterDraft | null>(null);
  const [readOnly, setReadOnly] = useState(true);
  const [enjoySales, setEnjoySales] = useState(0);
  const { backRoute } = useSearchParams('backRoute');
  const hideDuringPrint = openModals.length > 0;

  useAsyncEffect(load, [shiftDate, shiftNumber]);

  if (!value) {
    // Loading...
    return null;
  }

  async function load() {
    const fetchedUser = await Api.getUser();
    setUser(fetchedUser);
    const fetchedShiftMaster = await Api.getShift(shiftDate, shiftNumber);
    if (!fetchedShiftMaster) {
      window.alert('Failed to find shift master.');
      window.history.back();
      return;
    }
    setValue(fetchedShiftMaster);
    if (fetchedUser) {
      setReadOnly(
        !(await canEditShift(fetchedUser, {
          shiftDate: fetchedShiftMaster.shiftDate,
          shiftNumber: fetchedShiftMaster.shift,
          storeId: fetchedShiftMaster.storeId,
        })),
      );
    }
  }

  async function save(shiftMasterDraft: ShiftMasterDraft, enjoy = enjoySales) {
    if (readOnly) {
      return;
    }
    const shiftMaster = shiftMasterFromDraft(shiftMasterDraft);
    const beginningCash =
      (shiftMaster.beginningTill || 0) +
      (shiftMaster.beginningChange || 0) +
      (shiftMaster.beginningPokerBank || 0);

    const endingCash =
      (shiftMaster.endingTill || 0) +
      (shiftMaster.endingChange || 0) +
      (shiftMaster.endingPokerBank || 0);

    const totalIn =
      (shiftMaster.food || 0) +
      (shiftMaster.nonAlcohol || 0) +
      (shiftMaster.alcoholBeverages || 0) +
      (shiftMaster.tobaccoPack || 0) +
      (shiftMaster.tobaccoCarton || 0) +
      (shiftMaster.totalVpIn || 0) +
      (shiftMaster.jamBillsIn || 0) +
      (shiftMaster.cashToSafe || 0) +
      (shiftMaster.salesTax || 0) +
      (shiftMaster.regularShiftTubeRefill || 0) +
      (shiftMaster.scratchTickets || 0) +
      (shiftMaster.onLineSales || 0) +
      (shiftMaster.winnerChecks || 0) +
      (shiftMaster.cigaretteDiscountOverring || 0) +
      (shiftMaster.other || 0) +
      (shiftMaster.promotion || 0) +
      (shiftMaster.vlmcashIn || 0) +
      (shiftMaster.w2gTaxes || 0) +
      enjoy;

    const increaseBanks = endingCash - beginningCash;

    const totalOut =
      (shiftMaster.employeeMeals || 0) +
      (shiftMaster.vpValidation || 0) +
      (shiftMaster.onLinePrizes || 0) +
      (shiftMaster.scratchOffValidation || 0) +
      (shiftMaster.pullTabPrizes || 0) +
      (shiftMaster.cigaretteDiscount || 0) +
      (shiftMaster.espressoDiscount || 0) +
      (shiftMaster.foodDiscount || 0) +
      (shiftMaster.lotteryCoupons || 0) +
      (shiftMaster.payouts || 0) +
      (shiftMaster.overrings || 0) +
      (increaseBanks || 0) +
      (shiftMaster.nonPrizeChecks || 0) +
      (shiftMaster.cashDrop || 0) +
      (shiftMaster.jamBillsOut || 0) +
      (shiftMaster.cigaretteOverrings || 0) +
      (shiftMaster.midShiftPull || 0) +
      shiftMaster.discounts +
      shiftMaster.cancel;

    const fundsNeeded = totalIn - totalOut;
    const totalDeposit =
      (shiftMaster.cashDeposit || 0) +
      (shiftMaster.creditCardSlips || 0) +
      (shiftMaster.cashDepositB || 0) +
      (shiftMaster.cashDepositC || 0) +
      (shiftMaster.cashDepositD || 0) +
      (shiftMaster.atmRefill || 0);

    const discrepancyOverShort =
      shiftMaster.discrepencyOver - shiftMaster.discrepencyShort;
    const overShort = totalDeposit - fundsNeeded + discrepancyOverShort;

    await Api.addOrUpdateShiftMaster({
      ...shiftMaster,
      endingCash,
      totalIn,
      increaseBanks,
      totalOut,
      fundsNeeded,
      totalDeposit,
      overShort,
    });
    await load();
  }

  function updateValue<K extends keyof ShiftMasterDraft>(
    key: K,
    newValue: ShiftMasterDraft[K],
  ) {
    if (!value) {
      return;
    }
    setValue({
      ...value,
      [key]: newValue,
    });
  }

  function isModalOpen(modalType: ModalType): boolean {
    return openModals.includes(modalType);
  }

  function closeModal(modalType: ModalType): void {
    setOpenModals(openModals.filter(otherType => otherType !== modalType));
  }

  function openModal(modalType: ModalType): void {
    if (!isModalOpen(modalType)) {
      setOpenModals([...openModals, modalType]);
    }
  }

  function handleScratchOffClicked() {
    openModal(ModalType.ScratchTicketModal);
  }

  function handleGeneralEntriesClicked() {
    openModal(ModalType.GeneralShiftEntriesModal);
  }

  function handleEnterLotteryReportsClicked() {
    openModal(ModalType.LotteryReportModal);
  }

  function handleCashRegisterReportClicked() {
    openModal(ModalType.CashRegisterReportModal);
  }

  function handleEnterChecksCalculationClicked() {
    openModal(ModalType.DailyCheckRegisterModal);
  }

  function handleCheckRegisterClicked() {
    openModal(ModalType.CheckRegisterModal);
  }

  function handleW2GClicked() {
    openModal(ModalType.W2GModal);
  }

  function handleCalculateDepositClick() {
    if (value) {
      save(value);
    }
  }

  function close(shouldSave: boolean) {
    if (shouldSave && value) {
      save(value);
    }
    navigate(backRoute || '/');
  }

  return (
    <>
      {isModalOpen(ModalType.GeneralShiftEntriesModal) && (
        <Modal>
          <GeneralShiftEntriesForm
            value={value}
            readOnly={readOnly}
            onSubmit={newShiftMaster => {
              save(newShiftMaster);
              closeModal(ModalType.GeneralShiftEntriesModal);
            }}
            onCancel={() => closeModal(ModalType.GeneralShiftEntriesModal)}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.CashRegisterReportModal) && (
        <Modal noScroll>
          <CashRegisterReportForm
            readOnly={readOnly}
            value={{ ...value, enjoySales }}
            onSubmit={v => {
              const { enjoySales: newEnjoySales, ...shiftMaster } = v;
              setEnjoySales(newEnjoySales);
              save(shiftMaster, newEnjoySales);
              closeModal(ModalType.CashRegisterReportModal);
            }}
            onCancel={() => closeModal(ModalType.CashRegisterReportModal)}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.CigSalesModal) && (
        <Modal noScroll>
          <CigSalesForm
            storeId={+value.storeId}
            shiftDate={shiftDate}
            shiftNumber={shiftNumber}
            readOnly={readOnly}
            onSubmit={async result => {
              save({
                ...value,
                tobaccoPack: result.tobaccoPack,
                tobaccoCarton: result.tobaccoCarton,
                ppack: result.sumOfPacks,
                cigextra1: result.sumOfCartons,
              });
              closeModal(ModalType.CigSalesModal);
            }}
            onCancel={() => closeModal(ModalType.CigSalesModal)}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.LotteryReportModal) && (
        <Modal noScroll>
          <LotteryReportsForm
            storeId={+value.storeId}
            shiftDate={shiftDate}
            shiftNumber={shiftNumber}
            shiftMaster={value}
            readOnly={readOnly}
            onSubmit={async (itsReport, shiftMaster) => {
              if (!readOnly) {
                await Api.addOrUpdateLotteryReportData(itsReport);
                await save(shiftMaster);
              }
              closeModal(ModalType.LotteryReportModal);
            }}
            onCancel={() => closeModal(ModalType.LotteryReportModal)}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.ScratchTicketModal) && (
        <Modal noScroll>
          <ScratchTicket2Form
            shiftDate={shiftDate}
            shiftNumber={shiftNumber}
            readOnly={readOnly}
            onSubmit={async shiftScratchTickets => {
              if (!readOnly) {
                await Api.updateShiftScratchTicketDetails(
                  shiftDate,
                  shiftNumber,
                  shiftScratchTickets,
                );
                await save({
                  ...value,
                  scratchTickets: shiftScratchTickets.reduce(
                    (total, ticket) => {
                      return (
                        total + (ticket.value || 0) * (ticket.amountSold || 0)
                      );
                    },
                    0,
                  ),
                });
              }
              closeModal(ModalType.ScratchTicketModal);
            }}
            onCancel={() => closeModal(ModalType.ScratchTicketModal)}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.DailyCheckRegisterModal) && (
        <Modal noScroll>
          <DailyCheckRegisterForm
            storeId={value.storeId}
            shiftNumber={+value.shift}
            shiftDate={value.shiftDate}
            employeeId={value.employee || ''}
            readOnly={readOnly}
            onSubmit={async checkRegister => {
              if (!readOnly) {
                await Api.saveCheckRegister(checkRegister);
                await save({
                  ...value,
                  beginningCheckCount: checkRegister.beginningCheckCount,
                  endingCheckCount: checkRegister.endingCheckCount,
                });
              }
              closeModal(ModalType.DailyCheckRegisterModal);
            }}
            onCancel={() => closeModal(ModalType.DailyCheckRegisterModal)}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.CheckRegisterModal) && (
        <Modal noScroll>
          <CheckRegisterForm
            storeId={value.storeId}
            shiftNumber={+value.shift}
            shiftDate={value.shiftDate}
            readOnly={readOnly}
            onSubmit={async ({ nonPrizeTotal, winnerTotal }) => {
              await save({
                ...value,
                nonPrizeChecks: nonPrizeTotal,
                winnerChecks: winnerTotal,
              });
              closeModal(ModalType.CheckRegisterModal);
            }}
            onCancel={() => closeModal(ModalType.CheckRegisterModal)}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.W2GModal) && (
        <Modal noScroll>
          <W2GForm
            storeId={value.storeId}
            shiftDate={value.shiftDate}
            shiftNumber={+value.shift}
            readOnly={readOnly}
            employeeId={value.employee || ''}
            employeeName={
              user
                ? `${user.firstName || ''}${
                    user.lastName ? ` ${user.lastName}` : ''
                  }`
                : ''
            }
            onSubmit={async w2gTaxes => {
              await save({
                ...value,
                w2gTaxes,
              });
              closeModal(ModalType.W2GModal);
            }}
            onCancel={() => closeModal(ModalType.W2GModal)}
          />
        </Modal>
      )}
      <FormBox
        {...rest}
        title="Shift"
        tabIndex={-1}
        hideDuringPrinting={hideDuringPrint}
        onCloseClick={() => close(true)}
      >
        <ShiftInfoHeader>
          <Column>
            <div>Date</div>
            <ShiftInfoData>{formatISODate(value.shiftDate)}</ShiftInfoData>
          </Column>
          <Column>
            <div>Shift</div>
            <ShiftInfoData>{value.shift}</ShiftInfoData>
          </Column>
          <Column>
            <div>Store ID</div>
            <ShiftInfoData>{value.storeId}</ShiftInfoData>
          </Column>
          <Column>
            <div>First Name</div>
            <ShiftInfoData>{value.firstName}</ShiftInfoData>
          </Column>
          <Column>
            <div>Last Name</div>
            <ShiftInfoData>{value.lastName}</ShiftInfoData>
          </Column>
        </ShiftInfoHeader>
        <TopColumns>
          <TopColumn>
            <AccountHeader>
              <b>ACCOUNT</b>
            </AccountHeader>
            <InputRow>
              <EntryLabel>On-Line Sales</EntryLabel>
              <MoneyText amount={value.onLineSales || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Food</EntryLabel>
              <MoneyText amount={value.food || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Non-Alcohol</EntryLabel>
              <MoneyText amount={value.nonAlcohol || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Alcohol Bev.</EntryLabel>
              <MoneyText amount={value.alcoholBeverages || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Miscellaneous</EntryLabel>
              <MoneyText amount={value.cigextra5} />
            </InputRow>
            <InputRow>
              <EntryLabel>Other Cash In:</EntryLabel>
              <MoneyText amount={value.other || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Sales Tax</EntryLabel>
              <MoneyText amount={value.salesTax || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Scratch-Off Tickets</EntryLabel>
              <MoneyText amount={value.scratchTickets || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Winners Checks</EntryLabel>
              <MoneyText amount={value.winnerChecks || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>VLM Cash In</EntryLabel>
              <MoneyText amount={value.vlmcashIn || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>W2-g Taxes</EntryLabel>
              <MoneyText amount={value.w2gTaxes} />
            </InputRow>

            <TotalRow>
              <label>
                <b>Total In</b>
              </label>
              <MoneyText amount={value.totalIn || 0} />
            </TotalRow>
          </TopColumn>
          <TopColumn>
            <VpIn>
              <b>Video Lottery Sales</b>
              <MoneyText amount={value.vpsales || 0} />
            </VpIn>
            <TopColumnButton
              onClick={handleEnterLotteryReportsClicked}
              tabIndex={-1}
            >
              Enter Lottery Reports
            </TopColumnButton>
            <TopColumnButton
              onClick={handleCashRegisterReportClicked}
              tabIndex={-1}
            >
              Enter Cash Register Report
            </TopColumnButton>
            <TopColumnButton
              onClick={handleGeneralEntriesClicked}
              tabIndex={-1}
            >
              Enter General Entries
            </TopColumnButton>
            <TopColumnButton onClick={handleScratchOffClicked} tabIndex={-1}>
              Enter Scratch Off
            </TopColumnButton>
            <TopColumnButton
              onClick={handleEnterChecksCalculationClicked}
              tabIndex={-1}
            >
              Enter Checks Calculation
            </TopColumnButton>
            <TopColumnButton onClick={handleCheckRegisterClicked} tabIndex={-1}>
              Check Register
            </TopColumnButton>
            <TopColumnButton onClick={handleW2GClicked} tabIndex={-1}>
              W-2G
            </TopColumnButton>
            <TopColumnButton
              tabIndex={-1}
              onClick={handleCalculateDepositClick}
              style={{ height: 'auto' }}
            >
              Calculate Deposit and Over/Short
            </TopColumnButton>
            <TotalRow>
              <label>
                <b>Over/Short</b>
              </label>
              <MoneyText amount={value.overShort || 0} />
            </TotalRow>
          </TopColumn>
          <TopColumn>
            <AccountHeader>
              <b>ACCOUNT</b>
            </AccountHeader>
            <InputRow>
              <EntryLabel>S-Off Validation</EntryLabel>
              <MoneyText amount={value.scratchOffValidation || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>On-Line Cancel</EntryLabel>
              <MoneyText amount={value.cancel || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>On-Line Cashes</EntryLabel>
              <MoneyText amount={value.onLinePrizes || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>On-Line Discount</EntryLabel>
              <MoneyText amount={value.discounts || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Validated</EntryLabel>
              <MoneyText amount={value.vpValidation || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Cig. Discount</EntryLabel>
              <MoneyText amount={value.cigaretteDiscount || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Food Discount</EntryLabel>
              <MoneyText amount={value.foodDiscount || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Employee Meals</EntryLabel>
              <MoneyText amount={value.employeeMeals || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Overrings</EntryLabel>
              <MoneyText amount={value.overrings || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Lottery Coupons</EntryLabel>
              <MoneyText amount={value.lotteryCoupons || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Cig Overring</EntryLabel>
              <MoneyText amount={value.cigaretteOverrings || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Cash Drop</EntryLabel>
              <MoneyText amount={value.cashDrop || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Promotion</EntryLabel>
              <MoneyText amount={value.promotion || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Jam Bills Out</EntryLabel>
              <MoneyText amount={value.jamBillsOut || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Payout</EntryLabel>
              <MoneyText amount={value.payouts || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Non-Prize Checks</EntryLabel>
              <MoneyText amount={value.nonPrizeChecks || 0} />
            </InputRow>
            <TotalRow>
              <label>
                <b>Total Out</b>
              </label>
              <MoneyText amount={value.totalOut || 0} />
            </TotalRow>
          </TopColumn>
          <TopColumn>
            <TextAreaColumn>
              <label>Other Cash in Comments</label>
              <TextArea
                readOnly
                value={value.othercomments || ''}
                tabIndex={-1}
              />
            </TextAreaColumn>
            <TextAreaColumn>
              <label>Jam Bill Comments</label>
              <TextArea
                readOnly
                value={value.jamBillsComments || ''}
                tabIndex={-1}
              />
            </TextAreaColumn>
            <TextAreaColumn>
              <label>Payout Comments</label>
              <TextArea
                readOnly
                value={value.payoutsComments || ''}
                tabIndex={-1}
              />
            </TextAreaColumn>
          </TopColumn>
        </TopColumns>
        <SectionDivider />
        <TillSection>
          <TillRow>
            <label>Beginning Till:</label>
            <MoneyText amount={value.beginningTill || 0} />
            <label>Beginning Change:</label>
            <MoneyText amount={value.beginningChange || 0} />
          </TillRow>
          <TillRow>
            <label>Ending Till:</label>
            <MoneyText amount={value.endingTill || 0} />
            <label>Ending Change:</label>
            <MoneyText amount={value.endingChange || 0} />
          </TillRow>
        </TillSection>
        <SafeSection>
          <h2>Safe</h2>
          <SafeRow>
            <InputRow>
              <label>Cash Dispensed:</label>
              <MoneyText amount={+value.cashDispensed || 0} />
            </InputRow>
            <InputRow>
              <label>Column Total:</label>
              <MoneyText amount={value.columnTotal || 0} />
            </InputRow>
            <InputRow>
              <label>Cassette + Manual Drop Deposit:</label>
              <MoneyText amount={value.cassetteDeposit || 0} />
            </InputRow>
          </SafeRow>
          <SafeRow style={{ marginTop: 8, justifyContent: 'flex-start' }}>
            <InputRow style={{ maxWidth: 300 }}>
              <label>Tube Discrepency (Over)</label>
              <MoneyInput
                readOnly
                disabled
                value={value.discrepencyOver || 0}
                backgroundColor={
                  value.discrepencyOver && +value.discrepencyOver !== 0
                    ? 'rgb(255, 229, 0)'
                    : undefined
                }
                style={{ maxWidth: 100 }}
              />
            </InputRow>
            <InputRow style={{ maxWidth: 600, justifyContent: 'flex-start' }}>
              <label style={{ flex: '0 0 auto', marginRight: 8 }}>
                Tube Discrepency Comments:
              </label>
              <Input readOnly disabled value={value.discrepencyComments} />
            </InputRow>
          </SafeRow>
          <SafeRow style={{ marginTop: 8, justifyContent: 'flex-start' }}>
            <InputRow style={{ maxWidth: 300 }}>
              <label>Tube Discrepency (Short)</label>
              <MoneyInput
                readOnly
                disabled
                value={value.discrepencyShort || 0}
                backgroundColor={
                  value.discrepencyShort && +value.discrepencyShort !== 0
                    ? 'rgb(255, 229, 0)'
                    : undefined
                }
                style={{ maxWidth: 100 }}
              />
            </InputRow>
          </SafeRow>
        </SafeSection>
        <AuditSection>
          <CommentRow>
            <label>Comments:</label>
            <Input
              value={value.comments || ''}
              onValueChange={comments => updateValue('comments', comments)}
              data-testid="commentsInput"
              tabIndex={-1}
            />
          </CommentRow>
          <AuditColumns>
            <AuditColumn style={{ flex: 1 }} />

            <AuditColumn>
              <PrintRow>
                <label>Print Shift</label>
                <PrintButton
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                    window.print();
                  }}
                  tabIndex={-1}
                >
                  <FaPrint />
                </PrintButton>
              </PrintRow>
              <CheckboxRow>
                <Column>
                  <SignoffLabel>Employee Signoff</SignoffLabel>
                  <Checkbox
                    tabIndex={-1}
                    readOnly={readOnly}
                    checked={!!value.employeeSignoff}
                    onValueChange={async checked => {
                      if (checked) {
                        const confirmed = window.confirm(
                          `${(user && user.firstName) || ''} ${(user &&
                            user.lastName) ||
                            ''}, are you sure you want to sign off? Did you remember to PRINT your Shift?`,
                        );
                        if (confirmed) {
                          await save({
                            ...value,
                            employeeSignoff: 1,
                          });
                          await Api.logout();
                          navigate(`/login?store_id=${value.storeId}`);
                        }
                      }
                    }}
                    data-testid="employeeSignoffInput"
                  />
                </Column>
                <Column>
                  <SignoffLabel>Auditor Signoff</SignoffLabel>
                  <Checkbox
                    tabIndex={-1}
                    readOnly={readOnly}
                    checked={!!value.auditSignoff}
                    onValueChange={checked =>
                      updateValue('auditSignoff', checked ? 1 : 0)
                    }
                    data-testid="auditSignoffInput"
                  />
                </Column>
              </CheckboxRow>
            </AuditColumn>
          </AuditColumns>
        </AuditSection>
        <CancelRow>
          <BlockButton onClick={() => close(true)} tabIndex={-1}>
            Close
          </BlockButton>
          <BlockButton onClick={() => navigate(backRoute || '/')} tabIndex={-1}>
            Cancel
          </BlockButton>
          {user && onDelete && isSuperAdmin(user) && (
            <BlockButton
              onClick={async () => {
                if (
                  window.confirm('Are you sure you want to delete this shift?')
                ) {
                  await onDelete();
                  await close(false);
                }
              }}
            >
              Delete
            </BlockButton>
          )}
        </CancelRow>
      </FormBox>
    </>
  );
};

export default styled(ShiftForm)`
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;

  @media print {
    width: 100%;
    transform: scale(0.6);
    transform-origin: 0 0;
    overflow: visible;
    button {
      display: none;
    }
  }

  ${MoneyInput}, ${NumberInput}, ${Input} {
    height: 25px;
    text-align: right;
    padding: 0;
    flex: 0 1 50%;
    width: auto;
    min-width: 65px;
    border-bottom: 2px solid #ddd;
    border-right: 2px solid #ddd;

    &:read-only {
      border: none;
    }
  }

  ${MoneyInput} > input {
    width: auto;
    min-width: auto;
  }

  ${InputRow} {
    justify-content: stretch;
  }

  ${InputRow} > label {
    flex: 1 0 50%;
    min-width: auto;
  }

  ${BlockButton} {
    height: 30px;
    min-height: 30px;
    line-height: 1;
  }

  & label {
    text-align: left;
  }
`;

const ShiftInfoData = styled('div')`
  text-align: right;
`;

const ShiftInfoHeader = styled(Row)`
  margin-bottom: 10px;
  > ${Column} + ${Column} {
    padding-left: 40px;
  }

  > ${Column}:nth-child(1) ${ShiftInfoData} {
    min-width: 90px;
  }

  > ${Column}:nth-child(2) ${ShiftInfoData} {
    min-width: 60px;
  }

  /* prettier-ignore */
  > ${Column}:nth-child(4) ${ShiftInfoData},
  > ${Column}:nth-child(5) ${ShiftInfoData} {
    text-align: left;
  }
`;

const TopColumns = styled(Row)`
  min-width: 940px;
  ${MoneyText} {
    width: 120px;
  }
`;

const TopColumn = styled(Column)`
  width: calc(25% - 7.5px);
  min-height: 560px;

  & + & {
    padding-left: 10px;
  }

  :nth-child(3) {
    flex-basis: 26%;
  }
  :nth-child(4) {
    padding-top: 25px;
    flex-basis: 24%;
  }

  & + & ${BlockButton} {
    width: 100%;
  }

  ${BlockButton} {
    width: 250px;
  }
`;

const TopColumnButton = styled(BlockButton)`
  margin-bottom: 20px;
`;

const TextAreaColumn = styled(Column)`
  label {
    text-align: center;
    margin-bottom: 3px;
  }

  & + & {
    margin-top: 20px;
  }

  textarea {
    min-height: 110px;
  }
`;

const TillSection = styled(Column)`
  ${MoneyText} {
    width: 120px;
    margin-right: 10px;
  }
`;

const TillRow = styled(Row)`
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  width: 600px;
  max-width: 100%;
  label {
    width: 150px;
  }

  ${MoneyInput} + label {
    padding-left: 20px;
  }

  > label {
    flex-basis: 60%;
  }
  > ${MoneyInput}, > ${NumberInput} {
    flex-basis: 35%;
  }
`;

const VpIn = styled(InputRow)`
  width: 200px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
`;

const SafeSection = styled(Column)`
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid black;
  max-width: 100%;
  width: 1000px;
  padding: 0 40px;
  border-left: none;
  h2 {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 20px;
    text-decoration: underline;
  }
`;

const SectionDivider = styled.hr`
  margin: 2px 0 2px 0;
  height: 1px;
  width: 95%;
`;

const SafeRow = styled(Row)`
  justify-content: space-evenly;
  max-width: 100%;

  ${InputRow} {
    flex: 1 1 33%;
  }
  label {
    padding-left: 10px;
  }
`;

const AuditSection = styled(Column)``;

const CommentRow = styled(Row)`
  justify-content: stretch;
  align-items: center;
  max-width: 80%;

  ${Input} {
    flex: 1 1 60%;
    margin-left: 10px;
    height: 25px;
    max-width: 400px;
  }

  label {
    width: 125px;
    flex-grow: 0;
  }
`;

const AuditColumn = styled(Column)`
  min-height: 20px;
  justify-content: flex-start;
  align-items: stretch;
  flex-shrink: 0;

  
  ${InputRow} > label {
    text-align: right;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 65%;
    min-width: 120px;
    text-align: right;
  }
  
  ${InputRow} > ${MoneyInput},
  ${InputRow} > ${NumberInput} {
    flex-basis: 30%;
  }


  textarea {
    width: 225px;
    height: 60px;
    padding: 0;
  }
`;

const AuditColumns = styled(Row)`
  max-width: 100%;
  min-width:700px;

  /* prettier-ignore */
  ${AuditColumn}:nth-child(1) {
    margin-right: 15px;

    ${InputRow}:nth-child(1) {
      margin-bottom: 25px;
    }

    ${InputRow}:nth-child(3) {
      margin-bottom: 25px;
    }

    ${InputRow}:nth-child(6) {
      margin-bottom: 25px;
    }
    ${InputRow}:nth-child(7) {
      margin-bottom: 30px;
    }
  }
  /* prettier-ignore */
  ${AuditColumn}:nth-child(2) {
    label {
      height: 25px;
    }

    input {
      width: 100%;
      flex-grow: 0;
    }
  }
  /* prettier-ignore */
  ${AuditColumn}:nth-child(3) {
    margin-left: -75px;

    ${InputRow} > label {
      width: 225px;
      white-space: nowrap;
      text-align: right;
    }
  }
`;

const PrintRow = styled(Row)`
  align-items: center;
  justify-content: center;
  margin-left: 75px;
  margin-top: 75px;
  & > label {
    padding-right: 70px;
    text-align: center;
  }
`;

const PrintButton = styled(BlockButton)`
  flex-direction: column;
  width: 60px;
  min-width: auto;
`;

const CheckboxRow = styled(Row)`
  margin-left: 75px;
  margin-top: 20px;
  justify-content: space-evenly;
  > ${Column} {
    align-items: center;
  }
`;

const CancelRow = styled(Row)`
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 275px 0 75px;
`;

const AccountHeader = styled(Row)`
  margin-bottom: 15px;
`;

const EntryLabel = styled.label`
  min-width: 150px;
`;

const SignoffLabel = styled.label`
  width: 125px !important;
`;

const TotalRow = styled(Row)`
  margin-top: auto;
  width: 100%;
  overflow: hidden;
  align-items: center;

  > label {
    flex: 1 0 90px;
  }

  > ${NumberInput}, > ${MoneyInput} {
    flex: 0 1 100px;
    width: auto;
    max-width: 150px;
    margin-left: auto;
  }

  > ${MoneyInput} input {
    width: auto;
    min-width: auto;
  }
`;
