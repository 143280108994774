import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Input from './Input';
import BlockButton from './BlockButton';
import Row from './Row';
import FormBox from './FormBox';
import ShiftMaster, {
  ShiftMasterDraft,
  shiftMasterFromDraft,
} from '../models/ShiftMaster';
import noop from '../utils/noop';

export interface GeneralShiftEntriesFormProps {
  value: ShiftMasterDraft;
  readOnly: boolean;
  onSubmit?: (value: ShiftMaster) => any;
  onCancel?: () => any;
}

const GeneralShiftEntriesForm: React.FC<
  GeneralShiftEntriesFormProps
> = props => {
  const [value, setValue] = useState(() => {
    const draft = props.value;
    const shiftMaster = shiftMasterFromDraft(draft);
    const cashDispensed = shiftMaster.cashDispensed || 0;
    const discrepencyOver = shiftMaster.discrepencyOver || 0;
    const discrepencyShort = shiftMaster.discrepencyShort || 0;
    const beginningPokerBank = shiftMaster.beginningPokerBank || 0;

    if (
      cashDispensed === 0 &&
      discrepencyOver === 0 &&
      discrepencyShort === 0 &&
      beginningPokerBank !== 0
    ) {
      return {
        ...draft,
        cashDispensed: beginningPokerBank,
      };
    }
    return draft;
  });
  const { onSubmit = noop, onCancel, readOnly, ...rest } = props;

  function handleSubmit() {
    const shiftMaster = shiftMasterFromDraft(value);
    const beginningPokerBank = shiftMaster.cashDispensed;

    const beginningCash =
      (shiftMaster.beginningTill || 0) +
      (shiftMaster.beginningChange || 0) +
      beginningPokerBank;

    const endingCash =
      (shiftMaster.endingTill || 0) +
      (shiftMaster.endingChange || 0) +
      (shiftMaster.endingPokerBank || 0);

    onSubmit({
      ...shiftMaster,
      beginningPokerBank,
      beginningCash,
      endingCash,
    });
  }

  return (
    <FormBox
      {...rest}
      title="Remaining Entries : Form"
      onCloseClick={handleSubmit}
    >
      <FormBody>
        <h1>General Shift Entries</h1>
        <FormRow>
          <FormRowEntry>
            <label>Employee Meals</label>
            <Input
              readOnly={readOnly}
              value={value.employeeMeals || ''}
              onValueChange={employeeMeals =>
                setValue({ ...value, employeeMeals })
              }
              data-testid="employeeMealsInput"
            />
          </FormRowEntry>
          <FormRowEntry>
            <label>Overrings</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.overrings || ''}
              onValueChange={overrings => setValue({ ...value, overrings })}
              data-testid="overringsInput"
            />
          </FormRowEntry>
        </FormRow>

        <FormRow>
          <EmptyFormRowEntry />
          <FormRowEntry>
            <label>Lottery Coupons</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.lotteryCoupons || ''}
              onValueChange={lotteryCoupons =>
                setValue({ ...value, lotteryCoupons })
              }
              data-testid="lotteryCouponsInput"
            />
          </FormRowEntry>
        </FormRow>
        <FormRow>
          <FormRowEntry>
            <label>Cash Drop</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.cashDrop || ''}
              onValueChange={cashDrop => setValue({ ...value, cashDrop })}
              data-testid="cashDropInput"
            />
          </FormRowEntry>
          <FormRowEntry>
            <label>VLM Cash In</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.vlmcashIn || ''}
              onValueChange={vlmcashIn => setValue({ ...value, vlmcashIn })}
              data-testid="vlmCashInInput"
            />
          </FormRowEntry>
        </FormRow>

        <FormRow>
          <FormRowEntry>
            <label>Jam Bills Out:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.jamBillsOut || ''}
              onValueChange={jamBillsOut => setValue({ ...value, jamBillsOut })}
            />
          </FormRowEntry>
          <FormRowEntry>
            <label>Jam Bills Out Comments:</label>
            <Input
              readOnly={readOnly}
              style={{ flex: 1, height: 25 }}
              value={value.jamBillsComments || ''}
              onValueChange={jamBillsComments =>
                setValue({ ...value, jamBillsComments })
              }
            />
          </FormRowEntry>
        </FormRow>

        <FormRow>
          <FormRowEntry>
            <label>Payout:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.payouts || ''}
              onValueChange={payouts => setValue({ ...value, payouts })}
            />
          </FormRowEntry>
          <FormRowEntry>
            <label>Payout Comments:</label>
            <Input
              readOnly={readOnly}
              style={{ flex: 1, height: 25 }}
              value={value.payoutsComments || ''}
              onValueChange={payoutsComments =>
                setValue({ ...value, payoutsComments })
              }
            />
          </FormRowEntry>
        </FormRow>

        <FormRow>
          <FormRowEntry>
            <label>Any other Cash in:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={value.other || ''}
              onValueChange={other => setValue({ ...value, other })}
            />
          </FormRowEntry>
          <FormRowEntry>
            <label>Any other Cash in Comments:</label>
            <Input
              readOnly={readOnly}
              style={{ flex: 1, height: 25 }}
              value={value.othercomments || ''}
              onValueChange={othercomments =>
                setValue({ ...value, othercomments })
              }
            />
          </FormRowEntry>
        </FormRow>

        <TillEntries>
          <TillEntryRow>
            <FormRowEntry>
              <label>Beginning Till</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.beginningTill || ''}
                onValueChange={beginningTill =>
                  setValue({ ...value, beginningTill })
                }
                data-testid="beginningTillInput"
              />
            </FormRowEntry>
            <FormRowEntry>
              <label>Beginning Change</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.beginningChange || ''}
                onValueChange={beginningChange =>
                  setValue({ ...value, beginningChange })
                }
                data-testid="beginningChangeInput"
              />
            </FormRowEntry>
          </TillEntryRow>

          <TillEntryRow>
            <FormRowEntry>
              <label>Ending Till</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.endingTill || ''}
                onValueChange={endingTill => setValue({ ...value, endingTill })}
                data-testid="endingTillInput"
              />
            </FormRowEntry>
            <FormRowEntry>
              <label>Ending Change</label>
              <Input
                isMoney
                readOnly={readOnly}
                value={value.endingChange || ''}
                onValueChange={endingChange =>
                  setValue({ ...value, endingChange })
                }
                data-testid="endingChangeInput"
              />
            </FormRowEntry>
          </TillEntryRow>
        </TillEntries>
        <SafeBox>
          <h2>Safe</h2>
          <FormRow>
            <FormRowEntry>
              <label>Cash Disp-Safe Report:</label>
              <SafeMoneyInput
                readOnly={readOnly}
                value={value.cashDispensed || ''}
                onValueChange={cashDispensed => {
                  setValue({ ...value, cashDispensed });
                }}
                data-testid="cashDispensedTotalInput"
              />
            </FormRowEntry>

            <FormRowEntry>
              <ColTotalLabel>Col Balance (column)</ColTotalLabel>
              <TotalSafeMoneyInput
                readOnly={readOnly}
                value={value.columnTotal || ''}
                onValueChange={columnTotal =>
                  setValue({ ...value, columnTotal })
                }
                data-testid="colTotalInput"
              />
            </FormRowEntry>
            <FormRowEntry>
              <label>Cassette + Manual Drop:</label>
              <TotalSafeMoneyInput
                readOnly={readOnly}
                value={value.cassetteDeposit || ''}
                onValueChange={cassetteDeposit =>
                  setValue({ ...value, cassetteDeposit })
                }
                data-testid="cassetteAndManualDropInput"
              />
            </FormRowEntry>
          </FormRow>
          <FormRow>
            <FormRowEntry>
              <label>Tube Discrepency (Over)</label>
              <SafeMoneyInput
                readOnly={readOnly}
                value={value.discrepencyOver}
                onValueChange={discrepencyOver =>
                  setValue({ ...value, discrepencyOver })
                }
                style={{
                  backgroundColor:
                    value.discrepencyOver && +value.discrepencyOver !== 0
                      ? 'rgb(255, 229, 0)'
                      : 'white',
                }}
                data-testid="tubeDiscrepencyOverInput"
              />
            </FormRowEntry>

            <GrowFormRowEntry>
              <LongLabel>Tube Discrepency Comments:</LongLabel>
              <DiscrepencyComment
                readOnly={readOnly}
                value={value.discrepencyComments || ''}
                onValueChange={discrepencyComments =>
                  setValue({ ...value, discrepencyComments })
                }
                data-testid="tubeDiscrepencyCommentsInput"
              />
            </GrowFormRowEntry>
          </FormRow>
          <FormRow>
            <FormRowEntry>
              <label>Tube Discrepency (Short)</label>
              <SafeMoneyInput
                readOnly={readOnly}
                value={value.discrepencyShort}
                onValueChange={discrepencyShort =>
                  setValue({ ...value, discrepencyShort })
                }
                style={{
                  backgroundColor:
                    value.discrepencyShort && +value.discrepencyShort !== 0
                      ? 'rgb(255, 229, 0)'
                      : 'white',
                }}
                data-testid="tubeDiscrepencyShortInput"
              />
            </FormRowEntry>
          </FormRow>
        </SafeBox>
        <Line />
        <ButtonRow>
          <BlockButton onClick={handleSubmit} data-testid="enterButton">
            Enter
          </BlockButton>
          <BlockButton onClick={onCancel} data-testid="cancelButton">
            CANCEL
          </BlockButton>
        </ButtonRow>
      </FormBody>
    </FormBox>
  );
};

export default styled(GeneralShiftEntriesForm)`
  h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  padding: 30px 20px 20px 20px;

  width: 1100px;
`;

const FormBody = styled(Column)`
  background-color: #aaaaaa;
`;

const FormRow = styled(Row)`
  align-items: center;

  & + & {
    margin-top: 10px;
  }

  ${Input} {
    height: 25px;
    width: 120px;
    padding: 0;
    text-align: right;
  }
`;

const FormRowEntry = styled(Row)`
  align-items: center;
  justify-content: flex-start;

  label {
    width: 200px;
  }

  * + & {
    margin-left: 20px;
  }
`;

const EmptyFormRowEntry = styled(FormRowEntry)`
  min-width: 320px;
`;

const GrowFormRowEntry = styled(FormRowEntry)`
  flex: 1;

  ${Input} {
    flex: 1;
    max-width: 270px;
  }
`;

const SafeBox = styled(Column)`
  margin-top: 20px;
  width: calc(100% - 10px);
  margin-top: 20px;
  margin-left: 5px;
  padding: 10px 5px 10px 5px;
  border: 1px solid #fff;

  h2 {
    text-align: center;
    text-decoration: underline;
  }

  label {
    width: 172px;
  }
`;

const ButtonRow = styled(Row)`
  justify-content: space-evenly;
  padding: 0 20px 20px 20px;
  width: 700px;
  margin-right: 100px;

  ${BlockButton} {
    height: 30px;
    min-height: 30px;
    padding: 0;
    width: 100px;
    min-width: 100px;
  }
`;

const TillEntryRow = styled(FormRow)`
  justify-content: center;
`;

const TillEntries = styled(Column)`
  margin-top: 100px;
  align-self: flex-start;
  margin-left: 90px;
  margin-top: 50px;
`;

const LongLabel = styled.label`
  && {
    width: 220px;
  }
`;

const SafeMoneyInput = styled(Input)`
  && {
    width: 120px;
  }
`;

const TotalSafeMoneyInput = styled(Input)`
  && {
    width: 155px;
  }
`;

const DiscrepencyComment = styled(Input)`
  && {
    height: 25px;
    padding-left: 0;
    max-width: 300px;
  }
`;

const Line = styled.hr`
  position: relative;
  right: 25px;
  margin-bottom: 5px;
  width: 850px;
  border: 3px solid black;
`;

const ColTotalLabel = styled.label`
  && {
    width: 80px;
    margin-right: 10px;
    text-align: center;
  }
`;
