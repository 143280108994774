import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import EopPayoutsReport from '../components/EopPayoutsReport';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import isValidDate from '../utils/isValidDate';
import { PayoutReportDataRow } from '../api/PayoutReportEndpoints';
import SpinnerOverlay from '../components/SpinnerOverlay';

const PayoutReport: PageComponent = props => {
  const [data, setData] = useState<PayoutReportDataRow[] | null>(null);

  useAsyncEffect(async () => {
    const startDate = window.prompt('Beginning date:');
    if (!startDate) {
      window.history.back();
      return;
    }
    if (!isValidDate(startDate)) {
      window.alert('Invalid beginning date.');
      window.history.back();
      return;
    }
    const endDate = window.prompt('Ending date');
    if (!endDate) {
      window.history.back();
      return;
    }
    if (!isValidDate(endDate)) {
      window.alert('Invalid beginning date.');
      window.history.back();
      return;
    }
    setData(await Api.getPayoutReportData(startDate, endDate));
  }, []);

  if (!data) {
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <EopPayoutsReport date={new Date()} data={data} />
    </Page>
  );
};

export default styled(withAuth(PayoutReport))``;
