import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import FormBox from './FormBox';
import Row from './Row';
import { FaPrint } from './icons';
import BlockButton from './BlockButton';
import NumberInput from './NumberInput';
import MoneyInput from './MoneyInput';
import Input from './Input';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import PaginationRow from './PaginationRow';
import W2G from '../models/W2G';
import formatDate from '../utils/formatDate';
import replaceWhere from '../utils/replaceWhere';
import isBlank from '../utils/isBlank';
import Store from '../models/Store';
import usePrintLayout from '../utils/usePrintLayout';
import noop from '../utils/noop';
import logError from '../utils/logError';

export interface W2gFormProps {
  storeId: number;
  shiftDate: string;
  shiftNumber: number;
  employeeName: string;
  employeeId: string;
  readOnly: boolean;
  onSubmit?: (w2gTotal: number) => any;
  onCancel?: () => any;
}

const W2gForm: React.FunctionComponent<W2gFormProps> = props => {
  usePrintLayout('landscape');
  const {
    storeId,
    shiftDate,
    shiftNumber,
    employeeName,
    employeeId,
    readOnly,
    onSubmit,
    onCancel = noop,
    ...rest
  } = props;
  const [store, setStore] = useState<Store | null>(null);
  const [w2gRows, setW2gRows] = useState<W2G[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [newW2g, setNewW2g] = useState<W2G>({
    id: -1,
    storeId,
    employeeId,
    winnersName: '',
    winnersAddress: '',
    winnersCityStateZip: '',
    grossWinnings: 0,
    federalIncomeTax: 0,
    date: new Date(shiftDate).toISOString(),
    shift: shiftNumber,
    cashier: employeeName,
    winnersIdentification: '',
    firstId: '',
    secondId: '',
    stateIncomeTax: 0,
    formOfPayment: '',
    checkNumber: '',
    transaction: 0,
    payerIdentification: '',
    typeOfWager: 'Video Poker',
  });
  const currentW2g: W2G = w2gRows[pageIndex] || newW2g;
  const queueRef = useRef<Promise<unknown>>(Promise.resolve()); // Used to ensure everything is saved before calculating the total

  async function handleW2gChange(changes: Partial<W2G>) {
    if (readOnly) {
      return;
    }
    if (currentW2g === newW2g) {
      setNewW2g({
        id: -1,
        storeId,
        employeeId,
        winnersName: '',
        winnersAddress: '',
        winnersCityStateZip: '',
        grossWinnings: 0,
        federalIncomeTax: 0,
        date: new Date(shiftDate).toISOString(),
        shift: shiftNumber,
        cashier: '',
        winnersIdentification: '',
        firstId: '',
        secondId: '',
        stateIncomeTax: 0,
        formOfPayment: '',
        checkNumber: '',
        transaction: 0,
        payerIdentification: '',
        typeOfWager: 'Video Poker',
      });
      const promise = Api.createW2g(newW2g);
      queueRef.current = Promise.all([
        queueRef.current,
        promise.catch(error => logError('Failed to create w2g', error)),
      ]);

      setW2gRows([...w2gRows, await promise]);
    } else {
      const existingRow = w2gRows.find(row => row.id === currentW2g.id);
      if (existingRow) {
        const newRow = { ...existingRow, ...changes };

        setW2gRows(
          replaceWhere(w2gRows, row => row === currentW2g, () => newRow),
        );

        const promise = Api.updateW2g(newRow);
        queueRef.current = Promise.all([
          queueRef.current,
          promise.catch(error => logError('Failed to update w2g', error)),
        ]);
        await promise;
      }
    }
  }

  async function handleDeleteClick() {
    if (readOnly || currentW2g === newW2g) {
      return;
    }
    await Api.deleteW2g(currentW2g.id);
    setW2gRows(w2gRows.filter(other => other.id !== currentW2g.id));
  }

  async function handleCloseClick() {
    if (
      readOnly ||
      isBlank(currentW2g.cashier) ||
      isBlank(currentW2g.winnersIdentification) ||
      isBlank(currentW2g.firstId) ||
      isBlank(currentW2g.secondId)
    ) {
      onCancel();
      return;
    }

    if (onSubmit) {
      let w2gTotal = 0;

      await queueRef.current;
      for (const w2gRow of await Api.getW2gRowsForShift(
        shiftDate,
        shiftNumber,
        employeeId,
      )) {
        w2gTotal +=
          (w2gRow.federalIncomeTax || 0) + (w2gRow.stateIncomeTax || 0);
      }

      onSubmit(w2gTotal);
    }
  }

  useAsyncEffect(async () => {
    const rows = await Api.getW2gRowsForShift(
      shiftDate,
      shiftNumber,
      employeeId,
    );
    setW2gRows(rows);
    setStore(await Api.getStoreById(storeId));
  }, [shiftDate, shiftNumber, employeeId]);

  return (
    <FormBox {...rest} title="W-2G" onCloseClick={handleCloseClick}>
      <Column>
        <Row>
          <Column>
            <BorderedGroup>
              <PayerInfoHeader>Payers Name:</PayerInfoHeader>
              <p>MIR, LLC</p>
              <PayerInfoHeader>Street Address:</PayerInfoHeader>
              <p>600-A Prestige Park</p>
              <PayerInfoHeader>City, State and Zip code:</PayerInfoHeader>
              <p>Hurricane, WV 25526</p>
            </BorderedGroup>
            <BorderedGroup>
              <p>
                <strong>WINNER'S Name:</strong>
              </p>
              <Input
                data-testid="winnerName"
                readOnly={readOnly}
                tabIndex={10}
                value={currentW2g.winnersName || ''}
                onValueChange={winnersName => handleW2gChange({ winnersName })}
              />
              <p>
                <strong>Street Address:</strong>
              </p>
              <Input
                data-testid="winnerStreetAddress"
                readOnly={readOnly}
                tabIndex={11}
                value={currentW2g.winnersAddress}
                onValueChange={winnersAddress =>
                  handleW2gChange({ winnersAddress })
                }
              />
              <p>
                <strong>City, State and Zip code:</strong>
              </p>
              <Input
                data-testid="winnerCityStateZip"
                readOnly={readOnly}
                tabIndex={12}
                value={currentW2g.winnersCityStateZip || ''}
                onValueChange={winnersCityStateZip =>
                  handleW2gChange({ winnersCityStateZip })
                }
              />
            </BorderedGroup>
            <StoreInputsGroup>
              <p>
                <label>StoreID:</label>
                <Input
                  data-testid="storeId"
                  readOnly
                  value={storeId}
                  tabIndex={15}
                />
              </p>
              <p>
                <label>Date:</label>
                <Input
                  data-testid="date"
                  tabIndex={16}
                  readOnly
                  value={formatDate(shiftDate)}
                />
              </p>
              <p>
                <label>Shift:</label>
                <NumberInput
                  data-testid="shift"
                  readOnly
                  value={shiftNumber}
                  tabIndex={17}
                />
              </p>
              <p>
                <label>Emp ID:</label>
                <NumberInput
                  data-testid="empId"
                  readOnly
                  value={employeeId}
                  tabIndex={18}
                />
              </p>
            </StoreInputsGroup>
          </Column>
          <ColumnTwo>
            <PaddedRow>
              <FixedWidthLabel>1. Gross Winnings</FixedWidthLabel>
              <MoneyInput
                tabIndex={1}
                readOnly={readOnly}
                data-testid="grossWinnings"
                value={currentW2g.grossWinnings || 0}
                onValueChange={grossWinnings =>
                  handleW2gChange({ grossWinnings })
                }
              />
              <FixedWidthLabel>2. Federal Income Tax</FixedWidthLabel>
              <MoneyInput
                tabIndex={2}
                readOnly={readOnly}
                data-testid="federalIncomeTax"
                value={currentW2g.federalIncomeTax || 0}
                onValueChange={federalIncomeTax =>
                  handleW2gChange({ federalIncomeTax })
                }
              />
            </PaddedRow>
            <PaddedRow>
              <FixedWidthLabel>3. Type of Wager</FixedWidthLabel>
              <Input
                data-testid="typeOfWager"
                readOnly
                value="Video Poker"
                tabIndex={-1}
              />
              <FixedWidthLabel>4. Date Won</FixedWidthLabel>
              <Input
                data-testid="dateWon"
                readOnly
                value={formatDate(shiftDate)}
                tabIndex={-1}
              />
            </PaddedRow>
            <PaddedRow>
              <FixedWidthLabel>5. Transaction</FixedWidthLabel>
              <NumberInput
                data-testid="transaction"
                readOnly={readOnly}
                tabIndex={3}
                value={currentW2g.transaction || 0}
                onValueChange={transaction => handleW2gChange({ transaction })}
              />
              <FixedWidthLabel>6. Race</FixedWidthLabel>
              <Input data-testid="race" readOnly value="NA" tabIndex={-1} />
            </PaddedRow>
            <PaddedRow>
              <FixedWidthLabel>
                7. Winnings from identical wagers
              </FixedWidthLabel>
              <Input
                data-testid="winningsFromIdenticalWagers"
                readOnly
                value="NA"
                tabIndex={-1}
              />
              <FixedWidthLabel>8. Cashier</FixedWidthLabel>
              <Input
                data-testid="cashier"
                readOnly={readOnly}
                tabIndex={4}
                value={currentW2g.cashier || ''}
                onValueChange={cashier => handleW2gChange({ cashier })}
              />
            </PaddedRow>
            <PaddedRow>
              <FixedWidthLabel>9. Winners Identification</FixedWidthLabel>
              <Input
                data-testid="winnerIdentification"
                readOnly={readOnly}
                tabIndex={5}
                value={currentW2g.winnersIdentification || ''}
                onValueChange={winnersIdentification =>
                  handleW2gChange({ winnersIdentification })
                }
              />
              <FixedWidthLabel>10. Window</FixedWidthLabel>
              <Input
                data-testid="windowValue"
                readOnly
                value={(store && store.storeName) || ''}
                tabIndex={-1}
              />
            </PaddedRow>
            <PaddedRow>
              <FixedWidthLabel>11. First ID</FixedWidthLabel>
              <Input
                data-testid="firstId"
                readOnly={readOnly}
                tabIndex={6}
                value={currentW2g.firstId || ''}
                onValueChange={firstId => handleW2gChange({ firstId })}
              />
              <FixedWidthLabel>12. Second ID</FixedWidthLabel>
              <Input
                data-testid="secondId"
                readOnly={readOnly}
                tabIndex={7}
                value={currentW2g.secondId || ''}
                onValueChange={secondId => handleW2gChange({ secondId })}
              />
            </PaddedRow>
            <PaddedRow>
              <FixedWidthLabel>
                13. State/Payer's Identification
              </FixedWidthLabel>
              <Input
                data-testid="statePayersId"
                readOnly={readOnly}
                tabIndex={8}
                value={currentW2g.payerIdentification || 'WV 2271-8140'}
                onValueChange={payerIdentification =>
                  handleW2gChange({ payerIdentification })
                }
              />
              <FixedWidthLabel>14. State Income Tax</FixedWidthLabel>
              <MoneyInput
                data-testid="stateIncomeTax"
                tabIndex={9}
                value={currentW2g.stateIncomeTax || 0}
                onValueChange={stateIncomeTax =>
                  handleW2gChange({ stateIncomeTax })
                }
              />
            </PaddedRow>
            <PaddedRow>
              <div />
              <PlainFixedWidthLabel>Form of Payment</PlainFixedWidthLabel>
              <Input
                data-testid="formOfPayment"
                readOnly={readOnly}
                tabIndex={13}
                value={currentW2g.formOfPayment || ''}
                onValueChange={formOfPayment =>
                  handleW2gChange({ formOfPayment })
                }
              />
            </PaddedRow>
            <PaddedRow>
              <div />
              <PlainFixedWidthLabel>CheckNumber</PlainFixedWidthLabel>
              <Input
                data-testid="checkNumber"
                readOnly={readOnly}
                tabIndex={14}
                value={currentW2g.checkNumber || ''}
                onValueChange={checkNumber => handleW2gChange({ checkNumber })}
              />
            </PaddedRow>
            <ButtonRow>
              <BlockButton
                title="Print"
                onClick={() => {
                  window.scrollTo({ top: 0 });
                  window.print();
                }}
                tabIndex={19}
              >
                <FaPrint size="1.5em" />
              </BlockButton>
              <BlockButton
                onClick={handleDeleteClick}
                disabled={readOnly || currentW2g === newW2g}
                tabIndex={21}
              >
                Delete Record
              </BlockButton>
              <BlockButton onClick={handleCloseClick} tabIndex={20}>
                Close Form
              </BlockButton>
            </ButtonRow>
          </ColumnTwo>
        </Row>
      </Column>
      <PaginationRow
        page={pageIndex}
        pageCount={w2gRows.length + 1}
        onChange={setPageIndex}
      />
    </FormBox>
  );
};

const Group = styled('div')`
  margin-bottom: 10px;
  padding: 10px;

  > p {
    min-height: 16px;
  }
`;

const BorderedGroup = styled(Group)`
  border: 1px solid black;
  width: 275px;
`;

const PayerInfoHeader = styled('p')`
  font-weight: bold;
  margin-bottom: 10px;
  & + p {
    margin-bottom: 10px;
    min-height: 14px;
  }
`;

const StoreInputsGroup = styled(Group)`
  label {
    width: 75px;
    display: inline-block;
    font-weight: bold;
  }
  ${Input}, ${NumberInput} {
    display: inline-block;
    width: 90px;
    min-width: inherit;
    height: 20px;
    padding: 0px;
  }
`;

const FixedWidthLabel = styled('label')`
  margin: 0 7px 0;
  padding: 5px 1px;
  box-sizing: border-box;
  width: 100px;
  font-size: 14px;
  border: 1px solid black;
  :nth-child(1) {
    margin-left: 0;
  }
`;

const PlainFixedWidthLabel = styled(FixedWidthLabel)`
  border: none;
`;

const PaddedRow = styled(Row)`
  padding: 10px;
  box-sizing: border-box;
  & > * {
    max-width: 25%;
    flex: 1 1 auto;
  }
`;

const ButtonRow = styled(PaddedRow)`
  padding: 10px 10px 10px 180px;
  box-sizing: border-box;
  ${BlockButton} {
    min-width: auto;
    width: auto;
    margin-right: 10px;
    flex: 0;
    display: block;
  }
`;

const ColumnTwo = styled(Column)`
  flex-grow: 1;
  align-items: stretch;
`;

export default styled(W2gForm)`
  width: 1150px;


  ${Input},
  ${NumberInput},
  ${MoneyInput} {
    height: 25px;
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 20px;
  }

  @media print {
    transform-origin: top left;
    overflow: visible;
    transform: scale(0.8, 0.8);

    ${BlockButton} {
      display: none;
    }
  }
`;
