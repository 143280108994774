export default interface ShiftMaster {
  storeId: number;
  shiftDate: string;
  shift: number;
  employee: string | null;
  firstName: string | null;
  lastName: string | null;
  employeeSignoff: number | null;
  period: number | null;
  beginningCash: number | null;
  endingCash: number | null;
  managerSignoff: number | null;
  auditSignoff: number | null;
  beginningTill: number | null;
  beginningPokerBank: number | null;
  beginningChange: number | null;
  endingTill: number | null;
  endingPokerBank: number | null;
  endingChange: number | null;
  food: number | null;
  nonAlcohol: number | null;
  alcoholBeverages: number | null;
  tobaccoPack: number | null;
  ppack: number | null;
  mpack: number | null;
  gpack: number | null;
  ppack2: number;
  mpack2: number;
  gpack2: number;
  tobaccoCarton: number | null;
  pcarton: number | null;
  mcarton: number | null;
  gcarton: number | null;
  cigextra1: number;
  cigextra2: number;
  cigextra3: number;
  cigextra4: number;
  cigextra5: number;
  totalVpIn: number | null;
  scratchTickets: number | null;
  decreaseBanks: number | null;
  winnerChecks: number | null;
  other: number | null;
  totalIn: number | null;
  employeeMeals: number | null;
  onLineSales: number | null;
  vpValidation: number | null;
  onLinePrizes: number | null;
  scratchOffValidation: number | null;
  pullTabPrizes: number | null;
  cigaretteDiscount: number | null;
  cigaretteCoupons: number | null;
  cigaretteDiscountOverring: number | null;
  espressoDiscount: number | null;
  foodDiscount: number | null;
  lotteryCoupons: number | null;
  payouts: number | null;
  payoutsComments: string | null;
  promotion: number | null;
  otherPayouts: number | null;
  overrings: number | null;
  cigaretteOverrings: number | null;
  increaseBanks: number | null;
  nonPrizeChecks: number | null;
  atmRefill: number | null;
  totalOut: number | null;
  fundsNeeded: number | null;
  cashDeposit: number | null;
  cashDepositB: number | null;
  cashDepositC: number | null;
  cashDepositD: number | null;
  creditCardSlips: number | null;
  totalDeposit: number | null;
  overShort: number | null;
  depositBagA: string | null;
  depositBagB: string | null;
  depositBagC: string | null;
  depositBagD: string | null;
  comments: string | null;
  creditCardSlipCount: number | null;
  atmCount: number | null;
  atmAmount: number | null;
  beginningCheckCount: number | null;
  endingCheckCount: number | null;
  checksWritten: number | null;
  checksAdded: number | null;
  beginningciginventory: number | null;
  transferCigs: number | null;
  innercompanytransfers: number | null;
  innerCompanyTransferComment: string | null;
  endingciginventory: number | null;
  cigaretteOr: number | null;
  idwriteup: number | null;
  idmailed: number | null;
  idrm: number | null;
  idcomments: number | null;
  othercomments: string | null;
  auditShift: number | null;
  jamBillsIn: number | null;
  jamBillsOut: number | null;
  cashToSafe: number | null;
  midShiftPull: number | null;
  salesTax: number | null;
  regularShiftTubeRefill: number | null;
  vpsales: number;
  cancel: number;
  discounts: number;
  cashDrop: number | null;
  vlmcashIn: number | null;
  columnTotal: number | null;
  cassetteDeposit: number | null;
  w2gTaxes: number;
  timestamp: Date | string;
  innerCompanyTransferTo: number;
  innerCompanyTransferNotes: string | null;
  cashDispensed: number;
  discrepencyOver: number;
  discrepencyShort: number;
  discrepencyComments: string | null;
  auditTimeStamp: Date | string | null;
  auditEmpId: string | null;
  jamBillsComments: string | null;
}

export interface ShiftMasterDraft {
  storeId: number;
  shiftDate: string;
  shift: number | string;
  employee: string | null;
  firstName: string | null;
  lastName: string | null;
  employeeSignoff: string | number | null;
  period: string | number | null;
  beginningCash: string | number | null;
  endingCash: string | number | null;
  managerSignoff: string | number | null;
  auditSignoff: string | number | null;
  beginningTill: string | number | null;
  beginningPokerBank: string | number | null;
  beginningChange: string | number | null;
  endingTill: string | number | null;
  endingPokerBank: string | number | null;
  endingChange: string | number | null;
  food: string | number | null;
  nonAlcohol: string | number | null;
  alcoholBeverages: string | number | null;
  tobaccoPack: string | number | null;
  ppack: string | number | null;
  mpack: string | number | null;
  gpack: string | number | null;
  ppack2: string | number;
  mpack2: string | number;
  gpack2: string | number;
  tobaccoCarton: string | number | null;
  pcarton: string | number | null;
  mcarton: string | number | null;
  gcarton: string | number | null;
  cigextra1: string | number;
  cigextra2: string | number;
  cigextra3: string | number;
  cigextra4: string | number;
  cigextra5: string | number;
  totalVpIn: string | number | null;
  scratchTickets: string | number | null;
  decreaseBanks: string | number | null;
  winnerChecks: string | number | null;
  other: string | number | null;
  totalIn: string | number | null;
  employeeMeals: string | number | null;
  onLineSales: string | number | null;
  vpValidation: string | number | null;
  onLinePrizes: string | number | null;
  scratchOffValidation: string | number | null;
  pullTabPrizes: string | number | null;
  cigaretteDiscount: string | number | null;
  cigaretteCoupons: string | number | null;
  cigaretteDiscountOverring: string | number | null;
  espressoDiscount: string | number | null;
  foodDiscount: string | number | null;
  lotteryCoupons: string | number | null;
  payouts: string | number | null;
  payoutsComments: string | null;
  promotion: string | number | null;
  otherPayouts: string | number | null;
  overrings: string | number | null;
  cigaretteOverrings: string | number | null;
  increaseBanks: string | number | null;
  nonPrizeChecks: string | number | null;
  atmRefill: string | number | null;
  totalOut: string | number | null;
  fundsNeeded: string | number | null;
  cashDeposit: string | number | null;
  cashDepositB: string | number | null;
  cashDepositC: string | number | null;
  cashDepositD: string | number | null;
  creditCardSlips: string | number | null;
  totalDeposit: string | number | null;
  overShort: string | number | null;
  depositBagA: string | null;
  depositBagB: string | null;
  depositBagC: string | null;
  depositBagD: string | null;
  comments: string | null;
  creditCardSlipCount: string | number | null;
  atmCount: string | number | null;
  atmAmount: string | number | null;
  beginningCheckCount: string | number | null;
  endingCheckCount: string | number | null;
  checksWritten: string | number | null;
  checksAdded: string | number | null;
  beginningciginventory: string | number | null;
  transferCigs: string | number | null;
  innercompanytransfers: string | number | null;
  innerCompanyTransferComment: string | null;
  endingciginventory: string | number | null;
  cigaretteOr: string | number | null;
  idwriteup: string | number | null;
  idmailed: string | number | null;
  idrm: string | number | null;
  idcomments: string | number | null;
  othercomments: string | null;
  auditShift: string | number | null;
  jamBillsIn: string | number | null;
  jamBillsOut: string | number | null;
  cashToSafe: string | number | null;
  midShiftPull: string | number | null;
  salesTax: string | number | null;
  regularShiftTubeRefill: string | number | null;
  vpsales: string | number;
  cancel: string | number;
  discounts: string | number;
  cashDrop: string | number | null;
  vlmcashIn: string | number | null;
  columnTotal: string | number | null;
  cassetteDeposit: string | number | null;
  w2gTaxes: string | number;
  timestamp: Date | string;
  innerCompanyTransferTo: string | number;
  innerCompanyTransferNotes: string | null;
  cashDispensed: string | number;
  discrepencyOver: string | number;
  discrepencyShort: string | number;
  discrepencyComments: string | null;
  auditTimeStamp: Date | string | null;
  auditEmpId: string | null;
  jamBillsComments: string | null;
}

export function shiftMasterFromDraft(draft: ShiftMasterDraft): ShiftMaster {
  return {
    ...draft,
    shift: +draft.shift,
    employeeSignoff:
      draft.employeeSignoff === null ? null : +draft.employeeSignoff,
    period: draft.period === null ? null : +draft.period,
    beginningCash: draft.beginningCash === null ? null : +draft.beginningCash,
    endingCash: draft.endingCash === null ? null : +draft.endingCash,
    managerSignoff:
      draft.managerSignoff === null ? null : +draft.managerSignoff,
    auditSignoff: draft.auditSignoff === null ? null : +draft.auditSignoff,
    beginningTill: draft.beginningTill === null ? null : +draft.beginningTill,
    beginningPokerBank:
      draft.beginningPokerBank === null ? null : +draft.beginningPokerBank,
    beginningChange:
      draft.beginningChange === null ? null : +draft.beginningChange,
    endingTill: draft.endingTill === null ? null : +draft.endingTill,
    endingPokerBank:
      draft.endingPokerBank === null ? null : +draft.endingPokerBank,
    endingChange: draft.endingChange === null ? null : +draft.endingChange,
    food: draft.food === null ? null : +draft.food,
    nonAlcohol: draft.nonAlcohol === null ? null : +draft.nonAlcohol,
    alcoholBeverages:
      draft.alcoholBeverages === null ? null : +draft.alcoholBeverages,
    tobaccoPack: draft.tobaccoPack === null ? null : +draft.tobaccoPack,
    ppack: draft.ppack === null ? null : +draft.ppack,
    mpack: draft.mpack === null ? null : +draft.mpack,
    gpack: draft.gpack === null ? null : +draft.gpack,
    ppack2: +draft.ppack2,
    mpack2: +draft.mpack2,
    gpack2: +draft.gpack2,
    tobaccoCarton: draft.tobaccoCarton === null ? null : +draft.tobaccoCarton,
    pcarton: draft.pcarton === null ? null : +draft.pcarton,
    mcarton: draft.mcarton === null ? null : +draft.mcarton,
    gcarton: draft.gcarton === null ? null : +draft.gcarton,
    cigextra1: +draft.cigextra1,
    cigextra2: +draft.cigextra2,
    cigextra3: +draft.cigextra3,
    cigextra4: +draft.cigextra4,
    cigextra5: +draft.cigextra5,
    totalVpIn: draft.totalVpIn === null ? null : +draft.totalVpIn,
    scratchTickets:
      draft.scratchTickets === null ? null : +draft.scratchTickets,
    decreaseBanks: draft.decreaseBanks === null ? null : +draft.decreaseBanks,
    winnerChecks: draft.winnerChecks === null ? null : +draft.winnerChecks,
    other: draft.other === null ? null : +draft.other,
    totalIn: draft.totalIn === null ? null : +draft.totalIn,
    employeeMeals: draft.employeeMeals === null ? null : +draft.employeeMeals,
    onLineSales: draft.onLineSales === null ? null : +draft.onLineSales,
    vpValidation: draft.vpValidation === null ? null : +draft.vpValidation,
    onLinePrizes: draft.onLinePrizes === null ? null : +draft.onLinePrizes,
    scratchOffValidation:
      draft.scratchOffValidation === null ? null : +draft.scratchOffValidation,
    pullTabPrizes: draft.pullTabPrizes === null ? null : +draft.pullTabPrizes,
    cigaretteDiscount:
      draft.cigaretteDiscount === null ? null : +draft.cigaretteDiscount,
    cigaretteCoupons:
      draft.cigaretteCoupons === null ? null : +draft.cigaretteCoupons,
    cigaretteDiscountOverring:
      draft.cigaretteDiscountOverring === null
        ? null
        : +draft.cigaretteDiscountOverring,
    espressoDiscount:
      draft.espressoDiscount === null ? null : +draft.espressoDiscount,
    foodDiscount: draft.foodDiscount === null ? null : +draft.foodDiscount,
    lotteryCoupons:
      draft.lotteryCoupons === null ? null : +draft.lotteryCoupons,
    payouts: draft.payouts === null ? null : +draft.payouts,
    promotion: draft.promotion === null ? null : +draft.promotion,
    otherPayouts: draft.otherPayouts === null ? null : +draft.otherPayouts,
    overrings: draft.overrings === null ? null : +draft.overrings,
    cigaretteOverrings:
      draft.cigaretteOverrings === null ? null : +draft.cigaretteOverrings,
    increaseBanks: draft.increaseBanks === null ? null : +draft.increaseBanks,
    nonPrizeChecks:
      draft.nonPrizeChecks === null ? null : +draft.nonPrizeChecks,
    atmRefill: draft.atmRefill === null ? null : +draft.atmRefill,
    totalOut: draft.totalOut === null ? null : +draft.totalOut,
    fundsNeeded: draft.fundsNeeded === null ? null : +draft.fundsNeeded,
    cashDeposit: draft.cashDeposit === null ? null : +draft.cashDeposit,
    cashDepositB: draft.cashDepositB === null ? null : +draft.cashDepositB,
    cashDepositC: draft.cashDepositC === null ? null : +draft.cashDepositC,
    cashDepositD: draft.cashDepositD === null ? null : +draft.cashDepositD,
    creditCardSlips:
      draft.creditCardSlips === null ? null : +draft.creditCardSlips,
    totalDeposit: draft.totalDeposit === null ? null : +draft.totalDeposit,
    overShort: draft.overShort === null ? null : +draft.overShort,
    creditCardSlipCount:
      draft.creditCardSlipCount === null ? null : +draft.creditCardSlipCount,
    atmCount: draft.atmCount === null ? null : +draft.atmCount,
    atmAmount: draft.atmAmount === null ? null : +draft.atmAmount,
    beginningCheckCount:
      draft.beginningCheckCount === null ? null : +draft.beginningCheckCount,
    endingCheckCount:
      draft.endingCheckCount === null ? null : +draft.endingCheckCount,
    checksWritten: draft.checksWritten === null ? null : +draft.checksWritten,
    checksAdded: draft.checksAdded === null ? null : +draft.checksAdded,
    beginningciginventory:
      draft.beginningciginventory === null
        ? null
        : +draft.beginningciginventory,
    transferCigs: draft.transferCigs === null ? null : +draft.transferCigs,
    innercompanytransfers:
      draft.innercompanytransfers === null
        ? null
        : +draft.innercompanytransfers,
    endingciginventory:
      draft.endingciginventory === null ? null : +draft.endingciginventory,
    cigaretteOr: draft.cigaretteOr === null ? null : +draft.cigaretteOr,
    idwriteup: draft.idwriteup === null ? null : +draft.idwriteup,
    idmailed: draft.idmailed === null ? null : +draft.idmailed,
    idrm: draft.idrm === null ? null : +draft.idrm,
    idcomments: draft.idcomments === null ? null : +draft.idcomments,
    auditShift: draft.auditShift === null ? null : +draft.auditShift,
    jamBillsIn: draft.jamBillsIn === null ? null : +draft.jamBillsIn,
    jamBillsOut: draft.jamBillsOut === null ? null : +draft.jamBillsOut,
    cashToSafe: draft.cashToSafe === null ? null : +draft.cashToSafe,
    midShiftPull: draft.midShiftPull === null ? null : +draft.midShiftPull,
    salesTax: draft.salesTax === null ? null : +draft.salesTax,
    regularShiftTubeRefill:
      draft.regularShiftTubeRefill === null
        ? null
        : +draft.regularShiftTubeRefill,
    vpsales: +draft.vpsales,
    cancel: +draft.cancel,
    discounts: +draft.discounts,
    cashDrop: draft.cashDrop === null ? null : +draft.cashDrop,
    vlmcashIn: draft.vlmcashIn === null ? null : +draft.vlmcashIn,
    columnTotal: draft.columnTotal === null ? null : +draft.columnTotal,
    cassetteDeposit:
      draft.cassetteDeposit === null ? null : +draft.cassetteDeposit,
    w2gTaxes: +draft.w2gTaxes,
    innerCompanyTransferTo: +draft.innerCompanyTransferTo,
    cashDispensed: +draft.cashDispensed,
    discrepencyOver: +draft.discrepencyOver,
    discrepencyShort: +draft.discrepencyShort,
  };
}
