import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import W2gReport from '../components/W2gReport';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import isValidDate from '../utils/isValidDate';
import W2G from '../models/W2G';
import SpinnerOverlay from '../components/SpinnerOverlay';

const W2gReportPage: PageComponent = props => {
  const { user } = props;
  const [data, setData] = useState<W2G[] | null>(null);

  useAsyncEffect(async () => {
    const startDate = window.prompt('Beginning date:');
    if (!startDate) {
      window.history.back();
      return;
    }
    if (!isValidDate(startDate)) {
      window.alert('Invalid date');
      return;
    }
    const endDate = window.prompt('Ending date:');
    if (!endDate) {
      window.history.back();
      return;
    }
    if (!isValidDate(endDate)) {
      window.alert('Invalid date');
      return;
    }

    setData(await Api.getW2GReportData(startDate, endDate));
  }, []);

  if (!data || !user) {
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <W2gReport date={new Date()} storeId={user.storeId} data={data} />
    </Page>
  );
};

export default styled(withAuth(W2gReportPage))``;
