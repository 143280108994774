import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { PageComponent } from '../components/Page';
import FormBox from '../components/FormBox';
import Input from '../components/Input';
import Row from '../components/Row';
import BlockButton from '../components/BlockButton';
import isValidDate from '../utils/isValidDate';
import isValidNumber from '../utils/isValidNumber';
import InputRow from '../components/InputRow';
import Page from '../components/Page';
import Api from '../api/Api';
import getErrorMessage from '../utils/getErrorMessage';

const DeleteShift: PageComponent = props => {
  const [submitting, setSubmitting] = useState(false);
  const [shiftNumber, setShiftNumber] = useState('');
  const [shiftDate, setShiftDate] = useState('');

  async function handleDeleteClick() {
    try {
      setSubmitting(true);
      if (!isValidDate(shiftDate)) {
        window.alert('Invalid shift date.');
        return;
      }
      if (!isValidNumber(shiftNumber)) {
        window.alert('Invalid shift number.');
        return;
      }
      if (!window.confirm('Are you sure you want to delete this shift?')) {
        return;
      }

      await Api.deleteShift(shiftDate, shiftNumber);
      window.history.back();
    } catch (error) {
      window.alert(getErrorMessage(error));
    } finally {
      setSubmitting(false);
    }
  }

  function handleCancelClick() {
    window.history.back();
  }

  return (
    <Page {...props}>
      <FormBox title="Delete a shift">
        <InputRow>
          <label>Shift Date</label>
          <Input
            type="date"
            value={shiftDate}
            onValueChange={setShiftDate}
            disabled={submitting}
          />
        </InputRow>
        <InputRow>
          <label>Shift</label>
          <Input
            value={shiftNumber}
            onValueChange={setShiftNumber}
            disabled={submitting}
          />
        </InputRow>
        <ButtonRow>
          <BlockButton onClick={handleCancelClick}>Cancel</BlockButton>
          <BlockButton onClick={handleDeleteClick}>Delete</BlockButton>
        </ButtonRow>
      </FormBox>
    </Page>
  );
};

export default styled(DeleteShift)`
  align-items: center;
  justify-content: center;
`;

const ButtonRow = styled(Row)`
  justify-content: space-between;
`;
