import React from 'react';
import styled from 'styled-components/macro';
import BlockButton from './BlockButton';
import Row from './Row';
import FormBox from './FormBox';
import leaveIconSrc from '../images/leave_icon.png';
import auditIconSrc from '../images/audit_icon.png';

export interface AdministrationFormProps {
  onAddEmployeesClick?: () => any;
  onChangeCigPricesClick?: () => any;
  onNewLottoGameClick?: () => any;
  onAuditErrorCheckClick?: () => any;
  onReturnClick?: () => any;
}

const AdministrationForm: React.FC<AdministrationFormProps> = props => {
  const {
    onAddEmployeesClick,
    onChangeCigPricesClick,
    onNewLottoGameClick,
    onAuditErrorCheckClick,
    onReturnClick,
    ...rest
  } = props;

  return (
    <FormBox {...rest} title="Administration : Form" backPath="/">
      <h1>ADMINISTRATION</h1>
      <Row>
        <ButtonRow>
          <BlockButton
            onClick={onAddEmployeesClick}
            data-testid="addEmployeesButton"
          />
          Add Employees
        </ButtonRow>
      </Row>
      <Row>
        <ButtonRow>
          <BlockButton
            onClick={onNewLottoGameClick}
            data-testid="newLottoButton"
          />
          New Lottery Scratch Ticket Game
        </ButtonRow>
        <ButtonRow>
          <AuditButton
            onClick={onAuditErrorCheckClick}
            data-testid="auditCheckButton"
          />
          Audit Error Check
        </ButtonRow>
      </Row>
      <ButtonRow>
        <LeaveButton onClick={onReturnClick} data-testid="returnButton" />
        Return to Switchboard
      </ButtonRow>
    </FormBox>
  );
};

export default styled(AdministrationForm)`
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 10px;
  }

  > ${Row} {
    margin-bottom: 10px;
  }
`;

const AuditButton = styled(BlockButton)`
  background-image: url('${auditIconSrc}');
  background-position: center;
  background-size: auto 80%;
  background-repeat: no-repeat;
`;

const LeaveButton = styled(BlockButton)`
  background-image: url('${leaveIconSrc}');
  background-position: center;
  background-size: auto 80%;
  background-repeat: no-repeat;
`;

const ButtonRow = styled(Row)`
  flex: 1;
  align-items: center;

  ${BlockButton} {
    margin-right: 15px;
    width: 50px;
    padding: 0;
    height: 25px;
    min-width: 0;
    min-height: 0;
  }

  &:last-child {
    align-self: center;
  }
`;
