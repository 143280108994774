import { AxiosInstance } from 'axios';

export async function getCigOverShortData(
  axios: AxiosInstance,
  startDate: string,
  endDate: string,
) {
  const response = await axios.get(`/cig-over-short/${startDate}/${endDate}`);
  return response.data.cigOverShortData;
}
