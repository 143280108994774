import { AxiosInstance } from 'axios';

export interface BrandInventoryRow {
  brandId: number;
  packCount: number;
  cartonCount: number;
  brandName: string;
  firstProductName: string;
  isPromo: boolean;
}

export interface ActivePromo {
  promoName: string;
  brandId: number;
  price: number;
}

export async function getBrandInventoryData(
  axios: AxiosInstance,
  eopDate: Date | string,
): Promise<BrandInventoryRow[]> {
  const response = await axios.get(
    `/brandInventory/${new Date(eopDate).toISOString()}`,
  );
  return response.data.brandInventoryData;
}

export async function getActivePromos(
  axios: AxiosInstance,
): Promise<ActivePromo[]> {
  const response = await axios.get('/activePromos');
  return response.data.activePromos;
}

export async function insertIntoEopTable(
  axios: AxiosInstance,
  {
    brandId,
    brandCount,
    brandCartonCount,
    eopDate,
  }: {
    brandId: number;
    brandCount: number;
    brandCartonCount: number;
    eopDate: Date | string;
  },
): Promise<void> {
  await axios.post('/insertIntoEopTable', {
    brandId,
    brandCount,
    brandCartonCount,
    eopDate: new Date(eopDate).toISOString(),
  });
}

export async function insertIntoEopTableOther(
  axios: AxiosInstance,
  props: {
    brandId: number;
    brandCount: number;
    brandCartonCount: number;
    eopDate: Date | string;
  },
): Promise<void> {
  await axios.post('/insertIntoEopTableOther', {
    ...props,
    eopDate: new Date(props.eopDate).toISOString(),
  });
}

export async function getBrandInventoryTotals(
  axios: AxiosInstance,
  {
    eopDate,
    bluPackCount,
  }: {
    eopDate: Date | string;
    bluPackCount: number;
  },
): Promise<{
  closingTotal: number;
  closingBluTotal: number;
  grandTotal: number;
}> {
  const response = await axios.post('/getBrandInventoryTotals', {
    eopDate: new Date(eopDate).toISOString(),
    bluPackCount,
  });
  return response.data;
}
