import styled from 'styled-components/macro';
import BlockButton from './BlockButton';

/**
 * Same as BlockButton, except BlockLink has 'a' as the tag instead of 'button'.
 */
const BlockLink = styled(
  BlockButton as 'a', // Allows the component to receive anchor props like 'href', 'target'
).attrs({ as: 'a' })``;

export default BlockLink;
