import React from 'react';
import styled from 'styled-components/macro';
import Table from './Table';
import DateText, { DateDisplayFormat } from './DateText';
import Row from './Row';
import FormBox from './FormBox';
import W2G from '../models/W2G';
import formatIsoDate from '../utils/formatISODate';

export interface W2gReportWinner {
  id: number;
  date: Date;
  name: string;
  shiftNumber: number;
  grossWinnings: number;
  federalIncomeTax: number;
  stateIncomeTax: number;
}

export interface W2gReportProps {
  storeId: number;
  date: Date;
  data: W2G[];
}

const W2gReport: React.FC<W2gReportProps> = props => {
  const { data, storeId, date, ...rest } = props;
  const totalFederalTax = data.reduce(
    (total, row) => total + (row.federalIncomeTax || 0),
    0,
  );
  const totalStateTax = data.reduce(
    (total, row) => total + (row.stateIncomeTax || 0),
    0,
  );

  return (
    <FormBox {...rest} title="W-2G query">
      <ReportTitle>W-2G Report</ReportTitle>
      <StoreIdLabel>
        StoreID: <StoreId>{storeId}</StoreId>
      </StoreIdLabel>
      <Table
        headers={[
          'Date',
          'Shift',
          'Winners Name',
          'Gross Winnings',
          'Federal Income Tax',
          'State Income Tax',
        ]}
      >
        {data.map(row => (
          <tr key={row.id}>
            <Cell>{row.date && formatIsoDate(row.date)}</Cell>
            <Cell>{row.shift}</Cell>
            <Cell>{row.winnersName}</Cell>
            <Cell>${Number(row.grossWinnings).toFixed(2)}</Cell>
            <Cell>{row.federalIncomeTax}</Cell>
            <Cell>{row.stateIncomeTax}</Cell>
          </tr>
        ))}
        <tr>
          <HiddenCell />
          <HiddenCell />
          <HiddenCell />
          <Cell>
            <b>Sum</b>
          </Cell>
          <Cell>{totalFederalTax}</Cell>
          <Cell>{totalStateTax}</Cell>
        </tr>
      </Table>
      <Footer>
        <DateText format={DateDisplayFormat.Long} date={date} />
      </Footer>
    </FormBox>
  );
};

export default styled(W2gReport)`
  background: #fff;
  width: 800px;
  font-size: 12px;
  margin: auto;
  padding: 50px 0 50px 50px;
  min-height: 600px;

  h1 {
    text-align: center;
    margin-bottom: 4px;
  }

  ${Table} {
    width: 600px;
    border-collapse: collapse;

    th {
      border-bottom: 1px solid black;
      border-top: 1px solid black;
      padding: 4px 0 8px 0;
    }

    td {
      border: none;
      padding: 3px 0 6px;
    }

    tr:nth-child(1) td {
      padding-top: 6px;
    }

    tr:last-child td {
      border-top: 1px solid black;
    }
  }
`;

const StoreIdLabel = styled('h2')`
  margin-bottom: 7px;
  font-size: 13px;
`;

const StoreId = styled('span')`
  font-style: normal;
`;

const ReportTitle = styled('h1')`
  border-top: 3px double black;
  border-bottom: 3px double black;
  padding-right: 40px;
  padding: 6px 0 12px;
  margin-top: 20px;
  font-size: 32px;
  font-weight: normal;
  text-align: center;
  width: 600px;
`;

const Footer = styled(Row)`
  border-top: 3px double black;
  width: 600px;
  padding-top: 20px;
  margin-top: auto;
  justify-content: space-between;
`;

const Cell = styled('td')`
  background-color: white;
  text-align: center;
`;

const HiddenCell = styled('td')`
  background-color: transparent;
`;
