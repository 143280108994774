import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import FormBox from './FormBox';
import Row from './Row';
import Column from './Column';
import NumberInput from './NumberInput';
import Input from './Input';
import { dateToStringInputValue } from '../utils/dateToInputValue';
import BlockButton from './BlockButton';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import DailyCheckRegister from '../models/DailyCheckRegister';
import noop from '../utils/noop';

export interface Entry {
  beginningChecks: number | null;
  writtenChecks: number | null;
  addedChecks: number | null;
  endingChecks: number | null;
  id: number;
}

export interface Props {
  storeId: number;
  shiftNumber: number;
  shiftDate: string;
  employeeId: string;
  readOnly: boolean;
  onSubmit?: (data: DailyCheckRegister) => any;
  onCancel?: () => any;
}

interface EntryProps {
  index: number;
  entry: Entry;
  readOnly: boolean;
  onEntryChanged?: (entry: Entry) => any;
}

const CheckRegisterEntry: FC<EntryProps> = props => {
  const { index, entry, readOnly, onEntryChanged = noop } = props;
  const {
    id,
    beginningChecks,
    writtenChecks,
    addedChecks,
    endingChecks,
  } = entry;

  function onWrittenChecksChanged(value: number) {
    onEntryChanged({ ...entry, writtenChecks: value });
  }

  function onAddedChecksChanged(value: number) {
    onEntryChanged({ ...entry, addedChecks: value });
  }

  return (
    <Entry>
      <NumberInput
        readOnly={readOnly}
        value={beginningChecks !== null ? beginningChecks : ''}
        onValueChange={value =>
          onEntryChanged({ ...entry, beginningChecks: value })
        }
        min={0}
        tabIndex={index + 1}
        data-testid={`beginning-checks-${id}`}
      />
      <NumberInput
        readOnly={readOnly}
        value={writtenChecks !== null ? writtenChecks : ''}
        onValueChange={value => onWrittenChecksChanged(value)}
        min={0}
        tabIndex={11 + index}
        data-testid={`written-checks-${id}`}
      />
      <NumberInput
        readOnly={readOnly}
        value={addedChecks !== null ? addedChecks : ''}
        onValueChange={value => onAddedChecksChanged(value)}
        min={0}
        tabIndex={21 + index}
        data-testid={`added-checks-${id}`}
      />
      <NumberInput
        readOnly={readOnly}
        value={endingChecks !== null ? endingChecks : ''}
        onValueChange={value =>
          onEntryChanged({ ...entry, endingChecks: value })
        }
        data-testid={`ending-checks-${id}`}
        tabIndex={31 + index}
        min={0}
      />
    </Entry>
  );
};

const DailyCheckRegisterForm: FC<Props> = props => {
  const [data, setData] = useState<DailyCheckRegister | null>(null);
  const {
    employeeId,
    storeId,
    shiftNumber,
    shiftDate,
    readOnly,
    onSubmit,
    onCancel,
    ...rest
  } = props;

  async function handleCloseClick() {
    if (!data) {
      return;
    }

    const beginningCheckCounts = [
      data.checkBeginning1,
      data.checkBeginning2,
      data.checkBeginning3,
      data.checkBeginning4,
      data.checkBeginning5,
      data.checkBeginning6,
      data.checkBeginning7,
      data.checkBeginning8,
      data.checkBeginning9,
      data.checkBeginning10,
    ];

    const endingCheckCounts = [
      data.checkEnding1,
      data.checkEnding2,
      data.checkEnding3,
      data.checkEnding4,
      data.checkEnding5,
      data.checkEnding6,
      data.checkEnding7,
      data.checkEnding8,
      data.checkEnding9,
      data.checkEnding10,
    ];

    const addedCheckCounts = [
      data.checkAdded1,
      data.checkAdded2,
      data.checkAdded3,
      data.checkAdded4,
      data.checkAdded5,
      data.checkAdded6,
      data.checkAdded7,
      data.checkAdded8,
      data.checkAdded9,
      data.checkAdded10,
    ];

    const writtenCheckCounts = [
      data.checkWritten1,
      data.checkWritten2,
      data.checkWritten3,
      data.checkWritten4,
      data.checkWritten5,
      data.checkWritten6,
      data.checkWritten7,
      data.checkWritten8,
      data.checkWritten9,
      data.checkWritten10,
    ];

    let beginningCheckCount = 0;
    let endingCheckCount = 0;
    let writtenCheckCount = 0;
    let addedCheckCount = 0;

    for (const count of beginningCheckCounts) {
      if (count && count > 0) {
        beginningCheckCount += 1;
      } else {
        break;
      }
    }

    for (const count of addedCheckCounts) {
      if (count && count > 0) {
        addedCheckCount += 1;
      } else {
        break;
      }
    }

    for (const count of writtenCheckCounts) {
      if (count && count > 0) {
        writtenCheckCount += 1;
      } else {
        break;
      }
    }

    for (const count of endingCheckCounts) {
      if (count && count > 0) {
        endingCheckCount += 1;
      } else {
        break;
      }
    }

    const shiftChecks = await Api.getChecksFromShift(shiftDate, shiftNumber);
    const prior = await Api.getPriorCheckRegisterByShift(
      shiftDate,
      shiftNumber,
    );

    if (prior && beginningCheckCount !== prior.endingCheckCount) {
      window.alert(
        "Your beginning check count does not match the last shift's ending check count!",
      );
    }

    if (shiftChecks.length !== writtenCheckCount) {
      window.alert(
        'The written check count does not match the Check Master count for this shift!',
      );
    }

    const dataWithTotals = {
      ...data,
      beginningCheckCount,
      endingCheckCount,
      writtenCheckCount,
      addedCheckCount,
    };

    await Api.saveCheckRegister(dataWithTotals);

    if (onSubmit) {
      onSubmit(dataWithTotals);
    }
  }

  useAsyncEffect(async () => {
    const fetchedData = await Api.getCheckRegisterByShift(
      shiftDate,
      shiftNumber,
    );

    if (fetchedData) {
      setData(fetchedData);
    } else {
      setData({
        employeeId,
        storeId,
        date: shiftDate,
        shift: shiftNumber,
        addedCheckCount: 0,
        beginningCheckCount: 0,
        checkAdded1: 0,
        checkAdded10: 0,
        checkAdded2: 0,
        checkAdded3: 0,
        checkAdded4: 0,
        checkAdded5: 0,
        checkAdded6: 0,
        checkAdded7: 0,
        checkAdded8: 0,
        checkAdded9: 0,
        checkBeginning1: 0,
        checkBeginning10: 0,
        checkBeginning2: 0,
        checkBeginning3: 0,
        checkBeginning4: 0,
        checkBeginning5: 0,
        checkBeginning6: 0,
        checkBeginning7: 0,
        checkBeginning8: 0,
        checkBeginning9: 0,
        checkEnding1: 0,
        checkEnding10: 0,
        checkEnding2: 0,
        checkEnding3: 0,
        checkEnding4: 0,
        checkEnding5: 0,
        checkEnding6: 0,
        checkEnding7: 0,
        checkEnding8: 0,
        checkEnding9: 0,
        checkWritten1: 0,
        checkWritten10: 0,
        checkWritten2: 0,
        checkWritten3: 0,
        checkWritten4: 0,
        checkWritten5: 0,
        checkWritten6: 0,
        checkWritten7: 0,
        checkWritten8: 0,
        checkWritten9: 0,
        endingCheckCount: 0,
        writtenCheckCount: 0,
      });
    }
  }, []);

  if (!data) {
    // Loading...
    return null;
  }

  return (
    <FormBox
      {...rest}
      title="Daily Check Register"
      onCloseClick={handleCloseClick}
    >
      <FormBody>
        <Row>
          <FormInfo>
            <label>STORE ID:</label>
            <Input value={storeId} disabled />
          </FormInfo>
          <FormInfo>
            <label>SHIFT:</label>
            <Input value={shiftNumber} disabled />
          </FormInfo>
          <FormInfo>
            <label>DATE:</label>
            <EntryDate value={dateToStringInputValue(shiftDate)} disabled />
          </FormInfo>
        </Row>
        <HeaderRow>
          <label>BEGINNING CHECKS</label>
          <label>WRITTEN CHECKS</label>
          <label>ADDED CHECKS</label>
          <label>ENDING CHECKS</label>
        </HeaderRow>
        <Entries>
          <CheckRegisterEntry
            index={0}
            readOnly={readOnly}
            entry={{
              id: 1,
              beginningChecks: data.checkBeginning1,
              writtenChecks: data.checkWritten1,
              addedChecks: data.checkAdded1,
              endingChecks: data.checkEnding1,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning1: entry.beginningChecks,
                checkWritten1: entry.writtenChecks,
                checkAdded1: entry.addedChecks,
                checkEnding1: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={1}
            readOnly={readOnly}
            entry={{
              id: 2,
              beginningChecks: data.checkBeginning2,
              writtenChecks: data.checkWritten2,
              addedChecks: data.checkAdded2,
              endingChecks: data.checkEnding2,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning2: entry.beginningChecks,
                checkWritten2: entry.writtenChecks,
                checkAdded2: entry.addedChecks,
                checkEnding2: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={2}
            readOnly={readOnly}
            entry={{
              id: 3,
              beginningChecks: data.checkBeginning3,
              writtenChecks: data.checkWritten3,
              addedChecks: data.checkAdded3,
              endingChecks: data.checkEnding3,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning3: entry.beginningChecks,
                checkWritten3: entry.writtenChecks,
                checkAdded3: entry.addedChecks,
                checkEnding3: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={3}
            readOnly={readOnly}
            entry={{
              id: 4,
              beginningChecks: data.checkBeginning4,
              writtenChecks: data.checkWritten4,
              addedChecks: data.checkAdded4,
              endingChecks: data.checkEnding4,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning4: entry.beginningChecks,
                checkWritten4: entry.writtenChecks,
                checkAdded4: entry.addedChecks,
                checkEnding4: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={4}
            readOnly={readOnly}
            entry={{
              id: 5,
              beginningChecks: data.checkBeginning5,
              writtenChecks: data.checkWritten5,
              addedChecks: data.checkAdded5,
              endingChecks: data.checkEnding5,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning5: entry.beginningChecks,
                checkWritten5: entry.writtenChecks,
                checkAdded5: entry.addedChecks,
                checkEnding5: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={5}
            readOnly={readOnly}
            entry={{
              id: 6,
              beginningChecks: data.checkBeginning6,
              writtenChecks: data.checkWritten6,
              addedChecks: data.checkAdded6,
              endingChecks: data.checkEnding6,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning6: entry.beginningChecks,
                checkWritten6: entry.writtenChecks,
                checkAdded6: entry.addedChecks,
                checkEnding6: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={6}
            readOnly={readOnly}
            entry={{
              id: 7,
              beginningChecks: data.checkBeginning7,
              writtenChecks: data.checkWritten7,
              addedChecks: data.checkAdded7,
              endingChecks: data.checkEnding7,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning7: entry.beginningChecks,
                checkWritten7: entry.writtenChecks,
                checkAdded7: entry.addedChecks,
                checkEnding7: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={7}
            readOnly={readOnly}
            entry={{
              id: 8,
              beginningChecks: data.checkBeginning8,
              writtenChecks: data.checkWritten8,
              addedChecks: data.checkAdded8,
              endingChecks: data.checkEnding8,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning8: entry.beginningChecks,
                checkWritten8: entry.writtenChecks,
                checkAdded8: entry.addedChecks,
                checkEnding8: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={8}
            readOnly={readOnly}
            entry={{
              id: 9,
              beginningChecks: data.checkBeginning9,
              writtenChecks: data.checkWritten9,
              addedChecks: data.checkAdded9,
              endingChecks: data.checkEnding9,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning9: entry.beginningChecks,
                checkWritten9: entry.writtenChecks,
                checkAdded9: entry.addedChecks,
                checkEnding9: entry.endingChecks,
              })
            }
          />
          <CheckRegisterEntry
            index={9}
            readOnly={readOnly}
            entry={{
              id: 10,
              beginningChecks: data.checkBeginning10,
              writtenChecks: data.checkWritten10,
              addedChecks: data.checkAdded10,
              endingChecks: data.checkEnding10,
            }}
            onEntryChanged={entry =>
              setData({
                ...data,
                checkBeginning10: entry.beginningChecks,
                checkWritten10: entry.writtenChecks,
                checkAdded10: entry.addedChecks,
                checkEnding10: entry.endingChecks,
              })
            }
          />
        </Entries>
        <Buttons>
          <BlockButton
            onClick={handleCloseClick}
            data-testid="daily-check-register-close-button"
          >
            Close
          </BlockButton>
          <BlockButton onClick={onCancel}>Cancel</BlockButton>
        </Buttons>
      </FormBody>
    </FormBox>
  );
};

export default styled(DailyCheckRegisterForm)`
  width: 500px;
  align-self: center;
  padding-top: 30px;
  max-height: 600px;
  overflow-y: auto;
`;

const FormBody = styled(Column)`
  height: 100%;
  margin-left: 5px;
`;

const HeaderRow = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  > label {
    text-align: center;
    margin: auto auto auto auto;
    width: 100px;
  }
`;

const FormInfo = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 3px;
  > label {
    margin: auto 5px auto 20px;
  }

  > input {
    height: 25px;
    width: 40px;
    padding: 0;
    text-align: right;
  }
`;

const EntryDate = styled(Input)`
  width: 100px !important;
`;

const Entries = styled(Column)`
  align-self: flex-start;
  width: 100%;
`;

const Entry = styled(Row)`
  ${NumberInput} {
    width: 100px;
    margin: auto auto 3px auto;
    text-align: right;
    height: 25px;
    padding: 0;
  }
`;

const Buttons = styled(Row)`
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  ${BlockButton} {
    width: 100px;
    min-width: 100px;
    height: 25px;
    min-height: 25px;
    padding: 0;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 200px;
  }
`;
