import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import ByBrandForm from '../components/ByBrandForm';
import withAuth from '../components/withAuth';
import { navigate } from '@reach/router';
import BrandInventoryForm from '../components/BrandInventoryForm';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import { BrandInventoryRow, ActivePromo } from '../api/BrandInventoryEndpoints';
import replaceWhere from '../utils/replaceWhere';
import SpinnerOverlay from '../components/SpinnerOverlay';

const BrandInventory: PageComponent<{ inventoryDate?: string }> = props => {
  const [brandInventoryData, setBrandInventoryData] = useState<
    BrandInventoryRow[] | null
  >(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [closingTotal, setClosingTotal] = useState(0);
  const [closingBluTotal, setClosingBluTotal] = useState(0);
  const [activePromos, setActivePromos] = useState<ActivePromo[]>([]);
  const { user, inventoryDate, ...rest } = props;

  function handleDateFormSubmit(date: Date) {
    date = new Date(date);
    date.setHours(0, 0, 0, 0);
    navigate(`/brand-inventory/${date.toISOString()}`);
  }

  async function updateTotals(value: BrandInventoryRow[]) {
    if (!inventoryDate) {
      return;
    }
    const bluPack = value.find(row => row.brandId === 203);
    const totals = await Api.getBrandInventoryTotals({
      eopDate: inventoryDate,
      bluPackCount: bluPack ? bluPack.packCount : 0,
    });
    setGrandTotal(totals.grandTotal);
    setClosingTotal(totals.closingTotal);
    setClosingBluTotal(totals.closingBluTotal);
  }

  async function handleProductChange(
    brandId: number,
    packCount: number,
    cartonCount: number,
  ) {
    if (!brandInventoryData || !inventoryDate) {
      return;
    }
    const existingProduct = brandInventoryData.find(
      product => product.brandId === brandId,
    );
    const promo = activePromos.find(p => p.brandId === brandId);
    let newValue;

    if (existingProduct) {
      newValue = replaceWhere(
        brandInventoryData,
        value => value.brandId === brandId,
        value => ({ ...value, packCount, cartonCount }),
      );
    } else {
      newValue = [
        ...brandInventoryData,
        {
          brandId,
          packCount,
          cartonCount,
          brandName: promo ? promo.promoName : '',
          isPromo: false,
          firstProductName: '',
        },
      ];
    }
    setBrandInventoryData(newValue);

    await Api.insertIntoEopTable({
      brandId,
      brandCount: packCount,
      brandCartonCount: cartonCount,
      eopDate: inventoryDate,
    });

    await updateTotals(newValue);
  }

  async function handleOtherProductChange(
    brandId: number,
    brandCount: number,
    brandCartonCount: number,
  ) {
    if (!brandInventoryData || !inventoryDate) {
      return;
    }
    const existingProduct = brandInventoryData.find(
      product => product.brandId === brandId,
    );
    let newValue;

    if (existingProduct) {
      newValue = replaceWhere(
        brandInventoryData,
        value => value === existingProduct,
        value => ({
          ...value,
          packCount: brandCount,
          cartonCount: brandCartonCount,
        }),
      );
    } else {
      newValue = [
        ...brandInventoryData,
        {
          brandId,
          packCount: brandCount,
          cartonCount: brandCartonCount,
          brandName: '',
          isPromo: false,
          firstProductName: '',
        },
      ];
    }
    setBrandInventoryData(newValue);

    await Api.insertIntoEopTableOther({
      brandId,
      brandCount,
      brandCartonCount,
      eopDate: inventoryDate,
    });
    await updateTotals(newValue);
  }

  function handleSubmit() {
    navigate('/');
  }

  useAsyncEffect(async () => {
    setActivePromos(await Api.getActivePromos());
  }, []);

  useAsyncEffect(async () => {
    if (!inventoryDate) {
      return;
    }
    const values = await Api.getBrandInventoryData(inventoryDate);
    setBrandInventoryData(values);
    await updateTotals(values);
  }, [inventoryDate]);

  if (!inventoryDate) {
    return (
      <Page {...rest}>
        <ByBrandForm onSubmit={handleDateFormSubmit} />
      </Page>
    );
  }

  if (!user || !brandInventoryData) {
    return <SpinnerOverlay />;
  }

  return (
    <Page {...rest}>
      <BrandInventoryForm
        storeId={user.storeId}
        eopDate={inventoryDate}
        grandTotal={grandTotal}
        closingTotal={closingTotal}
        closingBluTotal={closingBluTotal}
        activePromos={activePromos}
        value={brandInventoryData}
        onProductChange={handleProductChange}
        onOtherProductChange={handleOtherProductChange}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default styled(withAuth(BrandInventory))`
  justify-content: center;
  align-items: center;
`;
