import React, { useState } from 'react';
import styled from 'styled-components/macro';
import DateInput from './DateInput';
import NumberInput from './NumberInput';
import replaceWhere from '../utils/replaceWhere';
import BlockButton from './BlockButton';
import Row from './Row';
import Table from './Table';
import FormBox from './FormBox';
import Column from './Column';
import ScratchTicketBackStock from '../models/ScratchTicketBackStock';
import Api from '../api/Api';
import useAsyncEffect from '../utils/useAsyncEffect';

interface Props {
  inventoryDate: string;
}

const ScratchTicketBackStockForm: React.FC<Props> = props => {
  const { inventoryDate, ...rest } = props;
  const [backstocks, setBackstocks] = useState<ScratchTicketBackStock[]>([]);

  useAsyncEffect(async () => {
    const rows = await Api.getScratchTicketBackstocks(inventoryDate);
    setBackstocks(rows);
  }, []);

  async function handleBackstockChange(backstock: ScratchTicketBackStock) {
    setBackstocks(
      replaceWhere(
        backstocks,
        other => other.id === backstock.id,
        () => backstock,
      ),
    );
    await Api.updateScratchTicketBackstock(backstock);
  }

  return (
    <FormBox {...rest} title="ScratchTicketBackStockForm : Form ">
      <TableWrapper>
        <TableTitle>Back Stock</TableTitle>
        <BackStockTable headers={['Date', 'Game Number', 'Pack Number']}>
          {backstocks
            .filter(backstock => !backstock.isReturn)
            .map(backstock => (
              <tr key={backstock.id}>
                <td>
                  <DateInput readOnly value={backstock.invDate || ''} />
                </td>
                <td>
                  <NumberInput
                    min={0}
                    value={backstock.gameNumber || 0}
                    onValueChange={gameNumber =>
                      handleBackstockChange({
                        ...backstock,
                        gameNumber,
                      })
                    }
                  />
                </td>
                <td>
                  <NumberInput
                    min={0}
                    value={backstock.packNumber || 0}
                    onValueChange={packNumber =>
                      handleBackstockChange({
                        ...backstock,
                        packNumber,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
        </BackStockTable>
      </TableWrapper>
      <TableWrapper>
        <TableTitle>Returns</TableTitle>
        <ReturnsTable
          headers={['Date', 'Game Number', 'Pack Number', 'Ending Inventory']}
        >
          {backstocks
            .filter(backstock => backstock.isReturn)
            .map(backstock => (
              <tr key={backstock.id}>
                <td>
                  <DateInput readOnly value={backstock.invDate || ''} />
                </td>
                <td>
                  <NumberInput
                    min={0}
                    value={backstock.gameNumber || 0}
                    onValueChange={gameNumber =>
                      handleBackstockChange({ ...backstock, gameNumber })
                    }
                  />
                </td>
                <td>
                  <NumberInput
                    min={0}
                    value={backstock.packNumber || 0}
                    onValueChange={packNumber =>
                      handleBackstockChange({ ...backstock, packNumber })
                    }
                  />
                </td>
                <td>
                  <NumberInput
                    min={0}
                    value={backstock.endingInventory || 0}
                    onValueChange={endingInventory =>
                      handleBackstockChange({ ...backstock, endingInventory })
                    }
                  />
                </td>
              </tr>
            ))}
        </ReturnsTable>
      </TableWrapper>
      <ButtonRow>
        <BlockButton
          onClick={() => window.history.back()}
          data-testid="doneButton"
        >
          Done
        </BlockButton>
      </ButtonRow>
    </FormBox>
  );
};

export default styled(ScratchTicketBackStockForm)`
  width: 800px;
  margin: auto;
  ${NumberInput},
  ${DateInput} {
    width: 100%;
  }
`;

const TableTitle = styled('h2')`
  font-size: 24px;
  font-weight: normal;
  display: inline-block;
  margin-bottom: 15px;
`;

const TableWrapper = styled(Column)`
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  margin-bottom: 20px;
  align-self: flex-start;
  th {
    font-weight: normal;
    text-align: left;
    padding: 2px;
  }

  td,
  th {
    border-bottom: 1px solid black;
    padding: 2px;
  }

  th:nth-child(1) {
    width: 105px;
  }

  th:nth-child(2) {
    width: 100px;
  }

  th:last-child {
    width: 225px;
  }

  td:last-child {
    input {
      width: 110px;
    }
  }

  input {
    text-align: right;
    padding: 3px 5px;
    &[type='date'] {
      padding-right: 30px;
    }
  }
`;

const BackStockTable = styled(Table)`
  border-collapse: collapse;
  width: 600px;
`;

const ReturnsTable = styled(Table)`
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 700px;
  th:nth-child(3) {
    width: 100px;
  }

  th:last-child {
    width: 180px;
  }
`;

const ButtonRow = styled(Row)`
  justify-content: flex-start;
  margin-bottom: 15px;
`;
