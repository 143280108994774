import { AxiosInstance } from 'axios';
import ShiftMaster from '../models/ShiftMaster';

export async function createShift(
  axios: AxiosInstance,
  fields: {
    shiftDate: string;
    shiftNumber: number;
    beginningTill: number;
    beginningChange: number;
    cashDispensed: number;
  },
): Promise<{ shift: ShiftMaster; warnings: string[] }> {
  const response = await axios.post('/shift', fields);
  const { shift, warnings } = response.data;
  return { shift, warnings };
}

export async function getShiftsBetween(
  axios: AxiosInstance,
  startDate: Date,
  endDate: Date,
): Promise<ShiftMaster[]> {
  const response = await axios.get(
    `/shifts/between/${startDate.toISOString()}/${endDate.toISOString()}`,
  );
  return response.data.shifts;
}

export async function getShiftToSignoff(
  axios: AxiosInstance,
): Promise<ShiftMaster | null> {
  const response = await axios.get('/shift/signoff');
  return response.data.shift;
}

export async function getShift(
  axios: AxiosInstance,
  shiftDate: string,
  shiftNumber: number,
): Promise<ShiftMaster | null> {
  const { data } = await axios.get(`/shift/${shiftDate}/${shiftNumber}`);
  return data.shiftMaster;
}

export async function deleteShift(
  axios: AxiosInstance,
  shiftDate: string,
  shiftNumber: number | string,
) {
  await axios.delete(
    `/shift/${new Date(shiftDate).toISOString()}/${shiftNumber}`,
  );
}

export async function getDateShifts(
  axios: AxiosInstance,
  shiftDate: string,
): Promise<ShiftMaster[]> {
  const response = await axios.get(`/shift/${shiftDate}`);
  return response.data.shifts;
}

export async function getOverShortShiftsBetween(
  axios: AxiosInstance,
  startDate: string,
  endDate: string,
): Promise<ShiftMaster[]> {
  const response = await axios.get(`/shifts/overshort/${startDate}/${endDate}`);
  return response.data.shifts;
}
