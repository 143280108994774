import { AxiosInstance } from 'axios';
import CheckMaster from '../models/CheckMaster';

export async function getChecks(axios: AxiosInstance): Promise<CheckMaster[]> {
  const response = await axios.get('/checks');
  return response.data.checks;
}

export async function getCheckTotals(
  axios: AxiosInstance,
  shiftDate: string,
  shiftNumber: number | string,
): Promise<{
  nonPrizeTotal: number;
  winnerTotal: number;
}> {
  const response = await axios.get(`/check-total/${shiftDate}/${shiftNumber}`);
  return response.data.checkTotals;
}

export async function saveChecks(
  axios: AxiosInstance,
  checks: CheckMaster[],
): Promise<void> {
  await axios.post('/checks', { checks });
}

export async function createCheckMaster(
  axios: AxiosInstance,
  checkMaster: CheckMaster,
): Promise<void> {
  await axios.post('/checkmaster', checkMaster);
}

export async function updateCheckMaster(
  axios: AxiosInstance,
  checkNumber: number,
  checkMaster: CheckMaster,
): Promise<void> {
  await axios.put(`/checkmaster/${checkNumber}`, checkMaster);
}

export async function getChecksFromShift(
  axios: AxiosInstance,
  shiftDate: Date | string,
  shiftNumber: number,
): Promise<CheckMaster[]> {
  const response = await axios.get(
    `/checks/${new Date(shiftDate).toISOString()}/${shiftNumber}`,
  );
  return response.data.checks;
}
