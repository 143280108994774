import React, { useState } from 'react';
import styled from 'styled-components/macro';
import InputRow from './InputRow';
import FormBox from './FormBox';
import noop from '../utils/noop';
import Row from './Row';
import Column from './Column';
import Input from './Input';
import BlockButton from './BlockButton';
import { shiftMasterFromDraft } from '../models/ShiftMaster';
import useAsyncEffect from '../utils/useAsyncEffect';
import ShiftMaster, { ShiftMasterDraft } from '../models/ShiftMaster';
import ItsReport, {
  ItsReportDraft,
  itsReportFromDraft,
} from '../models/ItsReport';
import Api from '../api/Api';
import usePrintLayout from '../utils/usePrintLayout';

export interface LotteryReportsFormProps {
  shiftDate: string;
  shiftNumber: number;
  storeId: number;
  shiftMaster: ShiftMasterDraft;
  readOnly: boolean;
  onSubmit?: (itsReport: ItsReport, shiftMaster: ShiftMaster) => any;
  onCancel?: () => any;
}

const LotteryReportsForm: React.FC<LotteryReportsFormProps> = props => {
  usePrintLayout('landscape');
  const [itsReport, setItsReport] = useState<ItsReportDraft | null>(null);
  const [shiftMaster, setShiftMaster] = useState<ShiftMasterDraft>(
    props.shiftMaster,
  );
  const {
    shiftDate,
    shiftNumber,
    storeId,
    readOnly,
    onSubmit = noop,
    onCancel = noop,
    ...rest
  } = props;

  useAsyncEffect(async () => {
    const fetched = await Api.getLotteryReportData(shiftDate, shiftNumber);
    setItsReport(
      fetched || {
        shiftDate,
        storeId,
        shift: shiftNumber,
        activated: null,
        activatedDetails: null,
        onLinePrizes: null,
        onLineSales: null,
        soffValidation: null,
        vpValidation: null,
        cancel: 0,
        discounts: 0,
      },
    );
  }, [shiftDate, shiftNumber]);

  if (!itsReport) {
    // Loading
    return null;
  }

  const vpSales = shiftMaster.vpsales;
  const vpValidation =
    itsReport && itsReport.vpValidation !== null ? itsReport.vpValidation : 0;
  const onLinePrizes =
    itsReport && itsReport.onLinePrizes !== null ? itsReport.onLinePrizes : 0;
  const onLineSales =
    itsReport && itsReport.onLineSales !== null ? itsReport.onLineSales : 0;
  const activated =
    itsReport && itsReport.activated !== null ? itsReport.activated : 0;
  const soffValidation =
    itsReport && itsReport.soffValidation !== null
      ? itsReport.soffValidation
      : 0;
  const cancel = itsReport && itsReport.cancel !== null ? itsReport.cancel : 0;
  const discounts =
    itsReport && itsReport.discounts !== null ? itsReport.discounts : 0;

  function handleItsReportChange<K extends keyof ItsReportDraft>(
    key: K,
    keyValue: ItsReportDraft[K],
  ) {
    setItsReport({
      ...itsReport,
      storeId,
      shiftDate,
      vpValidation,
      onLineSales,
      onLinePrizes,
      activated,
      soffValidation,
      discounts,
      cancel,
      shift: shiftNumber,
      activatedDetails: null,
      [key]: keyValue,
    });
  }

  function handleVpValidationChange(newVpValidation: string) {
    handleItsReportChange('vpValidation', newVpValidation);
  }

  function handleVpSalesChange(newVpSales: string) {
    setShiftMaster({
      ...shiftMaster,
      vpsales: newVpSales,
    });
  }

  function handleOnLineSalesChange(newOnLineSales: string) {
    handleItsReportChange('onLineSales', newOnLineSales);
  }

  function handleOnLinePrizesChange(newOnLinePrizes: string) {
    handleItsReportChange('onLinePrizes', newOnLinePrizes);
  }

  function handleActivatedChange(newActivated: string) {
    handleItsReportChange('activated', newActivated);
  }

  function handleCancelChange(newCancel: string) {
    handleItsReportChange('cancel', newCancel.replace(/-/g, ''));
  }

  function handleDiscountsChange(newDiscounts: string) {
    handleItsReportChange('discounts', newDiscounts);
  }

  function handleSoffValidationChange(newSoffValidation: string) {
    handleItsReportChange('soffValidation', newSoffValidation);
  }

  async function handleSubmit() {
    if (!itsReport) {
      return;
    }
    const dateShifts = await Api.getDateShifts(shiftDate);
    const priorShifts = dateShifts.filter(other => other.shift < shiftNumber);

    const priorVpValidation = priorShifts.reduce(
      (total, row) => total + (row.vpValidation || 0),
      0,
    );
    const priorSOffValidation = priorShifts.reduce(
      (total, row) => total + (row.scratchOffValidation || 0),
      0,
    );
    const priorOnlineSales = priorShifts.reduce(
      (total, row) => total + (row.onLineSales || 0),
      0,
    );
    const priorOnlinePrizes = priorShifts.reduce(
      (total, row) => total + (row.onLinePrizes || 0),
      0,
    );
    const priorCancel = priorShifts.reduce(
      (total, row) => total + (row.cancel || 0),
      0,
    );

    const priorDiscounts = priorShifts.reduce(
      (total, row) => total + (row.discounts || 0),
      0,
    );

    const newItsReport = itsReportFromDraft(itsReport);
    const newShiftMaster = shiftMasterFromDraft(shiftMaster);

    newShiftMaster.vpValidation =
      (newItsReport.vpValidation || 0) - priorVpValidation;
    newShiftMaster.onLineSales =
      (newItsReport.onLineSales || 0) - priorOnlineSales;
    newShiftMaster.onLinePrizes =
      (newItsReport.onLinePrizes || 0) - priorOnlinePrizes;
    newShiftMaster.scratchOffValidation =
      (newItsReport.soffValidation || 0) - priorSOffValidation;
    newShiftMaster.cancel = newItsReport.cancel
      ? newItsReport.cancel - priorCancel
      : priorCancel;
    newShiftMaster.discounts = (newItsReport.discounts || 0) - priorDiscounts;

    onSubmit(newItsReport, newShiftMaster);
  }

  return (
    <FormBox
      {...rest}
      title="lottery reports: Form"
      onCloseClick={handleSubmit}
    >
      <Row>
        <Column>
          <h2>DAILY SALES SUMMARY</h2>
          <Row>
            <LineColumn style={{ justifyContent: 'center' }}>
              <InputRow>
                <label>TOTAL:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={soffValidation}
                  onValueChange={handleSoffValidationChange}
                />
              </InputRow>
              <p>(Instant cashes)</p>
            </LineColumn>
            <LineColumn>
              <InputRow>
                <label>SALES:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={onLineSales}
                  onValueChange={handleOnLineSalesChange}
                />
              </InputRow>
              <InputRow>
                <label>CANCEL:</label>
                <Input
                  isMoney
                  min="0"
                  readOnly={readOnly}
                  value={cancel}
                  onValueChange={handleCancelChange}
                />
              </InputRow>
              <InputRow>
                <label>CASHES:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={onLinePrizes}
                  onValueChange={handleOnLinePrizesChange}
                />
              </InputRow>
              <InputRow>
                <label>DISCOUNTS:</label>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={discounts}
                  onValueChange={handleDiscountsChange}
                />
              </InputRow>
            </LineColumn>
          </Row>
        </Column>
        <LineColumn>
          <h2>Current Day Cash Flow</h2>
          <InputRow>
            <label>Total Amt In:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={vpSales}
              onValueChange={handleVpSalesChange}
            />
          </InputRow>
          <InputRow>
            <label>Total Amt Validated:</label>
            <Input
              isMoney
              readOnly={readOnly}
              value={vpValidation}
              onValueChange={handleVpValidationChange}
            />
          </InputRow>
        </LineColumn>
        <Column style={{ justifyContent: 'center' }}>
          <h3>INSTANT TICKETS:</h3>
          <InputRow>
            <label>
              INSTANT
              <br />
              TICKET
              <br />
              SHIPMENT
              <br />
              RECEIVED :
            </label>
            <Input
              isMoney
              readOnly={readOnly}
              value={activated}
              onValueChange={handleActivatedChange}
            />
          </InputRow>
        </Column>
      </Row>
      <ButtonRow>
        <BlockButton onClick={handleSubmit}>Enter</BlockButton>
        <BlockButton onClick={onCancel}>Cancel</BlockButton>
      </ButtonRow>
    </FormBox>
  );
};

export default styled(LotteryReportsForm)`
  width: 1100px;
  margin: auto;

  ${Input} {
    width: 120px;
    max-width: 120px;
  }

  ${InputRow} + ${InputRow} {
    margin-top: 20px;
  }

  h2, h3 {
    margin-bottom: 40px;
  }

  @media print {
    overflow: visible;

    button {
      display: none;
    }
  }
`;

const LineColumn = styled(Column)`
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid black;
`;

const ButtonRow = styled(Row)`
  justify-content: center;
  margin-top: 100px;

  ${BlockButton} + ${BlockButton} {
    margin-left: 50px; 
  }
`;
