import React from 'react';
import styled from 'styled-components';
import FormBox from './FormBox';
import VideoDepositLog from '../models/VideoDepositLog';
import getMonthName from '../utils/getMonthName';
import padZero from '../utils/padZero';

interface Props {
  data: VideoDepositLog[];
}

const AuditSafeLoadReport: React.FC<Props> = props => {
  const { data, ...rest } = props;

  return (
    <FormBox {...rest}>
      <h1>Safe/ATM Load Log</h1>
      <table>
        <thead>
          <tr>
            <th colSpan={7}>
              <UnderlinedText>Safe</UnderlinedText>
            </th>
            <th colSpan={4}>
              <UnderlinedText>ATM</UnderlinedText>
            </th>
          </tr>
          <tr>
            <th>Date by Month</th>
            <th colSpan={2}>Date</th>
            <th>1's Loaded</th>
            <th>5's Loaded</th>
            <th>10's Loaded</th>
            <th>20's Loaded</th>
            <th>Total Loaded Into Safe</th>
            <th>
              Actual Remaining
              <br />
              (Count in Cassette)
            </th>
            <th>
              Actual Rejected
              <br />
              (Count in Rejected)
            </th>
            <th>Dispensed</th>
            <th>Addition Count</th>
          </tr>
        </thead>
        <tbody>
          {data.map((value, i) => {
            const prevRow = data[i - 1];
            const showMonth =
              !prevRow ||
              !prevRow.date ||
              (value.date &&
                new Date(prevRow.date).getMonth() !==
                  new Date(value.date).getMonth());

            return (
              <tr key={value.id}>
                {value.date && showMonth ? (
                  <td>
                    {getMonthName(value.date)}{' '}
                    {new Date(value.date.replace('Z', '')).getFullYear()}
                  </td>
                ) : (
                  <td />
                )}
                <td colSpan={2}>
                  <BoxText>{value.date && formatDate(value.date)}</BoxText>
                </td>
                <td>
                  <BoxText>{value.load100S}</BoxText>
                </td>
                <td>
                  <BoxText>{value.load5S}</BoxText>
                </td>
                <td>
                  <BoxText>{value.load10S}</BoxText>
                </td>
                <td>
                  <BoxText>{value.load20S}</BoxText>
                </td>
                <td>
                  <BoxText>{value.totalLoadedIntoSafeCalc}</BoxText>
                </td>
                <td>
                  <BoxText>0?</BoxText>
                </td>
                <td>
                  <BoxText>0?</BoxText>
                </td>
                <td>
                  <BoxText>0?</BoxText>
                </td>
                <td>
                  <BoxText>0?</BoxText>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot />
      </table>
    </FormBox>
  );
};

function formatDate(date: Date | string) {
  date = new Date(date);
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

export default styled(AuditSafeLoadReport)`
  width: 1100px;
  background-color: white;

  h1 {
    border-top: 3px solid black;
    border-bottom: 3px solid black;
    padding: 10px 0 10px;
  }

  thead {
    text-align: left;
  }

  thead tr:first-child {
    font-size: 24px;
  }

  thead tr:last-child th {
    border-bottom: 3px solid black;
  }

  tbody {
    text-align: center;
  }

  tr > *:nth-child(7) {
    border-right: 1px solid black;
  }

  max-height: 600px;
  overflow: auto;

  @media print {
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
  }
`;

const UnderlinedText = styled('span')`
  border-bottom: 3px solid black;
`;

const BoxText = styled('div')`
  width: 100%;
  height: 100%;
  border: 1px solid black;
`;
