import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import Page, { PageComponent } from '../components/Page';
import VideoDepositLogRmForm from '../components/VideoDepositLogRmForm';
import withAuth from '../components/withAuth';
import VideoDepositLog from '../models/VideoDepositLog';
import Api from '../api/Api';
import SpinnerOverlay from '../components/SpinnerOverlay';
import useAsyncEffect from '../utils/useAsyncEffect';

const VideoDepositRegionalManager: PageComponent = props => {
  const { user } = props;
  const [videoDepositLogs, setVideoDepositLogs] = useState<VideoDepositLog[]>(
    [],
  );
  const [value, setValue] = useState<VideoDepositLog | null>(null);

  async function handleCloseClick(videoDepositLog: VideoDepositLog) {
    if (!user) {
      return;
    }
    const { storeId } = user;
    try {
      setVideoDepositLogs(vdls =>
        vdls.map(vdl =>
          vdl.id === videoDepositLog.id ? videoDepositLog : vdl,
        ),
      );
      await Api.createVideoDepositLogRm({ ...videoDepositLog, storeId });
      navigate('/manager');
    } catch (error) {
      alert(error.message);
    }
  }

  function handlePageChange(videoDepositLogId: number) {
    const videoDepositLog = videoDepositLogs.find(
      vdl => vdl.id === videoDepositLogId,
    );

    if (videoDepositLog) {
      setValue(videoDepositLog);
    }
  }

  useAsyncEffect(async () => {
    if (!user) {
      return;
    }
    const vdls = await Api.getVideoDepositLogRms();
    setVideoDepositLogs(vdls);
    setValue(vdls[0]);
  }, [user]);

  if (!user || !value) {
    // Loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <VideoDepositLogRmForm
        onCloseClick={handleCloseClick}
        storeId={user.storeId}
        employeeName={`${user.firstName || ''} ${user.lastName || ''}`}
        videoDepositLogs={videoDepositLogs}
        onVideoDepositLogChange={handlePageChange}
        onPrintClick={() => {
          window.scrollTo({ top: 0 });
          window.print();
        }}
        value={value}
        onChange={setValue}
      />
    </Page>
  );
};

export default styled(withAuth(VideoDepositRegionalManager))`
  padding: 50px;
  justify-content: center;
  align-items: center;

  ${VideoDepositLogRmForm} {
    width: 800px;
  }
`;
