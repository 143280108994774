import { AxiosInstance } from 'axios';
import DailyCheckRegister from '../models/DailyCheckRegister';

export async function getCheckRegisterByShift(
  axios: AxiosInstance,
  shiftDate: Date | string,
  shiftNumber: number,
): Promise<DailyCheckRegister | null> {
  const response = await axios.get(
    `/daily-check-register/${new Date(shiftDate).toISOString()}/${shiftNumber}`,
  );
  return response.data.checkRegister;
}

export async function getPriorCheckRegisterByShift(
  axios: AxiosInstance,
  shiftDate: string,
  shiftNumber: number,
): Promise<DailyCheckRegister | null> {
  const response = await axios.get(
    `/daily-check-register/${shiftDate}/${shiftNumber}/prior`,
  );
  return response.data.checkRegister;
}

export async function saveCheckRegister(
  axios: AxiosInstance,
  checkRegister: DailyCheckRegister,
): Promise<void> {
  await axios.post(`/daily-check-register`, checkRegister);
}
