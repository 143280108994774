import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import withAuth from '../components/withAuth';
import ScratchTicketInvDateForm from '../components/ScratchTicketInvDateForm';
import { navigate } from '@reach/router';

const ScratchTicketInventory: PageComponent = props => {
  const [inventoryDate, setInventoryDate] = useState(new Date());

  function onDateSelected(date: Date) {
    setInventoryDate(date);
  }

  return (
    <Page {...props}>
      <ScratchTicketInvDateForm
        date={inventoryDate}
        onDateChange={onDateSelected}
        onSubmit={() => {
          navigate(`/scratch-ticket-backstock/${inventoryDate.toISOString()}`);
        }}
      />
    </Page>
  );
};

export default withAuth(styled(ScratchTicketInventory)`
  justify-content: center;
  align-items: center;
`);
