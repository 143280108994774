import { AxiosError } from 'axios';

export default function getErrorMessage(error: AxiosError): string {
  const { response } = error;

  return (
    (response &&
      response.data &&
      response.data.error &&
      response.data.error.message) ||
    error.message
  );
}
