import { AxiosInstance } from 'axios';
import ScratchTicketBackStock from '../models/ScratchTicketBackStock';
import ScratchTickets, { ScratchTicketsDraft } from '../models/ScratchTickets';
import ScratchTicketDetails, {
  ScratchTicketDetailsDraft,
} from '../models/ScratchTicketDetails';

export type ScratchTicketWithDetails = ScratchTickets & ScratchTicketDetails;
export type ScratchTicketWithDetailsDraft = ScratchTicketsDraft &
  ScratchTicketDetailsDraft;

export function scratchTicketWithDetailsFromDraft(
  draft: ScratchTicketWithDetailsDraft,
): ScratchTicketWithDetails {
  return {
    amountSold: draft.amountSold === null ? null : +draft.amountSold,
    beginningInventory:
      draft.beginningInventory === null ? null : +draft.beginningInventory,
    endingInventory:
      draft.endingInventory === null ? null : +draft.endingInventory,
    gameNumber: +draft.gameNumber,
    outFlag: draft.outFlag === null ? null : +draft.outFlag,
    replacementFlag: +draft.replacementFlag,
    shift: +draft.shift,
    shiftDate: draft.shiftDate,
    slot: +draft.slot,
    storeId: +draft.storeId,
    gameName: draft.gameName,
    packetCount: draft.packetCount === null ? null : +draft.packetCount,
    status: draft.status === null ? null : +draft.status,
    value: draft.value === null ? null : +draft.value,
  };
}

export async function getShiftScratchTickets(
  axios: AxiosInstance,
  shiftDate: Date | string | number,
  shift: number | string,
): Promise<ScratchTicketDetails[]> {
  const response = await axios.get(
    `/scratchTickets/${new Date(shiftDate).toISOString()}/${shift}`,
  );
  return response.data.scratchTickets;
}

export async function getScratchTickets(
  axios: AxiosInstance,
): Promise<ScratchTickets[]> {
  const response = await axios.get('/scratchTickets');
  return response.data.scratchTickets;
}

export async function updateShiftScratchTicketTotal(
  axios: AxiosInstance,
  shiftDate: Date | string | number,
  shift: number | string,
  scratchTicketTotal: number,
) {
  await axios.put(
    `/shift/${shift}/${new Date(shiftDate).toISOString()}/scratchTicketTotal`,
    { scratchTicketTotal },
  );
}

export async function updateShiftScratchTicketDetails(
  axios: AxiosInstance,
  shiftDate: Date | string | number,
  shiftNumber: number | string,
  scratchTicketDetails: ScratchTicketDetails[],
) {
  await axios.put(
    `/shift/${shiftNumber}/${new Date(shiftDate).toISOString()}/scratchTickets`,
    { scratchTicketDetails },
  );
}

export async function createScratchTicket(
  axios: AxiosInstance,
  scratchTicket: ScratchTickets,
): Promise<ScratchTickets> {
  const response = await axios.post('/scratchTickets', scratchTicket);
  return response.data.scratchTickets;
}

export async function updateScratchTicket(
  axios: AxiosInstance,
  gameNumber: number,
  scratchTicket: ScratchTickets,
): Promise<void> {
  await axios.put(`/scratchTickets/${gameNumber}`, scratchTicket);
}

export async function getScratchTicketBackstocks(
  axios: AxiosInstance,
  inventoryDate: Date | string,
): Promise<ScratchTicketBackStock[]> {
  const response = await axios.get(
    `/scratchTickets/backstock/${new Date(inventoryDate).toISOString()}`,
  );
  return response.data.scratchTicketBackStocks;
}

export async function updateScratchTicketBackstock(
  axios: AxiosInstance,
  backstock: ScratchTicketBackStock,
) {
  await axios.put(`/scratchTickets/backstock/${backstock.id}`, backstock);
}
