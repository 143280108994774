import Store from '../models/Store';

const wvStoreNames = new Set([
  'ASHTON',
  'PATRICK',
  'ELKVIEW',
  'BECKLEY CROSSING',
  'OAK HILL',
  'BECKLEY PLAZA',
  'PRINCETON',
  'BLUEFIELD',
  'SOPHIA',
  'MERCHANT WALK',
  'GATEWAY',
  'BECKLEY BY-PASS',
  'FAYETTEVILLE',
  'ELKINS',
  'CLARKSBURG',
  'UNIVERSITY',
  'BRIDGEPORT',
  'WESTOVER',
  'MILEGROUND',
  'FAIRMONT',
  'WESTON',
  'NORTH MALL',
  'OLD COURTHOUSE',
  'RANSON',
  'SOUTHRIDGE',
  'QUINCY',
  'ST ALBANS',
  'LIBERTY',
  'NITRO',
  'HILLS PLAZA',
  'DUNBAR',
  'PEA RIDGE',
]);

export default function isWvStore(store: Store): boolean {
  return !!(store.storeName && wvStoreNames.has(store.storeName.toUpperCase()));
}
