import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import InputRow from './InputRow';
import NumberInput from './NumberInput';
import TextArea from './TextArea';
import NullableCheckbox from './NullableCheckbox';
import noop from '../utils/noop';
import VideoDepositLog from '../models/VideoDepositLog';

export interface DepositDetailFormProps {
  value: VideoDepositLog;
  onChange?: (value: VideoDepositLog) => any;
  totalDeposit: number;
  totalOverShort: number;
}

const DepositDetailForm: React.FC<DepositDetailFormProps> = props => {
  const {
    value,
    onChange = noop,
    totalDeposit,
    totalOverShort,
    ...rest
  } = props;

  return (
    <Column {...rest}>
      <h3>Deposit Detail</h3>
      <InputRow>
        <label>Detail 1's</label>
        <NumberInput
          value={value.detail1S || 0}
          onValueChange={detail1S => onChange({ ...value, detail1S })}
          data-testid="detailOnesInput"
        />
      </InputRow>
      <InputRow>
        <label>Detail 5's</label>
        <NumberInput
          value={value.detail5S || 0}
          onValueChange={detail5S => onChange({ ...value, detail5S })}
          data-testid="detailFivesInput"
        />
      </InputRow>
      <InputRow>
        <label>Detail 10's</label>
        <NumberInput
          value={value.detail10S || 0}
          onValueChange={detail10S => onChange({ ...value, detail10S })}
          data-testid="detailTensInput"
        />
      </InputRow>
      <InputRow>
        <label>Detail 20's</label>
        <NumberInput
          value={value.detail20S || 0}
          onValueChange={detail20S => onChange({ ...value, detail20S })}
          data-testid="detailTwentiesInput"
        />
      </InputRow>
      <InputRow>
        <label>Detail 50's</label>
        <NumberInput
          value={value.detail50S || 0}
          onValueChange={detail50S => onChange({ ...value, detail50S })}
          data-testid="detailFiftiesInput"
        />
      </InputRow>
      <InputRow>
        <label>Detail 100's</label>
        <NumberInput
          value={value.detail100S || 0}
          onValueChange={detail100S => onChange({ ...value, detail100S })}
          data-testid="detailHundredsInput"
        />
      </InputRow>
      <MoneyInputRow>
        <label>Total Deposit</label>
        <NumberInput value={totalDeposit} />
      </MoneyInputRow>
      <InputRow>
        <label>Bank Bag Number</label>
        <NumberInput
          value={value.bankBagNumber || 0}
          onValueChange={bankBagNumber => onChange({ ...value, bankBagNumber })}
          data-testid="bankBagNumberInput"
        />
      </InputRow>
      <MoneyInputRow>
        <label>Bank Correction</label>
        <NumberInput
          value={value.bankCorrection || 0}
          onValueChange={bankCorrection =>
            onChange({ ...value, bankCorrection })
          }
          data-testid="bankCorrectionInput"
        />
      </MoneyInputRow>
      <MoneyInputRow>
        <label>Total Over/Short</label>
        <TotalOverShortInput value={totalOverShort} />
      </MoneyInputRow>
      <InputRow>
        <Comments>
          <label>Comments</label>
          <TextArea
            value={value.comments || ''}
            onValueChange={comments => onChange({ ...value, comments })}
            data-testid="commentsInput"
          />
        </Comments>
      </InputRow>
      {/* <InputRow>
        <label>Current Day Cash Flow:</label>
        <NumberInput
          value={value.currentDayCashFlow}
          onValueChange={currentDayCashFlow =>
            onChange({ ...value, currentDayCashFlow })
          }
        />
      </InputRow> */}
      <label>
        <NullableCheckbox
          checked={!!value.cleanSweep}
          onValueChange={cleanSweep =>
            onChange({ ...value, cleanSweep: cleanSweep ? 1 : 0 })
          }
          data-testid="cleanSweepCheckbox"
        />
        Sweep was completed after closing or
        <br />
        before opening (same as end of period sweep)
      </label>
    </Column>
  );
};

const Comments = styled(Column)``;

export default styled(DepositDetailForm)`
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }

  ${NullableCheckbox} {
    margin-right: 20px;
  }

  ${NumberInput} {
    height: 25px;
    margin-left: 5px;
    text-align: right;
    padding: 0;
  }

  ${InputRow} {
    justify-content: flex-end;
    margin-right: 75px;

    ${Comments} {
      margin-right: -12px;
    }
  }
`;

const TotalOverShortInput = styled(NumberInput)`
  background-color: ${props => {
    const { value } = props;
    if (value !== 0) {
      return '#A00';
    }
    return 'white';
  }};
`;

const MoneyInputRow = styled(InputRow)`
  && {
    margin-right: 75px;
  }
`;
