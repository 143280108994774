import React, { useState } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import CreateShiftForm, {
  CreateShiftFormResult,
} from '../components/CreateShiftForm';
import Column from '../components/Column';
import withAuth from '../components/withAuth';
import Api from '../api/Api';
import requireUser from '../utils/requireUser';
import getErrorMessage from '../utils/getErrorMessage';
import isToday from '../utils/isToday';
import isYesterday from '../utils/isYesterday';
import isBlank from '../utils/isBlank';

const CreateShift: PageComponent = props => {
  const [submitting, setSubmitting] = useState(false);
  const user = requireUser(props);

  function handleCancelClick() {
    navigate('/');
  }

  async function handleSubmit(shiftMaster: CreateShiftFormResult) {
    try {
      if (
        !isToday(shiftMaster.shiftDate) &&
        !isYesterday(shiftMaster.shiftDate) &&
        !window.confirm('Did you mean to enter that date?')
      ) {
        return;
      }
      if (
        !shiftMaster.shiftDate ||
        isBlank(shiftMaster.shift) ||
        isBlank(shiftMaster.beginningTill) ||
        isBlank(shiftMaster.beginningChange) ||
        isBlank(shiftMaster.beginningPokerBank)
      ) {
        throw new Error(
          'You must fill in the Shift, Date and the Cash Totals!',
        );
      }

      setSubmitting(true);
      const result = await Api.createShift({
        shiftDate: shiftMaster.shiftDate,
        shiftNumber: +shiftMaster.shift,
        beginningTill: +shiftMaster.beginningTill,
        beginningChange: +shiftMaster.beginningChange,
        cashDispensed: +shiftMaster.beginningPokerBank,
      });
      if (result) {
        const { shift, shiftDate } = result.shift;

        for (const warning of result.warnings) {
          window.alert(warning);
        }

        navigate(`/audit-shift/${shiftDate}/${shift}?backRoute=/`);
      }
    } catch (error) {
      alert(getErrorMessage(error));
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Page {...props}>
      <FormWrapper>
        <CreateShiftForm
          employeeId={user.employeeId}
          onSubmit={handleSubmit}
          onCancelClick={handleCancelClick}
          disabled={submitting}
        />
      </FormWrapper>
    </Page>
  );
};

export default styled(withAuth(CreateShift))`
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled(Column)`
  max-width: 1000px;
`;
