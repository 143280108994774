import React, { FC, useEffect } from 'react';
import styled from 'styled-components/macro';
import Overlay from './Overlay';
import Column from './Column';
import BlankButton from './BlankButton';
import Row from './Row';

export interface FramedModalProps {
  disableClose?: boolean;
  onClose?: () => any;
  title?: string;
  hideHeader?: boolean;
}

const Modal: FC<FramedModalProps> = props => {
  const { disableClose, onClose, children, title, hideHeader, ...rest } = props;

  // Disable body overflow when modal mounts. Re-enable it on unmount.
  useEffect(() => {
    const prevOverflow = document.body.style.overflow;
    if (prevOverflow !== 'none') {
      document.body.style.overflow = 'none';
      return () => {
        document.body.style.overflow = prevOverflow;
      };
    }
  }, []);

  return (
    <ModalOverlay onClick={disableClose ? null : onClose}>
      <Column {...rest}>
        {!hideHeader && (
          <Header>
            <Title>{title}</Title>
            {!disableClose && (
              <CloseButton onClick={onClose}>{'\u2715' /* ✕ */}</CloseButton>
            )}
          </Header>
        )}
        {children}
      </Column>
    </ModalOverlay>
  );
};

export default styled(Modal)`
  position: absolute;
  top: 120px;
  margin-bottom: 120px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #e5ebf1;
  z-index: 3;
  padding: 50px;
  max-width: 100%;
`;

const ModalOverlay = styled(Overlay)`
  background: none;
  opacity: 1;
  /* Doesn't overflow everything else when its in a form box */
  height: 100%;
`;

// Styled similar to the FormBox
const CloseButton = styled(BlankButton)`
  background-color: #c0c0c0;
  color: black;
  width: 20px;
  height: 20px;
  border-bottom: 2px solid #777;
  border-right: 2px solid #777;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 0;
  padding-top: 2px;

  &:active {
    border-top: 2px solid #777;
    border-left: 2px solid #777;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    background-color: #b0b0b0;
  }
`;

const Header = styled(Row)`
  position: absolute;
  background-color: #a0bcd9;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 10px;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
`;

const Title = styled('div')``;
