import { AxiosInstance } from 'axios';
import Store from '../models/Store';

export async function getStoreById(
  axios: AxiosInstance,
  storeId: number,
): Promise<Store | null> {
  const response = await axios.get(`/store/${storeId}`);
  return response.data.store;
}

export async function getStores(axios: AxiosInstance) {
  const response = await axios.get('/stores');
  return response.data.stores;
}
