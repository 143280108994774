import React, { FC } from 'react';
import styled from 'styled-components/macro';
import FramedModal, { FramedModalProps } from './FramedModal';
import Row from './Row';
import BlockButton from './BlockButton';
interface DialogProps extends FramedModalProps {
  message?: string;
  onOkClicked?: () => any;
}

const OkDialog: FC<DialogProps> = props => {
  const { message = 'Invalid use of null', onOkClicked, ...rest } = props;

  return (
    <Root {...rest}>
      <Message>{message}</Message>
      <Row>
        <OkButton onClick={onOkClicked}>OK</OkButton>
      </Row>
    </Root>
  );
};

export default styled(OkDialog)``;

const Root = styled(FramedModal)`
  width: 290px;
  top: 25%;
  margin-bottom: 0;
`;

const Message = styled(Row)`
  margin-bottom: 10px;
  font-size: 20px;
`;

const OkButton = styled(BlockButton)`
  min-width: 70px;
  width: 70px;
  height: 35px;
  min-height: 35px;
  margin-left: auto;
`;
