import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import useAsyncEffect from '../utils/useAsyncEffect';
import Page, { PageComponent } from '../components/Page';
import CigPriceSetupForm, { Value } from '../components/CigPriceSetupForm';
import withAuth from '../components/withAuth';
import Api from '../api/Api';
import SpinnerOverlay from '../components/SpinnerOverlay';

const ChangeCigPrices: PageComponent = props => {
  const [cigPrices, setCigPrices] = useState<Value | null>(null);

  useAsyncEffect(async () => {
    const currentCigPrices = await Api.getCigPrices();
    setCigPrices(currentCigPrices);
  }, []);

  async function handleSubmit(value: Value) {
    await Api.patchCigPrices({
      ...value,
      carton10Price: value.carton10Price !== null ? +value.carton10Price : null,
      carton11Price: value.carton11Price !== null ? +value.carton11Price : null,
      carton12Price: value.carton12Price !== null ? +value.carton12Price : null,
      carton13Price: value.carton13Price !== null ? +value.carton13Price : null,
      carton14Price: value.carton14Price !== null ? +value.carton14Price : null,
      carton15Price: value.carton15Price !== null ? +value.carton15Price : null,
      carton16Price: value.carton16Price !== null ? +value.carton16Price : null,
      carton17Price: value.carton17Price !== null ? +value.carton17Price : null,
      carton18Price: value.carton18Price !== null ? +value.carton18Price : null,
      carton19Price: value.carton19Price !== null ? +value.carton19Price : null,
      carton1Price: value.carton1Price !== null ? +value.carton1Price : null,
      carton20Price: value.carton20Price !== null ? +value.carton20Price : null,
      carton2Price: value.carton2Price !== null ? +value.carton2Price : null,
      carton3Price: value.carton3Price !== null ? +value.carton3Price : null,
      carton4Price: value.carton4Price !== null ? +value.carton4Price : null,
      carton5Price: value.carton5Price !== null ? +value.carton5Price : null,
      carton6Price: value.carton6Price !== null ? +value.carton6Price : null,
      carton7Price: value.carton7Price !== null ? +value.carton7Price : null,
      carton8Price: value.carton8Price !== null ? +value.carton8Price : null,
      carton9Price: value.carton9Price !== null ? +value.carton9Price : null,
      pack10Price: value.pack10Price !== null ? +value.pack10Price : null,
      pack11Price: value.pack11Price !== null ? +value.pack11Price : null,
      pack12Price: value.pack12Price !== null ? +value.pack12Price : null,
      pack13Price: value.pack13Price !== null ? +value.pack13Price : null,
      pack14Price: value.pack14Price !== null ? +value.pack14Price : null,
      pack15Price: value.pack15Price !== null ? +value.pack15Price : null,
      pack16Price: value.pack16Price !== null ? +value.pack16Price : null,
      pack17Price: value.pack17Price !== null ? +value.pack17Price : null,
      pack18Price: value.pack18Price !== null ? +value.pack18Price : null,
      pack19Price: value.pack19Price !== null ? +value.pack19Price : null,
      pack1Price: value.pack1Price !== null ? +value.pack1Price : null,
      pack20Price: value.pack20Price !== null ? +value.pack20Price : null,
      pack2Price: value.pack2Price !== null ? +value.pack2Price : null,
      pack3Price: value.pack3Price !== null ? +value.pack3Price : null,
      pack4Price: value.pack4Price !== null ? +value.pack4Price : null,
      pack5Price: value.pack5Price !== null ? +value.pack5Price : null,
      pack6Price: value.pack6Price !== null ? +value.pack6Price : null,
      pack7Price: value.pack7Price !== null ? +value.pack7Price : null,
      pack8Price: value.pack8Price !== null ? +value.pack8Price : null,
      pack9Price: value.pack9Price !== null ? +value.pack9Price : null,
    });
    navigate('/admin');
  }

  if (!cigPrices) {
    // Loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <CigPriceSetupForm
        value={cigPrices}
        onChange={setCigPrices}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default withAuth(styled(ChangeCigPrices)``);
