import { useEffect } from 'react';

let mountCount = 0;

export default function useSinglePagePrint() {
  useEffect(() => {
    mountCount += 1;
    if (mountCount === 1) {
      document.body.classList.add('single-page-print');
    }
    return () => {
      mountCount -= 1;
      if (mountCount === 0) {
        document.body.classList.remove('single-page-print');
      }
    };
  }, []);
}
