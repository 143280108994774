import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { navigate } from '@reach/router';
import Column from './Column';
import VideoMasterReportForm from './VideoMasterReportForm';
import TrialCassetteReportForm from './TrialCassetteReportForm';
import NumberOfSafeTubesLoadedForm from './NumberOfSafeTubesLoadedForm';
import AtmFillForm from './AtmFillForm';
import AddCashReportNumbersForm from './AddCashReportNumbersForm';
import RegionalUseForm from './RegionalUseForm';
import DepositDetailForm from './DepositDetailForm';
import Row from './Row';
import BlockButton from './BlockButton';
import { FaPrint } from './icons';
import NumberInput from './NumberInput';
import MoneyInput from './MoneyInput';
import DateInput from './DateInput';
import FormBox from './FormBox';
import Input from './Input';
import VideoDepositLog from '../models/VideoDepositLog';
import Store from '../models/Store';
import noop from '../utils/noop';
import Employees from '../models/Employees';
import isValidDate from '../utils/isValidDate';
import usePrintLayout from '../utils/usePrintLayout';
import useIdleTimeout from '../utils/useIdleTimeout';
import todayISO from '../utils/todayISO';

const onesPerRoll = 20;
const fivesPerRoll = 20;
const tensPerRoll = 10;
const twentiesPerRoll = 10;
const fiftiesPerRoll = 4;
const hundredsPerRoll = 4;

export interface VideoDepositLogInputFormProps {
  storeId: number;
  employee: Employees;
  store: Store;
  priorVideoDepositLog: VideoDepositLog | null;
  reviewableVideoDepositLogs: VideoDepositLog[];
  value: VideoDepositLog;
  onChange?(value: VideoDepositLog): any;
  onCloseClick?(value: VideoDepositLog, shouldSave: boolean): any;
  onSave?(value: VideoDepositLog): any;
}

const VideoDepositLogInputForm: FC<VideoDepositLogInputFormProps> = props => {
  useIdleTimeout(() => close(false));
  usePrintLayout('landscape');
  const {
    storeId,
    employee,
    store,
    priorVideoDepositLog,
    reviewableVideoDepositLogs,
    onChange = noop,
    onCloseClick = noop,
    onSave = noop,
    ...rest
  } = props;
  let firstName: string;
  let lastName: string;

  if (
    (props.value.firstName && props.value.firstName.length > 0) ||
    (props.value.lastName && props.value.lastName.length > 0)
  ) {
    firstName = props.value.firstName || '';
    lastName = props.value.lastName || '';
  } else {
    firstName = employee.firstName || '';
    lastName = employee.lastName || '';
  }

  const employeeName = `${firstName} ${lastName}`;

  const value = getValueWithCalculations(props.value);

  const totalLoadQuantityToCassette =
    (value.atmResidualCount || 0) + (value.n20SLoaded || 0);

  const balanced =
    totalLoadQuantityToCassette === (value.atmAdditionCount || 0);

  const twentiesToLoad = Math.max(
    (store.atmMax || 0) / 20 - (value.atmReportResidual || 0),
    0,
  );

  function handleChange(newValue: VideoDepositLog) {
    onChange(getValueWithCalculations(newValue));
  }

  async function handleBackClick() {
    await onSave(value);
    let currentIndex = reviewableVideoDepositLogs.findIndex(
      vdl => vdl.id === value.id,
    );

    if (currentIndex === -1) {
      currentIndex = reviewableVideoDepositLogs.length;
    }

    if (currentIndex > 0) {
      const prevVdl = reviewableVideoDepositLogs[currentIndex - 1];
      if (prevVdl) {
        navigate(`/video-deposit-log/audit/${prevVdl.id}`);
      }
    }
  }

  async function handleForwardClick() {
    await onSave(value);
    const currentIndex = reviewableVideoDepositLogs.findIndex(
      vdl => vdl.id === value.id,
    );

    if (currentIndex === -1) {
      return;
    }

    const nextVdl = reviewableVideoDepositLogs[currentIndex + 1];
    if (nextVdl) {
      navigate(`/video-deposit-log/audit/${nextVdl.id}`);
    } else {
      navigate(`/video-deposit-log/create`);
    }
  }

  async function handleNewClick() {
    await onSave(value);
    navigate(`/video-deposit-log/create`);
  }

  function getValueWithCalculations(
    newValue: VideoDepositLog,
  ): VideoDepositLog {
    const defaultedDate =
      newValue.date && isValidDate(newValue.date) ? newValue.date : todayISO();

    const priorTotalCashIn = priorVideoDepositLog
      ? (priorVideoDepositLog.chipChange1 ||
          priorVideoDepositLog.machine1 ||
          0) +
        (priorVideoDepositLog.chipChange2 ||
          priorVideoDepositLog.machine2 ||
          0) +
        (priorVideoDepositLog.chipChange3 ||
          priorVideoDepositLog.machine3 ||
          0) +
        (priorVideoDepositLog.chipChange4 ||
          priorVideoDepositLog.machine4 ||
          0) +
        (priorVideoDepositLog.chipChange5 ||
          priorVideoDepositLog.machine5 ||
          0) +
        (priorVideoDepositLog.chipChange6 ||
          priorVideoDepositLog.machine6 ||
          0) +
        (priorVideoDepositLog.chipChange7 ||
          priorVideoDepositLog.machine7 ||
          0) +
        (priorVideoDepositLog.chipChange8 ||
          priorVideoDepositLog.machine8 ||
          0) +
        (priorVideoDepositLog.chipChange9 ||
          priorVideoDepositLog.machine9 ||
          0) +
        (priorVideoDepositLog.chipChange10 ||
          priorVideoDepositLog.machine10 ||
          0)
      : 0;

    const machineTotal =
      (newValue.machine1 || 0) +
      (newValue.machine2 || 0) +
      (newValue.machine3 || 0) +
      (newValue.machine4 || 0) +
      (newValue.machine5 || 0) +
      (newValue.machine6 || 0) +
      (newValue.machine7 || 0) +
      (newValue.machine8 || 0) +
      (newValue.machine9 || 0) +
      (newValue.machine10 || 0) +
      (newValue.chipChange1 || 0) +
      (newValue.chipChange2 || 0) +
      (newValue.chipChange3 || 0) +
      (newValue.chipChange4 || 0) +
      (newValue.chipChange5 || 0) +
      (newValue.chipChange6 || 0) +
      (newValue.chipChange7 || 0) +
      (newValue.chipChange8 || 0) +
      (newValue.chipChange9 || 0) +
      (newValue.chipChange10 || 0);

    const totalCashIn = machineTotal - priorTotalCashIn;

    const totalLoadedIntoSafe =
      onesPerRoll * (newValue.load100S || 0) +
      fivesPerRoll * 5 * (newValue.load5S || 0) +
      tensPerRoll * 10 * (newValue.load10S || 0) +
      twentiesPerRoll * 20 * (newValue.load20S || 0) +
      fiftiesPerRoll * 50 * (newValue.load50S || 0) +
      hundredsPerRoll * 100 * (newValue.newLoad100S || 0);

    const netDeposit =
      (newValue.moneytoIncreaseSafeAmount || 0) +
      totalCashIn -
      totalLoadedIntoSafe +
      20 * (newValue.atmRejectedCount || 0) -
      20 * (newValue.n20SLoaded || 0);

    const totalDeposit =
      (newValue.detail1S || 0) +
      (newValue.detail5S || 0) * 5 +
      (newValue.detail10S || 0) * 10 +
      (newValue.detail20S || 0) * 20 +
      (newValue.detail50S || 0) * 50 +
      (newValue.detail100S || 0) * 100;

    const totalOverShort =
      totalDeposit -
      netDeposit +
      (newValue.bankCorrection || 0) +
      (newValue.vlmPullPriorDay || 0) -
      (newValue.jamBillsIn || 0);

    if (priorVideoDepositLog) {
      return {
        ...newValue,
        date: defaultedDate,
        storeId,
        firstName,
        lastName,
        totalCashInCalc: totalCashIn,
        netDepositCalc: netDeposit,
        totalOverShortCalc: totalOverShort,
        totalLoadedIntoSafeCalc: totalLoadedIntoSafe,
        totalDepositCalc: totalDeposit,
        machine1Total:
          (newValue.machine1 || 0) +
          (newValue.chipChange1 || 0) -
          (priorVideoDepositLog.rollOver1
            ? priorVideoDepositLog.chipChange1 || 0
            : priorVideoDepositLog.machine1 || 0),
        machine2Total:
          (newValue.machine2 || 0) +
          (newValue.chipChange2 || 0) -
          (priorVideoDepositLog.rollOver2
            ? priorVideoDepositLog.chipChange2 || 0
            : priorVideoDepositLog.machine2 || 0),
        machine3Total:
          (newValue.machine3 || 0) +
          (newValue.chipChange3 || 0) -
          (priorVideoDepositLog.rollOver3
            ? priorVideoDepositLog.chipChange3 || 0
            : priorVideoDepositLog.machine3 || 0),
        machine4Total:
          (newValue.machine4 || 0) +
          (newValue.chipChange4 || 0) -
          (priorVideoDepositLog.rollOver4
            ? priorVideoDepositLog.chipChange4 || 0
            : priorVideoDepositLog.machine4 || 0),
        machine5Total:
          (newValue.machine5 || 0) +
          (newValue.chipChange5 || 0) -
          (priorVideoDepositLog.rollOver5
            ? priorVideoDepositLog.chipChange5 || 0
            : priorVideoDepositLog.machine5 || 0),
        machine6Total:
          (newValue.machine6 || 0) +
          (newValue.chipChange6 || 0) -
          (priorVideoDepositLog.rollOver6
            ? priorVideoDepositLog.chipChange6 || 0
            : priorVideoDepositLog.machine6 || 0),
        machine7Total:
          (newValue.machine7 || 0) +
          (newValue.chipChange7 || 0) -
          (priorVideoDepositLog.rollOver7
            ? priorVideoDepositLog.chipChange7 || 0
            : priorVideoDepositLog.machine7 || 0),
        machine8Total:
          (newValue.machine8 || 0) +
          (newValue.chipChange8 || 0) -
          (priorVideoDepositLog.rollOver8
            ? priorVideoDepositLog.chipChange8 || 0
            : priorVideoDepositLog.machine8 || 0),
        machine9Total:
          (newValue.machine9 || 0) +
          (newValue.chipChange9 || 0) -
          (priorVideoDepositLog.rollOver9
            ? priorVideoDepositLog.chipChange9 || 0
            : priorVideoDepositLog.machine9 || 0),
        machine10Total:
          (newValue.machine10 || 0) +
          (newValue.chipChange10 || 0) -
          (priorVideoDepositLog.rollOver10
            ? priorVideoDepositLog.chipChange10 || 0
            : priorVideoDepositLog.machine10 || 0),
      };
    } else {
      return {
        ...newValue,
        date: defaultedDate,
        storeId,
        firstName,
        lastName,
        totalCashInCalc: totalCashIn,
        netDepositCalc: netDeposit,
        totalOverShortCalc: totalOverShort,
        totalLoadedIntoSafeCalc: totalLoadedIntoSafe,
        totalDepositCalc: totalDeposit,
        machine1Total: newValue.machine1Total || 0,
        machine2Total: newValue.machine2Total || 0,
        machine3Total: newValue.machine3Total || 0,
        machine4Total: newValue.machine4Total || 0,
        machine5Total: newValue.machine5Total || 0,
        machine6Total: newValue.machine6Total || 0,
        machine7Total: newValue.machine7Total || 0,
        machine8Total: newValue.machine8Total || 0,
        machine9Total: newValue.machine9Total || 0,
        machine10Total: newValue.machine10Total || 0,
      };
    }
  }

  function close(shouldSave: boolean) {
    onCloseClick(getValueWithCalculations(value), shouldSave);
  }

  return (
    <FormBox
      {...rest}
      onCloseClick={() => close(true)}
      title="Video Deposit Log Input"
    >
      <FormInfoRow>
        <FormInfo>
          <label>
            <b>Store ID</b>
          </label>
          <FormInfoField value={storeId} disabled readOnly />
        </FormInfo>
        <FormInfo>
          <label>
            <b>Date</b>
          </label>
          <VdlDateInput
            type="date"
            value={value.date ? value.date.slice(0, 10) : ''}
            onValueChange={newDate =>
              handleChange({
                ...value,
                date: newDate,
              })
            }
          />
        </FormInfo>
        <FormInfo>
          <label>Employee Name:</label>
          <EmployeeName value={employeeName} disabled readOnly />
        </FormInfo>
      </FormInfoRow>
      <FormBody>
        <MainColumn>
          <BorderWrappedContainer right>
            <VideoMasterReportForm
              value={value}
              onChange={handleChange}
              totalCashIn={value.totalCashInCalc || 0}
            />
          </BorderWrappedContainer>
          <TrialCassetteReportFormContainer bottom right>
            <TrialCassetteReportForm
              value={value}
              onChange={handleChange}
              loadCap={store.atmMax || 0}
              twentiesToLoad={twentiesToLoad}
            />
          </TrialCassetteReportFormContainer>
          <BorderWrappedContainer right>
            <NumberOfSafeTubesLoadedForm
              value={value}
              onChange={handleChange}
              netDeposit={value.netDepositCalc || 0}
              totalLoadedIntoSafe={value.totalLoadedIntoSafeCalc || 0}
            />
          </BorderWrappedContainer>
        </MainColumn>
        <MainColumn>
          <BorderWrappedContainer bottom>
            <AtmFillForm value={value} onChange={handleChange} />
          </BorderWrappedContainer>
          <BorderWrappedContainer bottom>
            <AddCashReportNumbersForm
              value={value}
              onChange={handleChange}
              balanced={balanced}
              totalLoadQuantity={totalLoadQuantityToCassette}
            />
          </BorderWrappedContainer>
          <RegionalUseForm value={value} onChange={handleChange} />
        </MainColumn>
        <BorderWrappedContainer left>
          <MainColumn>
            <DepositDetailForm
              value={value}
              onChange={handleChange}
              totalDeposit={value.totalDepositCalc || 0}
              totalOverShort={value.totalOverShortCalc || 0}
            />
            <ButtonRow>
              <BlockButton
                onClick={() => close(true)}
                data-testid="closeButton"
              >
                Close Form
              </BlockButton>
              <PrintButton
                onClick={() => {
                  window.scrollTo({ top: 0 });
                  window.print();
                }}
                data-testid="printButton"
              >
                <FaPrint size="0.75em" />
                Print
              </PrintButton>
            </ButtonRow>
          </MainColumn>
        </BorderWrappedContainer>
      </FormBody>
      <Row>
        <NavButton onClick={handleBackClick}>{'<'}</NavButton>
        <NavButton onClick={handleForwardClick}>{'>'}</NavButton>
        <NavButton onClick={handleNewClick}>{'>*'}</NavButton>
      </Row>
    </FormBox>
  );
};

export default styled(VideoDepositLogInputForm)`
  width: 1200px;
  margin: auto;

  ${VideoMasterReportForm}, ${AtmFillForm}, ${DepositDetailForm} {
    > h3 {
      height: 50px;
      align-content: flex-end;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      @media print {
        margin-bottom: 5px;
      }
    }
  }

  @media (max-width: 1023px) {
    width: 1024px;
  }

  @media print {
    overflow: visible;
    width: 100%;
  }
`;

const VdlDateInput = styled(Input)`
  /**
   * Hide the calendar icon in chrome.
   */
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

const FormBody = styled(Row)``;

const MainColumn = styled(Column)`
  flex: 1 1 auto;

  h1 {
    margin-bottom: 20px;
    @media print {
      margin-bottom: 5px;
    }
  }

  ${DateInput},
  ${MoneyInput},
  ${NumberInput} {
    width: 80px;
  }

  ${NumberOfSafeTubesLoadedForm},
  ${TrialCassetteReportForm} {
    margin-top: 20px;
    @media print {
      margin-top: 5px;
    }
  }

  ${DepositDetailForm} {
    padding-left: 5px;
  }
`;

const ButtonRow = styled(Row)`
  margin-top: 50px;
  justify-content: space-around;
  padding-left: 5px;
  ${BlockButton} {
    height: 40px;
    width: 60px;
    min-width: 60px;
    padding: 0;
    font-size: 16px;
  }
  @media print {
    display: none;
  }
`;

const PrintButton = styled(BlockButton)`
  flex-direction: column;
  margin-left: 20px;
`;

const FormInfo = styled(Row)`
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 3px;

  > label {
    margin: auto 5px auto 20px;
  }

  > input {
    height: 25px;
    padding: 0;
    text-align: right;
    width: 90px;

    :disabled {
      background-color: #ffffff;
    }
  }
`;

const FormInfoField = styled(Input)``;

const FormInfoRow = styled(Row)`
  height: 30px;
  align-items: center;

  input[type='date'] {
    width: 150px;
  }
`;

const EmployeeName = styled(FormInfoField)`
  && {
    width: 220px;
    text-align: left;
  }
`;

interface BorderWrapProperties {
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
}

const BorderWrappedContainer = styled(Column)<BorderWrapProperties>`
  ${props =>
    props.right &&
    css`
      border-right: 2px solid #dcdfe5;
    `}

  ${props =>
    props.bottom &&
    css`
      border-bottom: 2px solid #dcdfe5;
    `}

  ${props =>
    props.left &&
    css`
      border-left: 2px solid #dcdfe5;
    `}
  padding: 5px;
  @media print {
    padding: 0 5px;
  }
`;

const TrialCassetteReportFormContainer = styled(BorderWrappedContainer)`
  padding: 5px 0;
  @media print {
    padding: 0;
  }
`;

const NavButton = styled(BlockButton)`
  width: 60px;
  min-width: 0;
  @media print {
    display: none;
  }
`;
