import React, { useState, FormEvent, useMemo } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import withAuth from '../components/withAuth';
import { downloadFile } from '../utils/downloadFile';
import isValidDate from '../utils/isValidDate';
import FormBox from '../components/FormBox';

const WorkbookExportPage: PageComponent = props => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const canSubmit = useMemo(
    () => isValidDate(startDate) && isValidDate(endDate),
    [startDate, endDate],
  );

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!isValidDate(startDate)) {
      window.alert('Start date is not valid.');
      return;
    }

    if (!isValidDate(endDate)) {
      window.alert('End date is not valid.');
      return;
    }

    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);

    if (startDateObject > endDateObject) {
      window.alert('Start date must be before end date.');
      return;
    }

    const startDateISO = startDateObject.toISOString();
    const endDateISO = endDateObject.toISOString();

    downloadFile(
      `/api/workbook-export?startDate=${encodeURIComponent(
        startDateISO,
      )}&endDate=${encodeURIComponent(endDateISO)}`,
    );
  }

  return (
    <Page {...props}>
      <FormBox title="Workbook Export">
        <form onSubmit={handleSubmit}>
          <label htmlFor="start-date">Start Date</label>
          <input
            id="start-date"
            type="date"
            value={startDate}
            onChange={e => setStartDate(e.currentTarget.value)}
          />

          <label htmlFor="end-date">End Date</label>
          <input
            id="end-date"
            type="date"
            value={endDate}
            onChange={e => setEndDate(e.currentTarget.value)}
          />

          <button disabled={!canSubmit}>Export</button>
        </form>
      </FormBox>
    </Page>
  );
};

export default styled(withAuth(WorkbookExportPage))`
  justify-content: center;
  align-items: center;
  form {
    display: flex;
    flex-direction: column;
  }
  input {
    margin-bottom: 1rem;
  }
  button {
    max-width: 6rem;
  }
`;
