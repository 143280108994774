import React from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import Page, { PageComponent } from '../components/Page';
import ManagersShiftAuditForm, {
  ManagersShiftAuditSubmitResult,
} from '../components/ManagerShiftAuditForm';
import Column from '../components/Column';
import withAuth from '../components/withAuth';

const Manager: PageComponent = props => {
  const { user } = props;
  const routeTo = (route: string) => () => navigate(route);

  async function handleAuditShiftClick(result: ManagersShiftAuditSubmitResult) {
    return navigate(
      `/audit-shift/${result.date.toISOString()}/${
        result.shift
      }?backRoute=/manager`,
    );
  }

  async function handleVideoDepositLogClick() {
    navigate(`/video-deposit-log/create`);
  }

  return (
    <Page {...props}>
      <FormWrapper>
        <ManagersShiftAuditForm
          user={user}
          onCloseClick={routeTo('/')}
          onOverShortLogClick={routeTo('/over-short-log')}
          onCigaretteOverShortClick={routeTo('/cig-over-short')}
          onVideoDepositLogClick={handleVideoDepositLogClick}
          onVideoDepositManagerClick={routeTo(
            '/video-deposit-regional-manager',
          )}
          onPayoutReportclick={routeTo('/payout-report')}
          onW2gReportClick={routeTo('/w2g-report')}
          onOverShortByEmployeeClick={routeTo('/over-short-by-employee-log')}
          onScratchTicketInventoryClick={routeTo('/scratch-ticket-inventory')}
          onDeleteShiftClicked={routeTo('/delete-shift')}
          onAuditSubmit={handleAuditShiftClick}
        />
      </FormWrapper>
    </Page>
  );
};

export default styled(withAuth(Manager, 1))`
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled(Column)``;
