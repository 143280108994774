import React from 'react';
import { Router } from '@reach/router';
import BadBrowserBanner from './components/BadBrowserBanner';

import Admin from './pages/admin';
import AuditErrorCheck from './pages/audit-error-check';
import AuditSafeLoad from './pages/audit-safe-load';
import AuditShift from './pages/audit-shift';
import BrandInventory from './pages/brand-inventory';
import ChangeCigPrices from './pages/change-cig-prices';
import CigOverShort from './pages/cig-over-short';
import CreateEmployee from './pages/create-employee';
import CreateLottoGame from './pages/create-lotto-game';
import CreateShift from './pages/create-shift';
import MachineAudit from './pages/machine-audit';
import MainSwitchboard from './pages/main-switchboard';
import Manager from './pages/manager';
import Over21Test from './pages/over-21-test';
import OverShortLog from './pages/over-short-log';
import OverShortByEmployeeLog from './pages/over-short-by-employee-log';
import CreateVideoDepositLog from './pages/create-video-deposit-log';
import AuditVideoDepositLog from './pages/audit-video-deposit-log';
import VideoDepositRegionalManager from './pages/video-deposit-regional-manager';
import PayoutReport from './pages/payout-report';
import W2gReport from './pages/w-2g-report';
import ScratchTicketBackstock from './pages/scratch-ticket-backstock';
import Login from './pages/login';
import ScratchTicketInventory from './pages/scratch-ticket-inventory';
import ErrorCheck from './pages/error-check';
import DeleteShift from './pages/delete-shift';
import isChrome from './utils/isChrome';
import WorkbookExportPage from './pages/workbook-export-page';

const browserIsChrome = isChrome();

const App: React.FC = () => {
  return (
    <>
      {!browserIsChrome && <BadBrowserBanner />}
      <Router>
        <MainSwitchboard default path="/" />
        <Admin path="/admin" />
        <AuditErrorCheck path="/audit-error-check" />
        <AuditSafeLoad path="/audit-safe-load" />
        <AuditShift
          path="/audit-shift/:shiftDate/:shiftNumber"
          shiftDate=""
          shiftNumber=""
        />
        <BrandInventory path="/brand-inventory" />
        <BrandInventory path="/brand-inventory/:inventoryDate" />
        <ChangeCigPrices path="/change-cig-prices" />
        <CigOverShort path="/cig-over-short" />
        <CreateEmployee path="/create-employee" />
        <CreateLottoGame path="/create-lotto-game" />
        <CreateShift path="/create-shift" />
        <Login path="/login" />
        <MachineAudit path="/machine-audit" />
        <Manager path="/manager" />
        <W2gReport path="/w2g-report" />
        <Over21Test path="/over-21-test" />
        <OverShortByEmployeeLog path="/over-short-by-employee-log" />
        <OverShortLog path="/over-short-log" />
        <PayoutReport path="/payout-report" />
        <ScratchTicketBackstock
          path="/scratch-ticket-backstock/:inventoryDate"
          inventoryDate=""
        />
        <ScratchTicketInventory path="/scratch-ticket-inventory" />
        <CreateVideoDepositLog path="/video-deposit-log/create" />
        <AuditVideoDepositLog
          path="/video-deposit-log/audit/:videoDepositLogId"
          videoDepositLogId=""
        />
        <VideoDepositRegionalManager path="/video-deposit-regional-manager" />
        <ErrorCheck path="/error-check" />
        <ErrorCheck path="/error-check/print" autoPrint />
        <DeleteShift path="/delete-shift" />
        <WorkbookExportPage path="/workbook-export" />
      </Router>
    </>
  );
};

export default App;
