import { AxiosInstance } from 'axios';

export interface PayoutReportDataRow {
  storeId: number;
  storeName: string;
  shift: number;
  payouts: number;
  payoutComments: string;
  companyName: string;
  shiftDate: string;
}

export async function getPayoutReportData(
  axios: AxiosInstance,
  startDate: Date | string,
  endDate: Date | string,
): Promise<PayoutReportDataRow[]> {
  const response = await axios.get(
    `/payout-report/${new Date(startDate).toISOString()}/${new Date(
      endDate,
    ).toISOString()}`,
  );
  return response.data.payoutReportData;
}
