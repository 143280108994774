import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Form from './Form';
import Input from './Input';
import InputRow from './InputRow';
import Row from './Row';
import BlockButton from './BlockButton';
import NumberInput from './NumberInput';
import FormBox from './FormBox';
import isValidDate from '../utils/isValidDate';

export interface CreateShiftFormResult {
  shiftDate: string;
  shift: string;
  beginningTill: string;
  beginningChange: string;
  beginningPokerBank: string;
}

export interface CreateShiftFormProps {
  employeeId: string;
  disabled?: boolean;
  onSubmit?: (formResult: CreateShiftFormResult) => any;
  onCancelClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => any;
}

const CreateShiftForm: React.FC<CreateShiftFormProps> = props => {
  const { employeeId, onSubmit, onCancelClick, disabled, ...rest } = props;
  const [shiftDate, setShiftDate] = useState('');
  const [shift, setShift] = useState<string>('');
  const [beginningTill, setBeginningTill] = useState<string>('');
  const [beginningChange, setBeginningChange] = useState<string>('');
  const [beginningPokerBank, setBeginningPokerBank] = useState<string>('0');

  function handleCancelClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.preventDefault(); // stop form from submitting
    if (onCancelClick) {
      onCancelClick(event);
    }
  }

  function handleSubmit() {
    if (!isValidDate(shiftDate)) {
      window.alert('Invalid shift date.');
      return;
    }
    const parsedShift = +shift;
    if (Number.isNaN(parsedShift) || parsedShift <= 0 || parsedShift > 5) {
      window.alert('Invalid shift number. Must be between 1 and 5.');
      return;
    }
    if (onSubmit) {
      onSubmit({
        shiftDate: `${shiftDate}T00:00:00.000Z`,
        shift,
        beginningTill,
        beginningChange,
        beginningPokerBank,
      });
    }
  }

  return (
    <FormBox {...rest} title="create shift : Form">
      <Form onSubmit={handleSubmit} data-testid="form">
        <InputRow>
          <label>Date:</label>
          <Input
            type="date"
            value={shiftDate}
            onChange={event => setShiftDate(event.currentTarget.value)}
            disabled={disabled}
            data-testid="dateInput"
          />

          <label>Shift:</label>
          <Input
            value={shift}
            onValueChange={setShift}
            disabled={disabled}
            data-testid="shiftInput"
          />

          <label>Employee ID:</label>
          <Input disabled value={employeeId} />
        </InputRow>

        <InputRow>
          <label>Beginning Till:</label>
          <Input
            isMoney
            value={beginningTill}
            onValueChange={setBeginningTill}
            disabled={disabled}
            data-testid="beginningTillInput"
          />

          <label>Beginning Change:</label>
          <Input
            isMoney
            value={beginningChange}
            onValueChange={setBeginningChange}
            disabled={disabled}
            data-testid="beginningChangeInput"
          />

          <label>Cash Dispensed:</label>
          <Input
            isMoney
            value={beginningPokerBank}
            onValueChange={setBeginningPokerBank}
            disabled={disabled}
            data-testid="beginningPokerBankInput"
          />
        </InputRow>

        <ButtonRow>
          <BlockButton disabled={disabled} data-testid="createShiftButton">
            Create Shift
          </BlockButton>
          <BlockButton
            onClick={handleCancelClick}
            disabled={disabled}
            data-testid="cancelButton"
          >
            Cancel
          </BlockButton>
        </ButtonRow>
      </Form>
    </FormBox>
  );
};

export default styled(CreateShiftForm)`
  width: 1100px;
  label {
    width: 130px;
  }

  input,
  ${NumberInput} {
    width: 160px;
  }

  * + label {
    margin-left: 20px;
  }
`;

const ButtonRow = styled(Row)`
  justify-content: space-evenly;
`;
