import React from 'react';
import styled from 'styled-components/macro';
import Table from './Table';
import DateText from './DateText';
import FormBox from './FormBox';
import ShiftMaster from '../models/ShiftMaster';

export interface OverShortByEmployeeFormProps {
  data: ShiftMaster[];
}

const OverShortByEmployeeForm: React.FC<
  OverShortByEmployeeFormProps
> = props => {
  const { data, ...rest } = props;

  return (
    <FormBox {...rest} title="Over_ShortByEmployee : Select Query">
      <EmployeeTable
        headers={[
          '',
          'Store id',
          'shift date',
          'shift',
          'Employee',
          'FirstName',
          'LastName',
          'Over_Short',
          'Othercomments',
        ]}
      >
        {data.map((row, index) => (
          <tr key={index}>
            <td />
            <td>{row.storeId}</td>
            <td>
              <DateText date={new Date(row.shiftDate)} />
            </td>
            <td>{row.shift}</td>
            <td>{row.employee}</td>
            <td>{row.firstName}</td>
            <td>{row.lastName}</td>
            <td>{row.overShort}</td>
            <td>{row.comments}</td>
          </tr>
        ))}
      </EmployeeTable>
    </FormBox>
  );
};

export default styled(OverShortByEmployeeForm)`
  width: 1000px;
  margin: auto;
  padding: 30px 0;
  background: #aaa;
`;

const EmployeeTable = styled(Table)`
  padding: 0;
  margin: 0;
  width: 850px;
  border-spacing: 0;
  border-collapse: collapse;

  th {
    margin: 0;
    overflow: hidden;
    font-weight: normal;
  }

  td {
    border: 1px solid #aaa;
    background-color: white;
    text-align: right;
    padding: 2px;
    min-width: 100px;

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      text-align: left;
    }
  }

  td:first-child,
  th {
    background: #ddd;
    min-width: 10px;
    border: 1px solid black;
  }
`;
